import ReactPlayer from "react-player";

import styles from "./styles.module.less";

type VideoPlayerProps = {
  video_url: string;
  thumbnail_url?: string;
  controls?: boolean;
  user_email: string;
  is_autoplay?: boolean;
}; /* use `interface` if exporting so that consumers can extend */

export const VideoPlayer = ({
  video_url,
  thumbnail_url,
  user_email,
  controls = true,
  is_autoplay,
}: VideoPlayerProps) => {
  return (
    <div className={styles.reactPlayerWrapper}>
      <ReactPlayer
        datacustomattribute={user_email}
        className={styles.reactPlayer}
        width="100%"
        height="100%"
        url={video_url}
        playing={true}
        light={is_autoplay ? false : thumbnail_url}
        playbackRate={1.0}
        controls={true}
        muted={is_autoplay ? true : false}
        playsinline
        config={{
          file: {
            attributes: {
              controlsList: "nodownload",
            },
          },
        }} // Disable download button
        onContextMenu={(e: any) => e.preventDefault()} // Disable right click
      />
    </div>
  );
};
