import { AxiosError } from "axios";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";

import { appAxios, url } from "~/utils/axios";

const getAudiencesQueryFn = () => {
  return appAxios.get<any>(url.get_audiences).then((response) => response.data);
};
const useGetAudiencesQuery = (
  options?: UseQueryOptions<any, Error | AxiosError<{ detail: string }>>
) => {
  return useQuery<any, Error | AxiosError<{ detail: string }>>(
    "audiences",
    getAudiencesQueryFn,
    options
  );
};

const getBroadcasterQueryFn = () => {
  return appAxios
    .get<any>(url.get_broadcaster_list, {
      params: {
        page_size: 0,
      },
    })
    .then((response) => response.data);
};
const useGetBroadcasterQuery = (
  options?: UseQueryOptions<any, Error | AxiosError<{ detail: string }>>
) => {
  return useQuery<any, Error | AxiosError<{ detail: string }>>(
    "broadcasters",
    getBroadcasterQueryFn,
    options
  );
};

type CreateLivePostInfo = { formData: FormData };

const createLivePostMutationFn = (createLivePostInfo: CreateLivePostInfo) => {
  const { formData } = createLivePostInfo;

  return appAxios
    .post(url.schedule_live_post, formData)
    .then((response) => response.data);
};

const useCreateLivePostMutation = (
  options?: UseMutationOptions<any, AxiosError, CreateLivePostInfo>
) => {
  return useMutation<any, AxiosError, CreateLivePostInfo>(
    createLivePostMutationFn,
    options
  );
};

export {
  useGetAudiencesQuery,
  useCreateLivePostMutation,
  useGetBroadcasterQuery,
};
