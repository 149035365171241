import { useEffect, useRef, useState } from "react";
import { StepsProps, Tooltip } from "antd";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  message,
  Modal,
  Popover,
  Row,
  Spin,
  Steps,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  IoArrowBackOutline,
  IoArrowForwardOutline,
  IoHomeOutline,
  IoInformationCircle,
  IoInformationCircleOutline,
  IoKeyOutline,
  IoLogoDiscord,
  IoLogoGoogle,
} from "react-icons/io5";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";

import images from "~/assets/images";
import {
  OTPButton,
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from "~/components/Button";
import { PaddedContainer } from "~/components/Container";
import {
  HorizontalInput,
  Input,
  SelectInput,
  TextAreaInput,
} from "~/components/Input";
import { DiscordOAuth, GoogleOAuth } from "~/components/OAuth";
import { DescriptionText, SubTitle, Title } from "~/components/Text";
import { useAuthContext } from "~/contexts/auth";
import { Path } from "~/enums";
import { UserProfileT } from "~/types";
import { UserInformationFormField } from "~/types/form";

import { useCountryListQuery } from "../query";

import {
  useRequestVerificationMailMutation,
  useRequestVerificationPhoneMutation,
  useUserProfileQuery,
  useVerifyEmailMutation,
  useVerifyPhoneMutation,
} from "./query";

type VerifyInfoState = {
  verifyInfo: { phone: string; phone_prefix: string; email: string };
};

const ChangePhoneNumberPage = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const state = location.state as VerifyInfoState;

  const [form] = Form.useForm();
  const countdownRef = useRef<any>();
  const navigate = useNavigate();

  const INFO_STEP = {
    title: t("changeverify.checkedit"),
    description: t("changeverify.checkedit.description"),
    id: "info",
  };

  const VERIFY_EMAIL_STEP = {
    title: t("changeverify.verifyemail"),
    description: t("changeverify.verifyemail.description"),
    id: "email",
  };

  const VERIFY_PHONE_STEP = {
    title: t("changeverify.verifyphone"),
    description: t("changeverify.verifyphone.description"),
    id: "phone",
  };

  const {
    mutate: requestEmailVerificationCode,
    isLoading: isLoadingRequestVerificationMail,
  } = useRequestVerificationMailMutation({
    onSuccess: () => {
      countdownRef.current?.startCountdown();
    },
    onError: (error: any) => {
      message.error("Failed to send verification email. Please try again.");
    },
  });

  const getEmailVerificationCode = () => {
    let email = form.getFieldValue("email");
    requestEmailVerificationCode({ formData: { email } });
  };

  const onVerifyEmail = (values: any) => {
    verifyEmail({ formData: { email: emailLabel, ...values } });
  };

  const [steps, setSteps] = useState([INFO_STEP]);

  const [current, setCurrent] = useState(0);

  const onChange = (value: number) => {
    setCurrent(value);
  };

  const [phoneNumberLabel, setPhoneNumberLabel] = useState(
    state?.verifyInfo.phone_prefix + state?.verifyInfo.phone
  );

  const [emailLabel, setEmailLabel] = useState(state?.verifyInfo.email);

  const { data, isLoading, isError, refetch } = useCountryListQuery();
  const {
    data: profileData,
    isLoading: isLoadingProfile,
    isError: isErrorProfile,
  } = useUserProfileQuery();

  const onNext = (values: any) => {
    let stepList = [...steps];
    setPhoneNumberLabel(values.phone_prefix + values.phone);
    setEmailLabel(values.email);

    if (!isEmailVerified(values.email)) {
      let filtered = stepList.filter((item) => item.id == "email");
      if (filtered.length == 0) {
        stepList.push(VERIFY_EMAIL_STEP);
      }
    } else {
      stepList = stepList.filter((item) => item.id != "email");
    }

    if (!isPhoneVerified(values.phone_prefix, values.phone)) {
      let filtered = stepList.filter((item) => item.id == "phone");
      if (filtered.length == 0) {
        stepList.push(VERIFY_PHONE_STEP);
      }
    } else {
      stepList = stepList.filter((item) => item.id != "phone");
    }

    setSteps(stepList);
    next(stepList.length);
    // requestPhoneChange({ formData: values });
  };

  // const { mutate: requestPhoneChange, isLoading: isLoadingPhoneChange } =
  //   useRequestChangePhoneMutation({
  //     onSuccess: () => {
  //       setShowVerify(true);
  //     },
  //   });

  useEffect(() => {
    let stepList = [...steps];

    if (!isEmailVerified(state?.verifyInfo.email)) {
      stepList.push(VERIFY_EMAIL_STEP);
    }

    if (
      !isPhoneVerified(state?.verifyInfo.phone_prefix, state?.verifyInfo.phone)
    ) {
      stepList.push(VERIFY_PHONE_STEP);
    }

    form.setFieldsValue(state?.verifyInfo);

    setSteps(stepList);
  }, []);

  const {
    mutate: requestPhoneVerificationCode,
    isLoading: isLoadingRequestVerificationOTP,
  } = useRequestVerificationPhoneMutation({
    onSuccess: () => {
      countdownRef.current?.startCountdown();
    },
    onError: (error: any) => {
      message.error("Failed to request for OTP");
    },
  });

  const {
    mutate: verifyPhone,
    isLoading: isVerifyingPhone,
    isError: isErrorVerifyPhone,
    error,
  } = useVerifyPhoneMutation({
    onSuccess: (data: any) => {
      Modal.success({
        centered: true,
        title: "Phone Verification Success",
        content: "Phone number verified successfully.",
        onOk() {
          next();
        },
      });
    },
    onError: (error: any) => {
      message.error("Failed to verify phone number. Please try again.");
    },
  });

  const {
    mutate: verifyEmail,
    isLoading: isVerifyingEmail,
    isError: isErrorVerifyEmail,
    error: errorVerifyEmail,
  } = useVerifyEmailMutation({
    onSuccess: (data: any) => {
      Modal.success({
        centered: true,
        title: "Email Verification Success",
        content: "Email verified successfully.",
        onOk() {
          next();
        },
      });
    },
    onError: (error: any) => {
      message.error("Failed to verify email. Please try again.");
    },
  });

  const getOTPVerificationCode = () => {
    requestPhoneVerificationCode({
      formData: form.getFieldsValue(["phone", "phone_prefix"]),
    });
  };

  const onVerifyPhone = (values: any) => {
    verifyPhone({ formData: values });
  };

  const isEmailVerified = (email: string) => {
    if (!profileData?.is_email_verified) {
      return false;
    }

    return email == profileData?.email;
  };

  const isPhoneVerified = (phone_prefix: string, phone: string) => {
    if (!profileData?.is_phone_verified) {
      return false;
    }

    return (
      phone_prefix == profileData?.phone_prefix && phone == profileData?.phone
    );
  };

  const next = (stepsCount?: number) => {
    let currentSteps = stepsCount ?? steps.length;
    if (current === currentSteps - 1) {
      navigate(`/${Path.profile}`);
    } else {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    setCurrent(0);
  };

  if (isLoadingProfile) {
    return <Spin size="small" />;
  }

  return (
    <PaddedContainer>
      <div className="md:w-2/3">
        <Steps size="small" current={current} onChange={onChange}>
          {steps.map((item) => {
            return (
              <Steps.Step
                key={item.title}
                title={item.title}
                description={item.description}
              />
            );
          })}
        </Steps>
      </div>

      {steps[current].id == "info" && (
        <Form
          onFinish={onNext}
          form={form}
          name="changephone_form"
          layout="vertical"
          size="middle"
          requiredMark={false}
          initialValues={undefined}
          className="w-4/5 md:w-2/3 !mt-10"
          labelCol={{
            xs: { span: 24 },
            sm: { span: 16 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 16 },
          }}
          colon={false}
        >
          <div className="md:p-4">
            <HorizontalInput
              name="email"
              label={t("email")}
              rules={[{ type: "email", message: "Invalid email format" }]}
              suffix={
                <Tooltip title={t("changeverify.changemaildiscord")}>
                  <IoInformationCircleOutline className="text-green-500 text-lg" />
                </Tooltip>
              }
              required
              disabled
            />

            <Input
              name="phone"
              label={t("phonenumber")}
              addonBefore={
                <SelectInput
                  name="phone_prefix"
                  label="Phone Code"
                  options={data}
                  valueKey="phone_code"
                  nameKey="phone_code"
                  optionFilterProp="children"
                  required
                  noStyle
                  showSearch
                />
              }
              rules={[
                {
                  pattern: /\d{8,15}$/g,
                  message:
                    "Invalid format, phone number must be between 11 to 18 characters, only numbers allowed",
                },
              ]}
              required
            />
          </div>

          <Divider dashed plain className="!border-neutral-700 !mt-16" />

          <div className="flex space-x-2 justify-end">
            <div>
              <SecondaryButton
                onClick={() => {
                  navigate(`/${Path.profile}`);
                }}
              >
                {t("cancel")}
              </SecondaryButton>
            </div>
            <div>
              <PrimaryButton htmlType="submit">{t("next")}</PrimaryButton>
            </div>
          </div>
        </Form>
      )}

      {steps[current].id == "email" && (
        <Form
          onFinish={onVerifyEmail}
          form={form}
          name="userinformation_form"
          layout="vertical"
          size="middle"
          requiredMark={false}
          className="w-4/5 md:w-2/3 space-y-8 !mt-10"
          labelCol={{
            xs: { span: 24 },
            sm: { span: 16 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 16 },
          }}
        >
          <div className="md:p-4">
            <div className="mb-7">
              {t("changeverify.getcodeprompt")} <br />
              {t("changeverify.codesentto")} {emailLabel}. <br />
              {t("changeverify.entercode")}
            </div>
            <Input
              name="email_verification_code"
              label={t("changeverify.emailverificationcode")}
              rules={[{ required: false }]}
              suffix={
                <OTPButton
                  ref={countdownRef}
                  isLoading={isLoadingRequestVerificationMail}
                  duration={60000}
                  onClick={getEmailVerificationCode}
                />
              }
              required
            />
          </div>

          <Divider dashed plain className="!border-neutral-700 !mt-16" />

          <div className="flex space-x-2 justify-end">
            <div>
              <SecondaryButton onClick={prev}>{t("back")}</SecondaryButton>
            </div>
            <div>
              <PrimaryButton htmlType="submit" loading={isLoading}>
                {current < steps.length - 1 && t("next")}
                {current === steps.length - 1 && t("done")}
              </PrimaryButton>
            </div>
          </div>
        </Form>
      )}

      {steps[current].id == "phone" && (
        <div>
          <Form
            onFinish={onVerifyPhone}
            form={form}
            name="changephone_form"
            layout="vertical"
            size="middle"
            requiredMark={false}
            className="w-4/5 md:w-2/3 !mt-10"
            labelCol={{
              xs: { span: 24 },
              sm: { span: 16 },
            }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 16 },
            }}
          >
            <div className="md:p-4">
              <div className="mb-7">
                {t("changeverify.getcodeprompt")} <br />
                {t("changeverify.codesentto")} {phoneNumberLabel}. <br />
                {t("changeverify.entercode")}
              </div>
              {isErrorVerifyPhone && error && (
                <Alert
                  showIcon
                  type="error"
                  message={
                    error.response?.data?.ERROR_MSG ??
                    "Error verifying phone number."
                  }
                />
              )}
              <Input
                name="phone_otp"
                label={t("changeverify.phonenumberverificationcode")}
                rules={[{ required: false }]}
                suffix={
                  <OTPButton
                    ref={countdownRef}
                    isLoading={isLoadingRequestVerificationOTP}
                    duration={60000}
                    onClick={getOTPVerificationCode}
                  />
                }
                required
              />
            </div>

            <Divider dashed plain className="!border-neutral-700 !mt-16" />

            <div className="flex space-x-2 justify-end">
              <div>
                <SecondaryButton onClick={prev}>{t("back")}</SecondaryButton>
              </div>
              <div>
                <PrimaryButton htmlType="submit" loading={isVerifyingPhone}>
                  {current < steps.length - 1 && t("next")}
                  {current === steps.length - 1 && t("done")}
                </PrimaryButton>
              </div>
            </div>
          </Form>
        </div>
      )}

      {/* <div className="steps-action">
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => message.success("Processing complete!")}
          >
            Done
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
        )}
      </div> */}
    </PaddedContainer>
  );
};

export default ChangePhoneNumberPage;
