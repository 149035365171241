import "react-quill/dist/quill.snow.css";
import "./styles.css";

import ReactQuill from "react-quill";

const Editor = (props: any) => {
  return (
    <ReactQuill
      theme="snow"
      placeholder={"Write something..."}
      modules={Editor.modules}
      formats={Editor.formats}
      value={props.value}
      onChange={props.onChange}
      id="react-quill-editor"
    />
  );
};

Editor.modules = {
  toolbar: [
    [{ font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

Editor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

export { Editor };
