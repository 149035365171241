import {
  createContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  message,
  Modal,
  notification,
  Row,
  Upload,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  IoArrowBackOutline,
  IoArrowForwardOutline,
  IoCloudCircle,
  IoCloudUploadOutline,
  IoHomeOutline,
  IoImageOutline,
  IoKeyOutline,
  IoLogoDiscord,
  IoLogoGoogle,
  IoVideocamOutline,
} from "react-icons/io5";
import ReactPlayer from "react-player";
import {
  Link,
  useLocation,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";

import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from "~/components/Button";
import { PaddedContainer } from "~/components/Container";
import { Editor } from "~/components/Editor";
import { Input, SelectInput, TextAreaInput } from "~/components/Input";
import { DiscordOAuth, GoogleOAuth } from "~/components/OAuth";
import { DescriptionText, SubTitle, Title } from "~/components/Text";
import { VideoPlayer } from "~/components/VideoPlayer";
import { useAuthContext } from "~/contexts/auth";
import { Path } from "~/enums";
import { UserInformationFormField } from "~/types/form";
import { guestAxios, url } from "~/utils/axios";
import { normFile } from "~/utils/functions";

import {
  useCreateFileUploadPolicyMutation,
  useFinalizePostMutation,
  useGetAdminListQuery,
  useGetAudiencesQuery,
  useUploadFileMutation,
  useUserProfileQuery,
} from "./query";
import styles from "./styles.module.less";

const GENDER_OPTIONS = [
  { name: "Male", value: "M" },
  { name: "Female", value: "F" },
];

const UploadVideo = () => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { setAuthInfo } = useAuthContext();
  const isModalShowing = useRef(false);

  const [thumbnail, setThumbnail] = useState<any>();
  const [video, setVideo] = useState<any>();
  const [progress, setProgress] = useState<any>();

  const {
    data: audiences,
    isLoading: isLoadingAudiences,
    isError,
    refetch,
  } = useGetAudiencesQuery();

  const {
    data: profileData,
    isLoading: isLoadingProfile,
    isError: isErrorProfile,
  } = useUserProfileQuery();

  const {
    data: adminList,
    isLoading: isLoadingAdminList,
    isError: isErrorAdminList,
  } = useGetAdminListQuery({
    enabled: !!profileData,
    onSuccess: (data) => {
      form.setFieldsValue({ user: profileData?.id });
    },
  });

  const { isLoading: isUploading, mutate: uploadFile } = useUploadFileMutation({
    onSuccess: (data) => {
      finalizePost({ id: data.post_id });
    },
    onError: (error) => {
      message.error(
        "Failed to upload video. Please create a new post and try again."
      );
    },
  });

  const { isLoading: isFinalizing, mutate: finalizePost } =
    useFinalizePostMutation({
      onSuccess: (data) => {
        Modal.success({
          centered: true,
          title: "Upload Successful",
          content: "New post has been uploaded successfully.",
          onOk() {
            navigate(`/${Path.content}`);
          },
        });
      },
      onError: (error) => {
        message.error("Failed to upload new post. Please try again.");
      },
    });

  const { isLoading, mutate } = useCreateFileUploadPolicyMutation({
    onSuccess: (data) => {
      // const thumbnailFormData = new FormData();
      // thumbnailFormData.append(
      //   "object",
      //   form.getFieldValue("thumbnail")[0].originFileObj
      // );
      // uploadFile({
      //   uploadURL: data.thumbnail_upload_url,
      //   file: form.getFieldValue("thumbnail")[0].originFileObj,
      // });

      const videoFormData = new FormData();

      //   let element = document.querySelector('input[type="file"]') as any;
      //   let videoFile = element.files[0] as any;

      //   videoFormData.append("file", videoFile);

      videoFormData.append(
        "object",
        form.getFieldValue("video")[0].originFileObj
      );

      uploadFile({
        onProgress: (e) => {
          if (!isModalShowing.current) {
            notification.warning({
              key: data.post_id,
              duration: 0,
              message:
                "Uploading video: " +
                form.getFieldValue("video")[0].originFileObj.name,
              description: "Progress: 0%",
              onClose() {
                isModalShowing.current = false;
              },
            });
            isModalShowing.current = true;
          } else {
            let progress = Math.floor((e.loaded / e.total) * 100);
            notification.warning({
              key: data.post_id,
              duration: progress == 100 ? 4.5 : 0,
              message:
                "Uplaoding video: " +
                form.getFieldValue("video")[0].originFileObj.name,
              description: "Progress: " + progress + "%",
              onClose() {
                isModalShowing.current = false;
              },
            });
          }
        },
        postId: data.post_id,
        uploadURL: data.video_upload_url,
        file: form.getFieldValue("video")[0].originFileObj,
      });
    },
    onError: (error: any) => {
      message.error("Failed to create new post. Please try again.");
    },
  });

  const onOAuthSuccess = (data: any) => {
    localStorage.setItem("access_token", data.access_token);
    setAuthInfo({ isAuthenticated: true, isVerifyingToken: false });
    navigate("/");
  };

  const onFinish = (values: any) => {
    // if (values.birth_date) {
    //   values.birth_date = values.birth_date.format("YYYY-MM-DD");
    // }

    // values.phone = values.phone_prefix + values.phone;

    // mutate({ formData: values });

    const { video, thumbnail, audiences, ...rest } = values;
    const formData = new FormData();

    // console.log("video:", video);

    if (video[0]) {
      // formData.append("video", video[0].originFileObj as Blob);

      formData.append("video_name", video[0].name);
      formData.append("video_size", video[0].size);
    }

    if (thumbnail[0]) {
      formData.append("thumbnail", thumbnail[0].originFileObj as Blob);
    }

    if (audiences) {
      audiences.map((audience: any, index: any) => {
        formData.append("audiences", audience);
      });
    }

    for (const fields of Object.entries(rest)) {
      const [key, value] = fields;
      if (value) formData.append(key, value as any);
    }

    for (let [key, value] of formData as any) {
      console.log(`${key}: ${value}`);
    }

    mutate({ formData });

    // navigate(`/signup/verification`, { state: { signUpInfo: values } });
  };

  // const onCountryChange = (value: string, option: any) => {
  //   form.setFieldsValue({ phone_prefix: option.object.phone_code });
  // };

  const onChangeVideo = (value: any) => {
    let videoUrl = URL.createObjectURL(value.file);
    setVideo(videoUrl);
  };

  const onChangeThumbnail = (value: any) => {
    let thumbnailUrl = URL.createObjectURL(value.file);
    setThumbnail(thumbnailUrl);
  };

  return (
    <PaddedContainer>
      <SubTitle>{t("content.newpost")}</SubTitle>

      <Form
        onFinish={onFinish}
        form={form}
        name="userinformation_form"
        layout="vertical"
        size="middle"
        requiredMark={false}
        className="space-y-8 !mt-5"
      >
        <Row gutter={[36, 24]}>
          <Col
            xs={{ order: 2, span: 24 }}
            md={{ order: 1, span: 12 }}
            className="w-full"
          >
            {/* <input type="file" name="fileName"></input> */}
            <Input name="title" label={t("title")} required />

            <Form.Item
              name="description"
              label={<DescriptionText>{t("description")}</DescriptionText>}
            >
              <Editor />
            </Form.Item>

            <DescriptionText className="mb-2">{t("video")}</DescriptionText>
            <Form.Item
              className="!w-[300px]"
              name="video"
              getValueFromEvent={normFile}
              rules={[{ required: true }]}
            >
              <Upload
                beforeUpload={(file) => {
                  //   setFileList([...fileList, file]);

                  return false;
                }}
                maxCount={1}
                accept="video/*"
                listType="picture"
                onChange={onChangeVideo}
              >
                <Button icon={<IoVideocamOutline />}>{t("upload")}</Button>
              </Upload>
            </Form.Item>

            <DescriptionText className="mb-2">{t("thumbnail")}</DescriptionText>
            <Form.Item
              className="!w-[300px]"
              name="thumbnail"
              getValueFromEvent={normFile}
              rules={[{ required: true }]}
            >
              <Upload
                beforeUpload={() => false}
                maxCount={1}
                accept="image/*"
                listType="picture"
                onChange={onChangeThumbnail}
              >
                <Button icon={<IoImageOutline />}>{t("upload")}</Button>
              </Upload>
            </Form.Item>

            <SelectInput
              name="user"
              label={t("user")}
              options={adminList}
              valueKey="id"
              nameKey="fullname"
              // onChange={onAdminLi}
              // optionFilterProp="children"
              required
            />

            <SelectInput
              name="audiences"
              label={t("audiences")}
              options={audiences}
              nameKey="title"
              valueKey="id"
              mode="multiple"
              required
            />

            <Form.Item
              name="is_visible"
              valuePropName="checked"
              initialValue={true}
            >
              <Checkbox>
                <span className="text-sm">{t("publishvideo")}</span>
              </Checkbox>
            </Form.Item>

            <div>
              <PrimaryButton
                htmlType="submit"
                loading={isLoading || isUploading || isFinalizing}
              >
                {t("upload")}
              </PrimaryButton>
            </div>
          </Col>
          <Col xs={{ order: 1, span: 24 }} md={{ order: 2, span: 12 }}>
            <DescriptionText className="mb-3">{t("preview")}</DescriptionText>
            <div className="w-full bg-neutral-800">
              <VideoPlayer
                video_url={video}
                thumbnail_url={thumbnail ?? true}
                user_email=""
              />
            </div>
          </Col>
        </Row>
      </Form>
    </PaddedContainer>
  );
};

export default UploadVideo;
