import { ReactNode } from "react";
import { Avatar, Col, Empty, Image, Row, Skeleton, Tag } from "antd";
import moment from "moment";
import { IoPerson } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import { DescriptionText } from "~/components/Text";
import { Path } from "~/enums";
import { VideoInfoT } from "~/types";

import { VideoInfo } from "../VideoInfo";
import styles from "../VideoInfo/styles.module.less";

type VideoRowProps = {
  data?: VideoInfoT[];
  size?: "small" | "medium" | undefined;
  isLoading?: boolean;
  onClickVideo?: (post_id: string, is_live: boolean) => void;
  hidePublisher?: boolean;
};

export const VideoRow = ({
  data,
  size = "medium",
  isLoading,
  onClickVideo,
  hidePublisher = false,
}: VideoRowProps) => {
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <Row gutter={[18, 28]} className="mt-2">
        {[...Array(size == "small" ? 4 : 3)].map((e, index) => (
          <Col
            key={"placeholder-" + index}
            xs={24}
            md={8}
            lg={size == "small" ? 6 : 8}
          >
            <div className="flex md:flex-col">
              <div
                className={styles.thumbnailContainer + " w-full md:w-full"}
              />
              <div className="w-full p-2">
                <Skeleton avatar paragraph={{ rows: 1 }} active={true} />
              </div>
            </div>
          </Col>
        ))}
      </Row>
    );
  }

  if (data && data.length > 0) {
    return (
      <Row gutter={[18, 28]} className="mt-2">
        {data?.map((video, index) => (
          <Col
            key={"video-" + index}
            xs={24}
            md={8}
            lg={size == "small" ? 6 : 8}
          >
            <VideoInfo
              key={video.post_id}
              video={video}
              onClick={() => {
                if (onClickVideo) {
                  onClickVideo(video.post_id, video.is_live);
                } else {
                  navigate(`/${Path.video}/${video.post_id}`);
                }
              }}
              hidePublisher={hidePublisher}
            />
          </Col>
        ))}
      </Row>
    );
  }

  return <Empty description="No Videos Found" className="p-7" />;
};
