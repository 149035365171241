import { Checkbox, Col, Divider, Form, message, Modal, Row } from "antd";
import {
  IoArrowBackOutline,
  IoArrowForwardOutline,
  IoHomeOutline,
  IoKeyOutline,
  IoLogoDiscord,
  IoLogoGoogle,
} from "react-icons/io5";
import {
  Link,
  useLocation,
  useNavigate,
  useResolvedPath,
  useSearchParams,
} from "react-router-dom";

import images from "~/assets/images";
import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from "~/components/Button";
import { Input, TextAreaInput } from "~/components/Input";
import { DiscordOAuth, GoogleOAuth } from "~/components/OAuth";
import { DescriptionText, SubTitle, Title } from "~/components/Text";
import { useAuthContext } from "~/contexts/auth";
import { Path } from "~/enums";

import { useResetPasswordMutation } from "./query";
import styles from "./styles.module.less";

const ResetPasswordForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { setAuthInfo } = useAuthContext();
  const { isLoading, mutate, isError, error } = useResetPasswordMutation({
    onSuccess: () => {
      Modal.success({
        title: "Password changed successfully",
        content: "Please login again.",
        centered: true,
        onOk() {
          navigate(`/${Path.login}`);
        },
      });
    },
    onError: (error: any) => {
      message.error("Failed to set new password. Please try again.");
    },
  });

  const redirectPath = sessionStorage.getItem("to");

  const onFinish = (values: any) => {
    let resetToken = searchParams.get("reset_token") || "";
    mutate({ reset_token: resetToken, new_password: values.password });
  };

  const onOAuthSuccess = (data: any) => {
    const { access_token } = data;
    localStorage.setItem("access_token", access_token);
    setAuthInfo({ isAuthenticated: true, isVerifyingToken: false });
    navigate("/");
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      name="userinformation_form"
      layout="vertical"
      size="middle"
      requiredMark={false}
      className="w-full md:w-2/3 !mt-10"
    >
      <div>
        <Input name="password" label={"Password"} type="password" required />

        <Input
          name="confirm_password"
          label={"Confirm Password"}
          rules={[
            ({ getFieldValue }: any) => ({
              validator(_: any, value: any) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
          type="password"
          required
        />
      </div>

      <Divider dashed plain className="!border-neutral-700 !mt-16" />

      <div className="flex space-x-2 justify-end">
        <div>
          <PrimaryButton htmlType="submit" loading={isLoading}>
            Submit
          </PrimaryButton>
        </div>
      </div>
    </Form>
  );
};

export default ResetPasswordForm;
