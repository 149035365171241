import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import {
  IoArrowBackOutline,
  IoArrowForwardOutline,
  IoHomeOutline,
  IoKeyOutline,
  IoPerson,
} from "react-icons/io5";
import { Link, Outlet } from "react-router-dom";

import images from "~/assets/images";
import { PrimaryButton, TextButton } from "~/components/Button";
import { PaddedContainer } from "~/components/Container";
import { DescriptionText, SubTitle, Title } from "~/components/Text";

import styles from "./styles.module.less";

const SubscriptionSkeleton = () => {
  return (
    <div className="relative overflow-hidden min-h-full">
      <PaddedContainer className="space-y-4">
        <Outlet />
      </PaddedContainer>

      <div className="absolute bottom-[-120px] left-[-350px] -z-10">
        <img src={images.blob3} width={600} />
      </div>
      <div className="scale-50 absolute top-[-160px] right-[-220px] -z-10">
        <img src={images.blob3} width={600} />
      </div>
    </div>
  );
};

export default SubscriptionSkeleton;
