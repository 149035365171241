import aboutUsBannerLogoImg from "~/assets/images/aboutus-banner-logo.png";
import aboutUsGraphImg from "~/assets/images/aboutus-graph.png";
import blob1Img from "~/assets/images/blob-1.svg";
import blob2Img from "~/assets/images/blob-2.svg";
import blob3Img from "~/assets/images/blob-3.svg";
import bannerLiveImg from "~/assets/images/home-banner-live.png";
import homeSubscriberImg from "~/assets/images/home-subscriber.png";
import masterLogoImg from "~/assets/images/logo-payment-master.png";
import tetherLogoImg from "~/assets/images/logo-payment-tether.png";
import visaLogoImg from "~/assets/images/logo-payment-visa.png";
import discordLogoImg from "~/assets/images/logo-social-discord.png";
import facebookLogoImg from "~/assets/images/logo-social-facebook.png";
import googleLogoImg from "~/assets/images/logo-social-google.png";
import instagramLogoImg from "~/assets/images/logo-social-instagram.png";
import twitterLogoImg from "~/assets/images/logo-social-twitter.png";
import youtubeLogoImg from "~/assets/images/logo-social-youtube.png";
import tradvellerLogoImg from "~/assets/images/logo-tradveller.png";

const images: any = {
  bannerLive: bannerLiveImg,
  blob1: blob1Img,
  blob2: blob2Img,
  blob3: blob3Img,
  homeSubscriber: homeSubscriberImg,
  aboutUsBannerLogo: aboutUsBannerLogoImg,
  googleLogo: googleLogoImg,
  aboutUsGraph: aboutUsGraphImg,
  masterLogo: masterLogoImg,
  visaLogo: visaLogoImg,
  tetherLogo: tetherLogoImg,
  socialLogo: {
    twitter: twitterLogoImg,
    youtube: youtubeLogoImg,
    instagram: instagramLogoImg,
    facebook: facebookLogoImg,
    discord: discordLogoImg,
  },
  tradvellerLogo: tradvellerLogoImg,
};

export default images;
