import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, useLocation } from "react-router-dom";

import { AuthProvider } from "~/contexts/auth";
import { SidebarContextProvider } from "~/contexts/sidebar";
import Routing from "~/Routing";

import { RoleProvider } from "./contexts/role";

const App = () => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <RoleProvider>
          <AuthProvider>
            <SidebarContextProvider>
              <Routing />
            </SidebarContextProvider>
          </AuthProvider>
        </RoleProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false, //by default will retry for 3 times if error
    },
  },
});

export default App;
