import "../index.css";

const PrivacyPolicyPage = () => {
  return (
    <div
      className="privacy"
      dangerouslySetInnerHTML={{
        __html: `
        <p class="underline title"><b>PERSONAL DATA PROTECTION NOTICE</b></p>

        <ol>
          <li class="list-title">
            <span class="small-title"><b>INTRODUCTION</b></span>

            <ol>
              <li>
                <span>
                  This personal data protection notice (<b>“Notice”</b>) is issued
                  pursuant to the
                  <b>Personal Data Protection Act 2010 (“the Act”)</b>. This Notice
                  serves to inform you how BALAENA SOFTTECH SDN. BHD. [Registration No.:
                  201901043025 (1352355-D)] including its subsidiary(ies), present
                  or future affiliates and/or associated company(ies) (collectively
                  be referred to as <b>“we”</b>, <b>“our”</b> or <b>“us”</b>)
                  collects, uses and protects information which you have provided to
                  us as part of the normal operation of the TheTradveller website
                  <a target="_blank" href="https://www.thetradveller.com"
                    >[https://www.thetradveller.com]</a
                  >
                  (<b>“Website”</b>). It also informs you of your rights with regard
                  to the use, access and correction of your Personal Data (as
                  hereunder defined) and other information on the Website.
                </span>
              </li>

              <li>
                <span
                  >By engaging our services (whether by visiting and/or using the
                  Website), you expressly consent to our collection, use, protection
                  and disclosure of your Personal Data in the provision of services.
                </span>
              </li>

              <li>
                <span>
                  In this Notice, unless the context otherwise requires:-
                </span>

                <ol class="roman">
                  <li>
                    “Personal Data” shall have the meaning as defined in the Act;
                  </li>

                  <li>“Subscribers” shall mean our customers; and</li>

                  <li>
                    “Users” shall mean the registered users of our forum or other
                    interactive areas of the Website.
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li class="list-title">
            <span class="small-title"><b>PERSONAL DATA</b></span>

            <ol>
              <li>
                The Personal Data that you provide or have provided to us
                voluntarily through the Website including but not limited to your
                name, date of birth, contact details, telephone number,
                valid email address, password to your account created via our
                Website, IP address, bank
                account information and/or such other information which may identify
                you that have been or may be collected, stored, used and processed
                by us and/or Website from time to time.
              </li>

              <li>
              Data collected and processed about you when accessing or using the Website. 
              Of which includes content you provide or post to any part of the Website and/or 
              your actions while using the Website such as search queries, streaming history, 
              browsing history and etc.
              </li>

              <li>
                In addition to the Personal Data that you have provided to us and/or
                the Website directly (whether when engaging us to provide our
                services or during any communication with us), we may also collect
                your Personal Data from various sources, including without
                limitation, at any events, seminars or talks organised or sponsored
                by us, or from online search tools.
              </li>

              <li>
                  The Website uses third party services that may collect information
                  used to identify you. Links to privacy policies of third party
                  service providers are as below:-
      
                  <ol>
                    <li>
                      <a class='link' href='https://policies.google.com/privacy' target='_blank'
                        >Google Sign-in</a
                      >
                    </li>
                    <li>
                      <a class='link' href='https://discord.com/privacy' target='_blank'
                        >Discord</a
                      >
                    </li>
                  </ol>
                </li>

                <li>
                If you purchase products through the Website, your financial information, 
                such as your payment method (valid credit card number, type, expiration date, crypto wallet address 
                  or other financial information), is collected and stored by our third party 
                  payment processing companies, Stripe and/or NowPayments (the “Payment Processors”), and use and 
                  storage of that information is governed by the Payment Processors' applicable 
                  terms of service and privacy policy.
                </li>

            </ol>
          </li>

          <li class="list-title">
            <span class="small-title"
              ><b>PURPOSES FOR COLLECTION OF PERSONAL DATA</b></span
            >

            <ol>
              <li>
                <span>
                  By engaging us, you hereby agree and consent to us using and
                  processing your Personal Data provided to the Website in the
                  manner and for the purposes as described below, which shall
                  include, without limitation:-
                </span>

                <ol class="roman">
                  <li>
                    for the purposes of processing any sales and/or payment
                    transactions;
                  </li>

                  <li>
                    for the preparation of related documents or instruments which
                    may be required for the purposes set out in sub-paragraph (i)
                    above
                  </li>

                  <li>
                    for us to verify your identity and to communicate or correspond
                    with you;
                  </li>

                  <li>to provide you access to the Website;</li>

                  <li>
                    to render our services and for all purposes related to or in
                    connection with our engagement;
                  </li>

                  <li>
                    for us to improve the Website, to better tailor the features,
                    performance and support of the Website;
                  </li>

                  <li>
                    to offer you additional information, opportunities, promotions
                    and functionality;
                  </li>

                  <li>
                    to provide you with information and/or materials of our features
                    and services and those relating to our related corporations,
                    lawyers, consultants, bankers, insurers and business partners or
                    to distribute information or details of events, seminars or
                    talks which may of interest to you by way of email or mail;
                  </li>

                  <li>
                    to facilitate our compliance with any laws or regulations;
                  </li>

                  <li>
                    for us to conduct internal administrative activities including
                    credit assessment and background check, market surveys and trend
                    analysis, as well as any other purposes as may be related to the
                    foregoing; and
                  </li>

                  <li>
                    for any other lawful purposes that is incidental or ancillary or
                    in furtherance to the above.
                  </li>
                </ol>
              </li>

              <li>
                <span>
                  The provision of your Personal Data is voluntary in nature. You
                  may also elect to limit the way your Personal Data provided to us
                  is to be processed by us. However, if you do not wish to provide
                  your Personal Data or the Personal Data provided by you is not
                  sufficient or not satisfactory, or if you subsequently withdraw
                  your consent to us processing your Personal Data, we and/or the
                  Website may not be able to (i) communicate or correspond with you;
                  (ii) provide you the services you require; or you may not be able
                  to access certain sections of the Website where log in is
                  required.
                </span>
              </li>
            </ol>
          </li>

          <li class="list-title">
            <span class="small-title"><b>TRANSFER OF PERSONAL DATA</b></span>

            <ol>
              <li>
                To the extent permitted under the applicable law, your Personal Data
                may be transferred to, stored, used and processed in a jurisdiction
                other than Malaysia.
              </li>
            </ol>
          </li>

          <li class="list-title">
            <span class="small-title"><b>DISCLOSURE OF PERSONAL DATA</b></span>

            <ol>
              <li>
                <span>
                  We may engage other companies, service providers or individuals to
                  perform functions on our behalf. In such event, you hereby agree
                  and consent that we may enable access or disclose your Personal
                  Data to the third parties, within or outside Malaysia, such as
                  those listed below (not exhaustive):</span
                >

                <ol class="roman">
                  <li>data entry service providers;</li>

                  <li>insurance providers;</li>

                  <li>storage facility providers;</li>

                  <li>information technology (IT) service providers;</li>

                  <li>banks and financial institutions;</li>

                  <li>any professional advisors and external auditors; and</li>

                  <li>
                    such other party as we deem necessary for the purposes mentioned
                    in paragraph 3 above.
                  </li>
                </ol>
              </li>

              <li>
                Further, we may also disclose your Personal Data to our related
                corporations as defined in the Companies Act 2016, business
                partners, all governmental and/or quasi-governmental departments
                and/or agencies, regulatory and/or statutory bodies where necessary
                and/or legally required to do so.
              </li>

              <li>
                We are committed to protecting the security of your Personal Data in
                accordance with the Act and the corresponding guidelines and orders.
                We use a variety of industry-standard security technologies and
                procedures and shall endeavour to implement the appropriate
                administrative and security safeguards and procedures to protect
                your Personal Data from unauthorized or unlawful access, use or
                disclosure.
              </li>

              <li>
                We may provide anonymous aggregate statistics about our Subscribers
                and/or Users, site traffic patterns, and related site information to
                relevant third parties, but these statistics will not include
                personally identifiable information.
              </li>

              <li>
                Notwithstanding the above, we may release your Personal Data when it
                is (i) reasonably necessary to comply with law; or (ii) required by
                exigent circumstances.
              </li>

              <li>
                Any feedback provided to us may be used by us for any purposes,
                provided we do not associate such feedback with your Personal Data.
                We will collect any information contained in such feedback and will
                treat the Personal Data in it in accordance with this Notice. You
                agree that any such comments and any email we receive becomes our
                property. We may use feedback for marketing purposes or to add to or
                modify our services without paying any royalties or other
                compensation to you.
              </li>

              <li>
                The Website may provide links to other sites. The privacy policies
                of these linked sites are the responsibility of the linked sites and
                we have no control or influence over their policies. Please check
                the policies of each site you visit for specific information. We
                shall not be held liable for any damage or misdoings of other sites
                linked or otherwise.
              </li>
            </ol>
          </li>

          <li class="list-title">
            <span class="small-title">
              <b>DATA SUBJECT'S OBLIGATIONS</b>
            </span>

            <ol>
              <li>
                You are responsible for ensuring that the Personal Data you provide
                us is accurate, complete and not misleading and that such personal
                data is kept up to date. The Personal Data collected and processed
                by us is deemed to be accurate until and unless you inform us
                otherwise in writing. If we are unable to process your Personal Data
                for the purposes set out in paragraph 3 herein or effectively render
                our services to you, all relationships created or to be created
                between us shall then be terminated and ceased to be in effect
                immediately.
              </li>
            </ol>
          </li>

          <li class="list-title">
            <span class="small-title"
              ><b>REQUEST FOR ACCESS AND INQUIRIESM</b></span
            >

            <ol>
              <li>
                <span
                  >You may access and request to amend your Personal Data and/or to
                  limit the processing of your Personal Data, make any enquiries or
                  complaints in respect of your Personal Data, or withdraw your
                  consent for us to process your Personal Data, as the case may be,
                  however subject to the exceptions and restrictions as may be
                  contained under the applicable law. If you wish to do so, please
                  send your request in writing to:</span
                >

                <table>
                  <tr>
                    <td>Name:</td>
                    <td>BALAENA SOFTTECH SDN. BHD.</td>
                  </tr>

                  <tr>
                    <td>Company No:</td>
                    <td>201901043025 (1352355-D)</td>
                  </tr>

                  <tr>
                    <td>Address:</td>
                    <td>
                      NO 2, JALAN ABD KARIM 29/KS2 TAMAN PERINDUSTRIAN SUNGAI JATI 41200 KLANG SELANGOR MALAYSIA
                    </td>
                  </tr>

                  <tr>
                    <td>Email:</td>
                    <td>support@thetradveller.com</td>
                  </tr>

                  <tr>
                    <td>Tel No:</td>
                    <td>0122652653</td>
                  </tr>
                </table>
              </li>

              <li>
                Please note that we have the right to refuse your request to access
                and/or make any correction to your Personal Data to the extent
                permitted under the applicable law, in which case we will notify you
                of the same by notice in writing.
              </li>
            </ol>
          </li>

          <li class="list-title">
            <span class="small-title"><b>GENERAL</b></span>

            <ol>
              <li>
                This Notice is subject to occasional revision at our discretion, and
                if we make any substantial changes in the way we use your Personal
                Data, we will post an alert on the Website. If you object to any
                such changes, you must cease using the Website. Continuous use of
                the Website following notice of any such changes shall indicate your
                acknowledgement of such changes and agreement to be bound by the
                terms and conditions of such changes.
              </li>

              <li>
                As required by the Act, this Notice is issued in both English and
                Bahasa Malaysia. In the event of any inconsistencies or
                discrepancies between the English version and the Bahasa Malaysia
                version of this Notice, the English version shall prevail over the
                Bahasa Malaysia version.
              </li>
            </ol>
          </li>
        </ol>

        <p class="attention">
          <b
            >BY USING OUR WEBSITE, YOU CONSENT AND AGREE TO THE COLLECTION AND USE
            OF YOUR INFORMATION BY US IN THE MANNER AS HEREIN PROVIDED.</b
          >
        </p>

        <p class="date">Last Updated: 19 September 2022</p>

        <hr class="line" />

        <!-- Malay -->

        <p class="underline title"><b>NOTIS PERLINDUNGAN DATA PERIBADI</b></p>

        <ol>
          <li class="list-title">
            <span class="small-title"><b>PENGENALAN</b></span>

            <ol>
              <li>
                <span>
                  Notis perlindungan data peribadi ini (<b>“Notis”</b>) dikeluarkan
                  menurut
                  <b>Akta Perlindungan Data Peribadi 2010 (“Akta tersebut”)</b>.
                  Notis ini berfungsi untuk memaklumkan kepada anda bagaimana 
                  BALAENA SOFTTECH SDN. BHD. [No. Pendaftaran: 201901043025 (1352355­D)]
                  termasuk anak syarikatnya, sekutu sekarang atau akan datang
                  dan/atau syarikat bersekutu (secara kolektif dirujuk sebagai
                  <b>'kami'</b> atau <b>“kita”</b>) mengumpul, menggunakan dan
                  melindungi maklumat yang telah anda berikan kepada kami sebagai
                  sebahagian daripada operasi biasa laman web TheTradveller
                  <a target="_blank" href="https://www.thetradveller.com"
                    >[https://www.thetradveller.com]</a
                  >
                  (<b>“Laman Web”</b>). Ia juga memberitahu anda tentang hak anda
                  berkenaan dengan penggunaan, akses dan pembetulan Data Peribadi
                  anda (seperti yang ditakrifkan di bawah sini) dan maklumat lain di
                  Laman Web tersebut.
                </span>
              </li>

              <li>
                <span
                  >Dengan menggunakan perkhidmatan kami (sama ada dengan melawati
                  dan/atau menggunakan Laman Web tersebut), anda secara nyata
                  bersetuju dengan pengumpulan, penggunaan, perlindungan dan
                  pendedahan Data Peribadi anda dalam perutukan perkhidmatan
                  kami.</span
                >
              </li>

              <li>
                <span>
                  Dalam Notis ini, melainkan konteksnya menghendaki makna yang
                  lain:-
                </span>

                <ol class="roman">
                  <li>
                    “Data Peribadi” hendaklah mempunyai erti seperti yang
                    ditakrifkan dalam Akta tersebut;
                  </li>

                  <li>“Pelanggan” bermaksud pelanggan kami; dan</li>

                  <li>
                    “Pengguna” bermaksud pengguna berdaftar forum kami atau kawasan
                    interaktif lain di Laman Web tersebut.
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li class="list-title">
            <span class="small-title"><b>DATA PERIBADI</b></span>

            <ol>
              <li>
                Data Peribadi yang anda berikan atau telah diberikan kepada kami
                secara sukarela melalui Laman Web tersebut termasuk tetapi tidak
                terhad kepada nama anda, tarikh lahir, butiran hubungan, alamat,
                nombor telefon, alamat e-mel yang sah, kata laluan ke akaun anda
                yang dibuat melalui Laman Web kami, alamat IP, maklumat akaun bank dan/atau maklumat
                lain yang mungkin mengenal pasti anda yang telah atau mungkin
                dikumpul, disimpan, digunakan dan diproses oleh kami dan/atau Laman
                Web dari semasa ke semasa.
              </li>

              <li>
              Data yang dikumpul dan diproses tentang anda semasa mengakses atau menggunakan Laman Web.
              Antaranya termasuk kandungan yang anda sediakan atau siarkan ke mana-mana bahagian Laman Web dan/atau
              tindakan anda semasa menggunakan Laman Web seperti pertanyaan carian, sejarah penstriman,
              sejarah pelayaran dan lain-lain.
              </li>

              <li>
                Selain daripada Data Peribadi yang telah anda berikan kepada kami
                dan/atau Laman Web tersebut secara langsung (sama ada semasa
                melibatkan kami untuk memberikan perkhidmatan kami atau semasa
                sebarang komunikasi dengan kami), kami juga mungkin mengumpul Data
                Peribadi anda daripada pelbagai sumber, termasuk tanpa had, di
                mana-mana acara, seminar atau ceramah yang dianjurkan atau ditaja
                oleh kami, atau daripada alat carian dalam talian.
              </li>

              <li>
              Laman Web menggunakan perkhidmatan pihak ketiga yang mungkin mengumpul maklumat
              digunakan untuk mengenal pasti anda. Pautan kepada dasar privasi pihak ketiga
              pembekal perkhidmatan adalah seperti di bawah:-
      
                  <ol>
                    <li>
                      <a class='link' href='https://policies.google.com/privacy' target='_blank'
                        >Google Sign-in</a
                      >
                    </li>
                    <li>
                      <a class='link' href='https://discord.com/privacy' target='_blank'
                        >Discord</a
                      >
                    </li>
                  </ol>
                </li>

                <li>
                Jika anda membeli produk melalui Laman Web, maklumat kewangan anda
                seperti kaedah pembayaran anda (nombor kad kredit yang sah, jenis, tarikh tamat tempoh, alamat dompet crypto
                  atau maklumat kewangan lain) akan dikumpul dan disimpan oleh servis pemprosesan pembayaran pihak ketiga, iaitu Stripe dan/atau NowPayments ("Pemproses Pembayaran"). Pengunaan dan
                  penyimpanan maklumat itu dikawal oleh syarat perkhidmatan dan dasar privasi servis Pemproses Pembayaran yang berkenaan.
                  </li>
            </ol>
          </li>

          <li class="list-title">
            <span class="small-title"
              ><b>TUJUAN UNTUK PENGUMPULAN DATA PERIBADI</b></span
            >

            <ol>
              <li>
                <span>
                  Dengan menggunakan kami, anda dengan ini bersetuju dan membenarkan
                  kami menggunakan dan memproses Data Peribadi anda yang diberikan
                  kepada Laman Web tersebut mengikut cara dan untuk tujuan seperti
                  yang diterangkan di bawah, yang termasuk, tanpa had:-
                </span>

                <ol class="roman">
                  <li>
                    untuk tujuan memproses sebarang transaksi jualan dan/atau
                    pembayaran;
                  </li>

                  <li>
                    untuk penyediaan dokumen atau instrumen berkaitan yang mungkin
                    diperlukan bagi tujuan yang dinyatakan dalam subperenggan (i) di
                    atas;
                  </li>

                  <li>
                    untuk kami mengesahkan identiti anda dan untuk berkomunikasi
                    atau berhubung dengan anda;
                  </li>

                  <li>untuk memberikan anda akses kepada Laman Web;</li>

                  <li>
                    untuk memberikan perkhidmatan kami dan untuk semua tujuan yang
                    berkaitan dengan atau berhubung dengan penglibatan kami;
                  </li>

                  <li>
                    untuk kami menambah baik Laman Web tersebut, untuk menyesuaikan
                    ciri, prestasi dan sokongan Laman Web tersebut dengan lebih
                    baik;
                  </li>

                  <li>
                    untuk menawarkan anda maklumat tambahan, peluang, promosi dan
                    kefungsian;
                  </li>

                  <li>
                    untuk memberikan anda maklumat dan/atau bahan mengenai ciri dan
                    perkhidmatan kami dan yang berkaitan dengan perbadanan, peguam,
                    perunding, jurubank, penanggung insurans dan rakan kongsi
                    perniagaan kami yang berkaitan atau untuk mengedarkan maklumat
                    atau butiran acara, seminar atau ceramah yang mungkin menarik
                    minat anda melalui e-mel atau mel;
                  </li>

                  <li>
                    untuk memudahkan pematuhan kami dengan mana-mana undang-undang
                    atau peraturan;
                  </li>

                  <li>
                    untuk kami menjalankan aktiviti pentadbiran dalaman termasuk
                    penilaian kredit dan semakan latar belakang, tinjauan pasaran
                    dan analisis trend, serta sebarang tujuan lain yang mungkin
                    berkaitan dengan perkara di atas; dan
                  </li>

                  <li>
                    untuk apa-apa tujuan sah lain yang bersampingan atau tambahan
                    atau lanjutan kepada perkara di atas.
                  </li>
                </ol>
              </li>

              <li>
                <span>
                  Perutukan Data Peribadi anda adalah bersifat sukarela. Anda juga
                  boleh memilih untuk mengehadkan cara Data Peribadi anda yang
                  diberikan kepada kami untuk diproses oleh kami. Walau
                  bagaimanapun, jika anda tidak mahu memberikan Data Peribadi anda
                  atau Data Peribadi yang anda berikan tidak mencukupi atau tidak
                  memuaskan, atau jika anda kemudiannya menarik balik kebenaran anda
                  kepada kami untuk memproses Data Peribadi anda, kami dan/atau
                  Laman Web tersebut mungkin tidak dapat untuk (i) berkomunikasi
                  atau berhubung dengan anda; (ii) memberikan perkhidmatan yang anda
                  perlukan; atau anda mungkin tidak dapat mengakses sebahagian
                  tertentu Laman Web tersebut di mana log masuk adalah diperlukan.
                </span>
              </li>
            </ol>
          </li>

          <li class="list-title">
            <span class="small-title"><b>PEMINDAHAN DATA PERIBADI</b></span>

            <ol>
              <li>
                Setakat yang dibenarkan di bawah undang-undang yang berkenaan, Data
                Peribadi anda mungkin akan dipindahkan ke, disimpan, digunakan dan
                diproses dalam bidang kuasa selain Malaysia.
              </li>
            </ol>
          </li>

          <li class="list-title">
            <span class="small-title"><b>PENDEDAHAN DATA PERIBADI</b></span>

            <ol>
              <li>
                <span
                  >Kami mungkin akan melibatkan syarikat, pembekal perkhidmatan atau
                  individu lain untuk melaksanakan fungsi-fungsi bagi pihak kami.
                  Dalam keadaan sedemikian, anda dengan ini bersetuju bahawa kami
                  boleh membolehkan akses atau mendedahkan Data Peribadi anda kepada
                  pihak ketiga, di dalam atau di luar Malaysia, seperti yang
                  disenaraikan di bawah (tidak menyeluruh):</span
                >

                <ol class="roman">
                  <li>pembekal perkhidmatan kemasukan data;</li>

                  <li>pembekal insurans;</li>

                  <li>pembekal kemudahan penyimpanan;</li>

                  <li>pembekal perkhidmatan teknologi maklumat (IT);</li>

                  <li>bank dan institusi kewangan;</li>

                  <li>mana-mana penasihat profesional dan juruaudit luaran; dan</li>

                  <li>
                    pihak lain yang kami anggap perlu untuk tujuan yang dinyatakan
                    dalam perenggan 3 di atas.
                  </li>
                </ol>
              </li>

              <li>
                Selanjutnya, kami juga mungkin akan mendedahkan Data Peribadi anda
                kepada syarikat berkaitan kami seperti yang ditakrifkan dalam Akta
                Syarikat 2016, rakan kongsi perniagaan, semua jabatan dan/atau
                agensi kerajaan dan/atau separa kerajaan, badan kawal selia dan/atau
                berkanun jika perlu dan/atau dikehendaki di sisi undang-undang untuk
                berbuat demikian.
              </li>

              <li>
                Kami komited untuk melindungi keselamatan Data Peribadi anda
                mengikut Akta tersebut dan garis panduan serta perintah yang
                sepadan. Kami menggunakan pelbagai teknologi dan prosedur
                keselamatan standard industri dan akan berusaha untuk melaksanakan
                perlindungan dan prosedur pentadbiran dan keselamatan yang sesuai
                untuk melindungi Data Peribadi anda daripada akses, penggunaan atau
                pendedahan yang tidak dibenarkan atau menyalahi undang-undang.
              </li>

              <li>
                Kami mungkin akan memberikan statistik agregat tanpa nama tentang
                Pelanggan dan/atau Pengguna kami, corak trafik tapak dan maklumat
                tapak berkaitan kepada pihak ketiga yang berkaitan, tetapi statistik
                ini tidak akan termasuk maklumat yang boleh dikenal pasti secara
                peribadi.
              </li>

              <li>
                Walau apa pun perkara di atas, kami mungkin akan mengeluarkan Data
                Peribadi anda apabila (i) semunasabahnya perlu untuk mematuhi
                undang-undang, atau (ii) diperlukan dalam keadaan mendesak.
              </li>

              <li>
                Sebarang maklum balas yang diberikan kepada kami boleh digunakan
                oleh kami untuk sebarang tujuan, dengan syarat kami tidak mengaitkan
                maklum balas tersebut dengan Data Peribadi anda. Kami akan mengumpul
                sebarang maklumat yang terkandung dalam maklum balas tersebut dan
                akan mengendalikan Data Peribadi di dalamnya mengikut Notis ini.
                Anda bersetuju bahawa sebarang komen sedemikian dan sebarang e-mel
                yang kami terima menjadi hak milik kami. Kami mungkin menggunakan
                maklum balas untuk tujuan pemasaran atau untuk menambah atau
                mengubah suai perkhidmatan kami tanpa membayar sebarang royalti atau
                pampasan lain kepada anda.
              </li>

              <li>
                Laman Web tersebut mungkin akan memberikan pautan ke laman web lain.
                Polisi privasi laman web terpaut ini adalah tanggungjawab laman web
                terpaut tersebut dan kami tidak mempunyai kawalan atau pengaruh ke
                atas polisi mereka. Sila semak polisi setiap laman web yang anda
                lawati untuk mendapatkan maklumat khusus. Pihak kami tidak boleh
                dipertanggungjawabkan untuk sebarang kerosakan atau salah laku laman
                web lain yang dipautkan atau sebaliknya.
              </li>
            </ol>
          </li>

          <li class="list-title">
            <span class="small-title">
              <b>KEWAJIPAN SUBJEK DATA</b>
            </span>

            <ol>
              <li>
                Anda bertanggungjawab untuk memastikan bahawa Data Peribadi yang
                anda berikan kepada kami adalah tepat, lengkap dan tidak
                mengelirukan dan bahawa data peribadi tersebut sentiasa dikemas
                kini. Data Peribadi yang dikumpul dan diproses oleh kami adalah
                dianggap tepat sehingga dan selain anda memberitahu kami sebaliknya
                secara bertulis. Jika kami tidak dapat memproses Data Peribadi anda
                untuk tujuan yang dinyatakan dalam perenggan 3 di sini atau secara
                berkesan memberikan perkhidmatan kami kepada anda, semua perhubungan
                yang diciptakan atau akan dicpitakan antara kami kemudiannya akan
                ditamatkan dan tidak lagi berkuat-kuasa dengan serta-merta.
              </li>
            </ol>
          </li>

          <li class="list-title">
            <span class="small-title"><b>PERMINTAAN AKSES DAN PERTANYAAN</b></span>

            <ol>
              <li>
                <span
                  >Anda boleh akses dan minta untuk meminda Data Peribadi anda
                  dan/atau mengehadkan pemprosesan Data Peribadi anda, membuat
                  sebarang pertanyaan atau aduan berkenaan dengan Data Peribadi
                  anda, atau menarik balik kebenaran anda untuk kami memproses Data
                  Peribadi anda, mengikut mana-mana yang berkenaan, tetapi tertakluk
                  kepada pengecualian dan sekatan yang mungkin terkandung di bawah
                  undang-undang yang berkaitan. Jika anda ingin berbuat demikian,
                  sila hantar permintaan anda secara bertulis kepada:
                </span>

                <table>
                  <tr>
                    <td>Nama:</td>
                    <td>BALAENA SOFTTECH SDN. BHD.</td>
                  </tr>

                  <tr>
                    <td>No. Syarikat:</td>
                    <td>201901043025 (1352355-D)</td>
                  </tr>

                  <tr>
                    <td>Alamat:</td>
                    <td>
                    NO 2, JALAN ABD KARIM 29/KS2 TAMAN PERINDUSTRIAN SUNGAI JATI 41200 KLANG SELANGOR MALAYSIA
                    </td>
                  </tr>

                  <tr>
                    <td>Emel:</td>
                    <td>support@thetradveller.com</td>
                  </tr>

                  <tr>
                    <td>No. Tel:</td>
                    <td>0122652653</td>
                  </tr>
                </table>
              </li>

              <li>
                Sila ambil perhatian bahawa kami mempunyai hak untuk menolak
                permintaan anda untuk akses dan/atau membuat sebarang pembetulan
                terhadap Data Peribadi anda setakat yang dibenarkan di bawah
                undang-undang yang berkaitan, di mana kami akan memberitahu anda
                tentang perkara yang sama melalui notis secara bertulis.
              </li>
            </ol>
          </li>

          <li class="list-title">
            <span class="small-title"><b>UMUM</b></span>

            <ol>
              <li>
                Notis ini adalah tertakluk kepada semakan sekali-sekala mengikut
                budi bicara kami, dan jika kami membuat sebarang perubahan besar
                dalam cara kami menggunakan Data Peribadi anda, kami akan menyiarkan
                makluman di Laman Web tersebut. Jika anda membantah sebarang
                perubahan sedemikian, anda mesti berhenti menggunakan Laman Web
                tersebut. Penggunaan berterusan Laman Web tersebut berikutan notis
                atas perubahan sedemikian akan menunjukkan pengakuan anda terhadap
                perubahan tersebut dan persetujuan untuk terikat dengan terma-terma
                dan syarat-syaray perubahan tersebut.
              </li>

              <li>
                Seperti yang dikehendaki oleh Akta tersebut, Notis ini adalah
                dikeluarkan dalam kedua-dua Bahasa Inggeris dan Bahasa Malaysia.
                Sekiranya terdapat sebarang ketidakkonsistenan atau percanggahan
                antara versi Bahasa Inggeris dan versi Bahasa Malaysia Notis ini,
                versi Bahasa Inggeris akan mengatasi versi Bahasa Malaysia.
              </li>
            </ol>
          </li>
        </ol>

        <p class="attention">
          <b
            >DENGAN MENGGUNAKAN LAMAN WEB KAMI, ANDA BERSETUJU DAN BERSETUJU
            TERHADAP PENGUMPULAN DAN PENGGUNAAN MAKLUMAT ANDA OLEH KAMI DENGAN CARA
            SEPERTI YANG DIBERIKAN DI SINI.</b
          >
        </p>

        <p class="date">Kemas Kini Terakhir: 19 September 2022</p>
      `,
      }}
    />
  );
};

export default PrivacyPolicyPage;
