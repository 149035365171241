import classNames from "classnames";

type AvatarProps = {
  src: string;
  widthClassName: string;
  heightClassName: string;
  className?: string;
  alt?: string;
};

const Avatar = ({
  alt,
  src,
  widthClassName,
  heightClassName,
  className,
}: AvatarProps) => {
  return (
    <div
      className={classNames(
        `flex ${widthClassName} ${heightClassName} ${className}`
      )}
    >
      <img
        className={`rounded-full`}
        alt={alt ?? "thumbnail-img"}
        src={src}
        draggable={false}
      />
    </div>
  );
};

export { Avatar };
