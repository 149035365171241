import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NotFound = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="h-full flex flex-col justify-center items-center">
      <div className="flex items-center space-x-4">
        <h1 className="mb-0 text-6xl">404</h1>

        <span className="text-lg">{t("404.message")}</span>
      </div>

      <div className="mt-6">
        <Link to="/" className="underline hover:underline">
          {t("backhome")}
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
