import { useLayoutEffect, useRef } from "react";
import { message, Modal } from "antd";
import axios from "axios";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";

import {
  BroadcasterOnlyRoute,
  CreatorOnlyRoute,
  PrivateRoute,
  PublicRoute,
} from "~/components/Route";
import { useAuthContext } from "~/contexts/auth";
import { Path } from "~/enums";
import AboutUs from "~/pages/aboutus";
import Browse from "~/pages/browse";
import Channel from "~/pages/channel";
import Connect from "~/pages/connect";
import ContactUs from "~/pages/contactus";
import Content from "~/pages/content";
import EditPost from "~/pages/content/details";
import UploadVideo from "~/pages/content/upload";
import Dashboard from "~/pages/dashboard";
import ForgotPassword from "~/pages/forgotpassword";
import Home from "~/pages/home";
import TermSkeleton from "~/pages/info";
import PrivacyPolicyPage from "~/pages/info/privacy-policy";
import TermOfUsePage from "~/pages/info/term-of-use";
import CreateLiveStream from "~/pages/livestream/create";
import EditLiveStream from "~/pages/livestream/edit";
import Profile from "~/pages/profile";
import ChangePassword from "~/pages/profile/changepassword";
import ChangePhoneNumber from "~/pages/profile/changephone";
import ChangeVerifyDetails from "~/pages/profile/changeverify";
import OAuthRedirect from "~/pages/redirect/oauth";
import ResetPassword from "~/pages/resetpassword";
import SubscriptionSkeleton from "~/pages/subscribe";
import SubscriptionPackages from "~/pages/subscribe/package";
import PaymentMethod from "~/pages/subscribe/paymentmethod";
import Subscription from "~/pages/subscription";
import Video from "~/pages/video";

import { useInitialAppMount } from "./contexts/initialMount";
import NotFound from "./pages/error/404";
import NowPaymentsPage from "./pages/subscribe/cryptopayment";
import { appAxios } from "./utils/axios";

// *PAGES NOT USED*
// import LogIn from "~/pages/login";
// import SignUpSkeleton from "~/pages/signup";
// import UserInformation from "~/pages/signup/userinformation";
// import Verification from "~/pages/signup/verification";

const Routing = () => {
  const isModalShowing = useRef(false);
  const initialAppMount = useInitialAppMount();
  const { logout } = useAuthContext();

  // WARNING:: This might cause Multiple modal if routing begin rerender...
  // It will rerender when error boundary get refresh
  useLayoutEffect(() => {
    if (!initialAppMount.current) {
      appAxios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          if (axios.isAxiosError(error)) {
            if (error.response?.status == 401) {
              // TODO:: Unauthorized
              // Navigate to / so it will verify again?
              if (!isModalShowing.current) {
                Modal.warning({
                  centered: true,
                  title: "Unauthorized",
                  content: "You are unauthorized, please login.",
                  onOk() {
                    isModalShowing.current = false;
                    logout();
                  },
                });
                isModalShowing.current = true;
              }
            }
            return Promise.reject(error);
          } else {
            // Server dead
            if (error.message === "Network Error") {
              message.error(
                "Please check your internet connection, or check with website technician"
              );
            }
            return Promise.reject(error);
          }
        }
      );

      initialAppMount.current = true;
    }
  }, []);

  return (
    <Routes>
      <Route
        path={Path.default}
        element={
          <PublicRoute>
            <Dashboard />
          </PublicRoute>
        }
      >
        <Route index element={<Navigate to={Path.home} />} />

        <Route path={Path.home} element={<Home />} />
        <Route path={Path.browse}>
          <Route path={`:category`} element={<Browse />} />
          <Route index element={<Navigate to={`all`} />} />
        </Route>
        <Route path={Path.aboutus} element={<AboutUs />} />
        <Route path={Path.contactus} element={<ContactUs />} />
        <Route path={`${Path.video}/:id`} element={<Video />} />
        <Route path={`${Path.channel}/:id`} element={<Channel />} />
        <Route
          path={Path.createlivestream}
          element={
            <BroadcasterOnlyRoute>
              <CreateLiveStream />
            </BroadcasterOnlyRoute>
          }
        />
        <Route
          path={`${Path.editlivestream}/:id`}
          element={
            <BroadcasterOnlyRoute>
              <EditLiveStream />
            </BroadcasterOnlyRoute>
          }
        />

        <Route path={Path.subscribe} element={<SubscriptionSkeleton />}>
          <Route path={Path.package} element={<SubscriptionPackages />} />
          <Route
            path={`${Path.package}/:id`}
            element={
              <PrivateRoute>
                <PaymentMethod />
              </PrivateRoute>
            }
          />
          <Route
            path={`${Path.payment}/:payment_no`}
            element={
              <PrivateRoute>
                <NowPaymentsPage />
              </PrivateRoute>
            }
          />
          <Route index element={<Navigate to={Path.package} />} />
        </Route>

        <Route path={Path.info} element={<TermSkeleton />}>
          <Route path={Path.privacypolicy} element={<PrivacyPolicyPage />} />
          <Route path={Path.termofuse} element={<TermOfUsePage />} />
        </Route>

        <Route
          path={Path.profile}
          element={
            <PrivateRoute>
              <RouteWrapper />
            </PrivateRoute>
          }
        >
          {/* <Route path={Path.changephone} element={<ChangePhoneNumber />} />
          <Route path={Path.changepassword} element={<ChangePassword />} /> */}
          <Route
            path={Path.changeverifydetails}
            element={<ChangeVerifyDetails />}
          />
          <Route index element={<Profile />} />
        </Route>

        <Route
          path={Path.subscription}
          element={
            <PrivateRoute>
              <RouteWrapper />
            </PrivateRoute>
          }
        >
          <Route path={`:status`} element={<Subscription />} />
          <Route index element={<Subscription />} />
        </Route>

        <Route
          path={Path.content}
          element={
            <CreatorOnlyRoute>
              <RouteWrapper />
            </CreatorOnlyRoute>
          }
        >
          <Route path={Path.upload} element={<UploadVideo />} />
          <Route path={`${Path.postdetails}/:id`} element={<EditPost />} />
          <Route index element={<Content />} />
        </Route>

        {/* <Route
          path={Path.login}
          element={
            <PublicRoute restricted>
              <LogIn />
            </PublicRoute>
          }
        />

        <Route
          path={Path.signup}
          element={
            <PublicRoute restricted>
              <SignUpSkeleton />
            </PublicRoute>
          }
        >
          <Route path={Path.userinfo} element={<UserInformation />} />
          <Route path={Path.verification} element={<Verification />} />
        </Route> */}

        <Route
          path={Path.connect}
          element={
            <PublicRoute restricted>
              <Connect />
            </PublicRoute>
          }
        />

        <Route
          path={Path.resetpassword}
          element={
            <PublicRoute restricted>
              <ResetPassword />
            </PublicRoute>
          }
        />

        <Route
          path={Path.forgotpassword}
          element={
            <PublicRoute restricted>
              <ForgotPassword />
            </PublicRoute>
          }
        />
      </Route>

      <Route path={Path.oauth} element={<OAuthRedirect />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Routing;

const RouteWrapper = () => {
  return <Outlet />;
};
