import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { useMutation, UseMutationOptions } from "react-query";

import { CountryInfoT, VideoInfoT } from "~/types";
import { UserInformationFormField } from "~/types/form";
import { appAxios, guestAxios, url } from "~/utils/axios";

type InitiatePaymentInfo = { id?: number; formData: any };

const initiatePaymentMutationFn = (
  initiatePaymentInfo: InitiatePaymentInfo
) => {
  const { id, formData } = initiatePaymentInfo;

  return appAxios
    .post(`${url.initiate_payment}/${id}/initiate-payment/`, formData)
    .then((response) => response.data);
};

export const useInitiatePaymentMutation = (
  options?: UseMutationOptions<any, AxiosError, InitiatePaymentInfo>
) => {
  return useMutation<any, AxiosError, InitiatePaymentInfo>(
    initiatePaymentMutationFn,
    options
  );
};
