import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Empty,
  Form,
  Image,
  message,
  Modal,
  Result,
  Row,
  Select,
  Spin,
  Switch,
  Typography,
  Upload,
} from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { IoEyeOutline, IoImageOutline, IoTrashBin } from "react-icons/io5";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { sprintf } from "sprintf-js";

import { PrimaryButton, SecondaryButton } from "~/components/Button";
import {
  LiveChatButton,
  LiveChatContainer,
  LiveChatText,
} from "~/components/Chat";
import { PaddedContainer } from "~/components/Container";
import { Editor } from "~/components/Editor";
import { HorizontalInput, Input, SelectInput } from "~/components/Input";
import {
  DescriptionText,
  SubTitle,
  VideoSectionTitle,
} from "~/components/Text";
import { Path } from "~/enums";
import Unauthorized from "~/pages/error/403";
import NotFound from "~/pages/error/404";
import { useUserProfileQuery } from "~/pages/profile/query";
import { url } from "~/utils/axios";
import { normFile } from "~/utils/functions";

import {
  useDeletePostMutation,
  useGetAudiencesQuery,
  useGetChatMessageQuery,
  useGetPostDetailsQuery,
  useGetStreamLinkQuery,
  useUpdatePostMutation,
} from "./query";

const EditLiveStreamPage = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [wsInstance, setWsInstance] = useState<any>(null);
  const [viewerCount, setViewerCount] = useState<any>(0);
  const [liveChatMessage, setLiveChatMessage] = useState<any>(null);
  const [chatMessageList, setChatMessageList] = useState<any>([]);

  const {
    data: audiences,
    isLoading: isLoadingAudiences,
    isError: isErrorAudiences,
  } = useGetAudiencesQuery();

  const {
    data: streamLink,
    isLoading: isLoadingStreamLink,
    isError: isErrorStreamLink,
    refetch: refetchStreamLink,
  } = useGetStreamLinkQuery(id!, {
    enabled: false,
    onSuccess: (data) => {
      Modal.info({
        icon: null,
        width: 650,
        content: (
          <>
            <div>
              <DescriptionText>Server</DescriptionText>
              <div className="mt-1">
                <Typography.Text className="font-medium text-white" copyable>
                  {data?.server}
                </Typography.Text>
              </div>
            </div>
            <div className="mt-4">
              <DescriptionText>Stream Key</DescriptionText>
              <div className="mt-1">
                <Typography.Text className="font-medium text-white" copyable>
                  {data?.stream_key}
                </Typography.Text>
              </div>
            </div>
          </>
        ),
      });
    },
  });

  const {
    data: postDetails,
    isLoading: isLoadingPostDetails,
    isError: isErrorPostDetails,
    refetch: refetchPostDetails,
  } = useGetPostDetailsQuery(id!);

  const {
    data: profileData,
    isLoading: isProfileLoading,
    isError: isProfileError,
  } = useUserProfileQuery();

  const {
    data: chatMessages,
    isLoading: isLoadingChatMessages,
    isError: isErrorChatMessages,
    refetch: refetchChatMessages,
  } = useGetChatMessageQuery(postDetails?.chatroom_id!);

  useEffect(() => {
    if (chatMessages?.length > 0) {
      setChatMessageList(chatMessages);
    }
  }, [chatMessages]);

  const { isLoading: isLoadingUpdatePost, mutate: updatePost } =
    useUpdatePostMutation({
      onSuccess: () => {
        form.resetFields();
        message.success("Post updated. Changes saved successfully.");
      },
      onError: () => {
        message.error("Failed to save changes. Please try again.");
      },
      onSettled: () => {
        queryClient.invalidateQueries(["useGetPostDetailsQuery", id]);
      },
    });

  const { isLoading: isLoadingDelete, mutate: deletePost } =
    useDeletePostMutation({
      onSuccess: () => {
        Modal.success({
          centered: true,
          title: "Delete success",
          content: "Post deleted successfully.",
          onOk() {
            navigate(`/${Path.content}`);
          },
        });
      },
      onError: () => {
        message.error("Failed to delete post. Please try again.");
      },
    });

  const onFinish = (values: any) => {
    const formData = new FormData();

    formData.append("title", values["title"]);
    formData.append(
      "live_start_at",
      moment(values["live_start_at"]).format("YYYY-MM-DD HH:mm:ss")
    );
    values["new_thumbnail"] &&
      values["new_thumbnail"]?.forEach((file: any) => {
        formData.append("thumbnails", file.originFileObj as Blob, file.name);
      });
    formData.append("description", values["description"] ?? "");
    formData.append("is_visible", values["visibility"] ? "true" : "false");
    values["audiences"]?.forEach((audience: any) => {
      formData.append("audiences", audience);
    });
    // values["tags"] &&
    //   values["tags"]?.forEach((tag: any) => {
    //     formData.append("tags", tag ?? "");
    //   });

    updatePost({ id, formData });
  };

  const onDelete = () => {
    Modal.confirm({
      title: "Confirm delete?",
      icon: <IoTrashBin />,
      content: "This post will be permanently deleted.",
      onOk() {
        deletePost({ id });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const initialiseWebSocket = () => {
    let webSocketUrl = sprintf(
      url.livechat_websocket,
      postDetails?.chatroom_id
    );

    const ws = new WebSocket(webSocketUrl);
    setWsInstance(ws as any);
  };

  const connectLiveChatWebsocket = useCallback(() => {
    if (postDetails?.chatroom_id && wsInstance) {
      wsInstance.onopen = () => {
        // connection opened
        console.log("wsInstance connection open");
      };

      wsInstance.onmessage = (e: any) => {
        // a message was received
        console.log("wsInstance connection on message", e);
        let data = JSON.parse(e.data);
        if (data?.type === "viewer.count") {
          setViewerCount(data?.viewers);
        }
        if (data?.type === "live.chat") {
          let liveChatResponse = {
            chatroom: null,
            content: data?.content,
            created_at: data?.timestamp,
            id: data?.id,
            is_reply: null,
            reply_to: null,
            sender: {
              email: null,
              fullname: data?.sender_name,
              id: data?.sender,
              member_id: null,
              photo: {
                id: null,
                file: data?.photo_url
                  ? process.env.REACT_APP_BACKEND_DOMAIN + data?.photo_url
                  : null,
                name: null,
              },
              role: null,
            },
          };
          setChatMessageList((arr: any) => [liveChatResponse, ...arr]);
        }
      };
      wsInstance.onerror = (event: any) => {
        // an error occurred
        console.log("wsInstance connection on error", event);
        let reason;
        if (event.code === 1000)
          reason =
            "Normal closure, meaning that the purpose for which the connection was established has been fulfilled.";
        else if (event.code === 1001)
          reason =
            'An endpoint is "going away", such as a server going down or a browser having navigated away from a page.';
        else if (event.code === 1002)
          reason =
            "An endpoint is terminating the connection due to a protocol error";
        else if (event.code === 1003)
          reason =
            "An endpoint is terminating the connection because it has received a type of data it cannot accept (e.g., an endpoint that understands only text data MAY send this if it receives a binary message).";
        else if (event.code === 1004)
          reason =
            "Reserved. The specific meaning might be defined in the future.";
        else if (event.code === 1005)
          reason = "No status code was actually present.";
        else if (event.code === 1006)
          reason =
            "The connection was closed abnormally, e.g., without sending or receiving a Close control frame";
        else if (event.code === 1007)
          reason =
            "An endpoint is terminating the connection because it has received data within a message that was not consistent with the type of the message (e.g., non-UTF-8 [https://www.rfc-editor.org/rfc/rfc3629] data within a text message).";
        else if (event.code === 1008)
          reason =
            'An endpoint is terminating the connection because it has received a message that "violates its policy". This reason is given either if there is no other sutible reason, or if there is a need to hide specific details about the policy.';
        else if (event.code === 1009)
          reason =
            "An endpoint is terminating the connection because it has received a message that is too big for it to process.";
        else if (event.code === 1010)
          // Note that this status code is not used by the server, because it can fail the WebSocket handshake instead.
          reason =
            "An endpoint (client) is terminating the connection because it has expected the server to negotiate one or more extension, but the server didn't return them in the response message of the WebSocket handshake. <br /> Specifically, the extensions that are needed are: " +
            event.reason;
        else if (event.code === 1011)
          reason =
            "A server is terminating the connection because it encountered an unexpected condition that prevented it from fulfilling the request.";
        else if (event.code === 1015)
          reason =
            "The connection was closed due to a failure to perform a TLS handshake (e.g., the server certificate can't be verified).";
        else reason = "Unknown reason";
        console.warn(reason);
      };
      wsInstance.onclose = (e: any) => {
        // connection closed
        console.log("wsInstance connection on close", e);
        // webSocketEventRef.current = null;
        if (wsInstance != null) {
          // console.log('reconnect every 5s');
          setTimeout(() => {
            initialiseWebSocket();
          }, 5000);
        }
      };
    }
  }, [wsInstance]);

  useEffect(() => {
    if (wsInstance) {
      connectLiveChatWebsocket();
    }
    return () => {
      // Cleanup on unmount if ws wasn't closed already
      if (wsInstance?.readyState !== 3) wsInstance?.close();
    };
  }, [wsInstance]);

  useEffect(() => {
    if (postDetails) {
      const audienceList = postDetails?.audiences.map(
        (audience: any) => audience.id
      );

      form.setFieldsValue({
        user: postDetails?.user?.id,
        live_start_at: moment(postDetails?.live_start_at),
        title: postDetails?.title,
        description: postDetails?.description,
        audiences: audienceList,
        tags: postDetails?.tags,
        visibility: postDetails?.is_visible,
      });
      initialiseWebSocket();
    }
  }, [postDetails]);

  if (
    isLoadingAudiences &&
    isLoadingPostDetails &&
    isProfileLoading &&
    isLoadingChatMessages
  ) {
    return (
      <Row justify="center">
        <Spin />
      </Row>
    );
  }

  if (isErrorPostDetails) {
    return <NotFound />;
  }

  return (
    <PaddedContainer className="pb-10 mb-20">
      <Row gutter={[32, 32]}>
        <Col xs={24} lg={24} xl={15}>
          <SubTitle>
            <Row justify="space-between" align="middle">
              <Col>{t("content.livestreaminfo")}</Col>
              <Col>
                <Button
                  type="dashed"
                  onClick={() => {
                    refetchStreamLink();
                  }}
                >
                  Generate Live Stream URL
                </Button>
              </Col>
            </Row>
          </SubTitle>

          <Form
            onFinish={onFinish}
            form={form}
            name="livestream_form"
            layout="vertical"
            size="middle"
            requiredMark={false}
            className="space-y-8 !mt-5"
          >
            <HorizontalInput
              name="live_start_at"
              label={t("date")}
              type="date"
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              rules={[{ required: true, message: "Date is required" }]}
              disabled={postDetails?.is_live_now}
            />

            <Input
              name="title"
              label={t("title")}
              rules={[{ required: true, message: "Title is required" }]}
            />

            <Form.Item
              name="description"
              label={<DescriptionText>{t("description")}</DescriptionText>}
            >
              <Editor />
            </Form.Item>

            <div>
              <DescriptionText className="mb-2">
                {t("thumbnail")}
              </DescriptionText>
              <Image src={postDetails?.thumbnail?.file} width={160} />
            </div>

            <Form.Item
              className="!w-[300px]"
              name="new_thumbnail"
              getValueFromEvent={normFile}
            >
              <Upload
                beforeUpload={() => false}
                maxCount={1}
                accept="image/*"
                listType="picture"
              >
                <Button icon={<IoImageOutline />}>{t("replace")}</Button>
              </Upload>
            </Form.Item>

            <SelectInput
              name="audiences"
              label={t("audiences")}
              options={audiences}
              nameKey="title"
              valueKey="id"
              mode="multiple"
              required
            />

            {/* <Form.Item
              name="Tags"
              label={<DescriptionText>Tags</DescriptionText>}
            >
              <Select mode="tags">
                <Select.Option value="1">h</Select.Option>
              </Select>
            </Form.Item> */}

            <Form.Item
              name="visibility"
              label={<DescriptionText>{t("publishvideo")}</DescriptionText>}
              valuePropName="checked"
              initialValue={true}
            >
              <Switch defaultChecked />
            </Form.Item>

            <div className="mb-0 text-xl space-x-4">
              <SecondaryButton
                onClick={onDelete}
                loading={isLoadingDelete}
                danger
              >
                {t("delete")}
              </SecondaryButton>
              <PrimaryButton htmlType="submit" loading={isLoadingUpdatePost}>
                {t("savechanges")}
              </PrimaryButton>
            </div>
          </Form>
        </Col>
        <Col xs={24} lg={24} xl={9}>
          <div className="mb-5 sticky h-[calc(100vh_-_280px)] top-0">
            <Row justify="space-between">
              <Col>
                <VideoSectionTitle className="mb-2">
                  {t("content.livechat")}
                </VideoSectionTitle>
              </Col>
              <Col>
                <IoEyeOutline /> {viewerCount} {t("content.watching")}
              </Col>
            </Row>
            <LiveChatContainer className="!bg-neutral-800 !border-neutral-700 p-4 rounded-md h-full w-full">
              {chatMessageList?.length > 0 &&
                chatMessageList?.map((item: any, index: any) => {
                  return (
                    <LiveChatText
                      key={item.id}
                      name={item.sender.fullname}
                      chatContent={item.content}
                      className={index !== 0 ? "mb-4" : ""}
                      url={item.sender.photo.file}
                      createdAt={item.created_at}
                    />
                  );
                })}
              {chatMessageList?.length === 0 && (
                <div className="flex h-full justify-center items-center">
                  <Empty
                    description={t("content.livechat.nochat")}
                    className="p-7"
                  />
                </div>
              )}
            </LiveChatContainer>
            <div className="pt-4 pb-1 px-3">
              <LiveChatButton
                url={profileData?.photo?.file}
                value={liveChatMessage}
                onChange={(e: any) => {
                  setLiveChatMessage(e.target.value);
                }}
                onPressEnter={(e: any) => {
                  e.preventDefault();
                  if (liveChatMessage.match(/^ *$/) === null) {
                    wsInstance.send(
                      JSON.stringify({
                        sender: profileData?.id,
                        content: liveChatMessage,
                      })
                    );
                  }
                  setLiveChatMessage(null);
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </PaddedContainer>
  );
};

export default EditLiveStreamPage;
