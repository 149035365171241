import { ReactNode } from "react";
import { Avatar, Image } from "antd";
import moment from "moment";
import { IoPerson } from "react-icons/io5";

type CommentContainerProps = {
  children?: ReactNode;
  className?: string;
};

const CommentContainer = ({ children, className }: CommentContainerProps) => {
  return <div className="">{children}</div>;
};

type CommentProps = {
  imgUrl?: string;
  name: string;
  commentText: string;
  className?: string;
  date: string;
};

const Comment = ({
  imgUrl,
  name,
  commentText,
  className,
  date,
}: CommentProps) => {
  return (
    <div className={"flex flex-row " + className}>
      <div className="mr-3">
        <Avatar
          draggable={false}
          icon={<IoPerson />}
          size={44}
          src={
            !!imgUrl && (
              <Image
                draggable={false}
                src={imgUrl}
                style={{
                  width: 44,
                  height: 44,
                  background: "white",
                  objectFit: "contain",
                }}
                preview={false}
              />
            )
          }
        />
      </div>
      <div className="flex flex-col">
        <div>
          <span className="mr-2 font-semibold">{name}</span>
          <span className="text-[#FFFFFF80]">{moment(date).fromNow()}</span>
        </div>
        <div className="break-words">
          <span className="font-light">{commentText}</span>
        </div>
      </div>
    </div>
  );
};

export { CommentContainer, Comment };
