import { ReactNode } from "react";

import styles from "./styles.module.less";

type TitleProps = {
  children?: ReactNode;
  className?: string;
};

const Title = ({ children, className }: TitleProps) => {
  return <div className={styles.title + " " + className}>{children}</div>;
};

//================

type SubTitleProps = {
  children?: ReactNode;
  className?: string;
};

const SubTitle = ({ children, className }: SubTitleProps) => {
  return <div className={styles.subTitle + " " + className}>{children}</div>;
};

//================

type VideoSectionTitleProps = {
  children?: ReactNode;
  className?: string;
};

const VideoSectionTitle = ({ children, className }: VideoSectionTitleProps) => {
  return (
    <div className={styles.videoSectionTitle + " " + className}>{children}</div>
  );
};

//================

type DescriptionTextProps = {
  children?: ReactNode;
  className?: string;
};

const DescriptionText = ({ children, className }: DescriptionTextProps) => {
  return (
    <div className={"text-xs text-neutral-500 " + className}>{children}</div>
  );
};

export { Title, SubTitle, VideoSectionTitle, DescriptionText };
