import { Outlet } from "react-router-dom";

import Header from "~/components/Header";
// import styles from "./styles.module.less";
import Sidebar from "~/components/Sidebar";

const Dashboard = () => {
  return (
    <div className="flex h-full">
      <Sidebar />
      <div className="block flex-1">
        <Header />

        <div
          id="page-content"
          className="min-h-[calc(100%-64px)] h-[calc(100%-64px)] overflow-auto"
        >
          {/* <PathBreadCrumb /> */}

          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
