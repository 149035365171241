import { AxiosError } from "axios";
import { useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { useMutation, UseMutationOptions } from "react-query";

import { CountryInfoT, UserProfileT } from "~/types";
import { UserInformationFormField } from "~/types/form";
import { appAxios, guestAxios, url } from "~/utils/axios";

type VerifyPhoneInfo = { formData: FormData };

const verifyPhoneMutationFn = (verifyPhoneInfo: VerifyPhoneInfo) => {
  const { formData } = verifyPhoneInfo;

  return appAxios
    .post(url.verify_phone, formData)
    .then((response) => response.data);
};

export const useVerifyPhoneMutation = (options: any) => {
  const queryClient = useQueryClient();

  return useMutation<any, AxiosError<{ ERROR_MSG: string }>, VerifyPhoneInfo>(
    verifyPhoneMutationFn,
    {
      ...options,
      onSettled: (data) => {
        queryClient.invalidateQueries(["profile"]);
      },
    }
  );
};

//==============

type RequestOtpInfo = { formData: { phone: string } };

const requestVerificationPhoneMutationFn = (requestOtpInfo: RequestOtpInfo) => {
  const { formData } = requestOtpInfo;

  return appAxios
    .post(url.verification_otp, formData)
    .then((response) => response.data);
};

export const useRequestVerificationPhoneMutation = (
  options?: UseMutationOptions<any, AxiosError, RequestOtpInfo>
) => {
  return useMutation<any, AxiosError, RequestOtpInfo>(
    requestVerificationPhoneMutationFn,
    options
  );
};

//==============

// type ChangePhoneInfo = { formData: any };

// const requestChangePhoneMutationFn = (changePhoneInfo: ChangePhoneInfo) => {
//   const { formData } = changePhoneInfo;

//   return appAxios
//     .post(url.change_phone, formData)
//     .then((response) => response.data);
// };

// export const useRequestChangePhoneMutation = (
//   options?: UseMutationOptions<any, AxiosError, ChangePhoneInfo>
// ) => {
//   return useMutation<any, AxiosError, ChangePhoneInfo>(
//     requestChangePhoneMutationFn,
//     options
//   );
// };

const userQueryFn = () => {
  return appAxios.get(url.profile).then((response) => {
    console.log("verify profile:", response);
    return response.data;
  });
};

// Get user profile
export const useUserProfileQuery = (options?: UseQueryOptions<any>) => {
  return useQuery<any>(
    ["profile"],
    userQueryFn,
    options
      ? {
          ...options,
          enabled: true,
        }
      : undefined
  );
};

type SignUpInfo = { formData: FormData };

const signUpMutationFn = (signUpInfo: SignUpInfo) => {
  const { formData } = signUpInfo;

  return guestAxios
    .post(url.sign_up, formData)
    .then((response) => response.data);
};

export const useSignUpMutation = (
  options?: UseMutationOptions<any, AxiosError, SignUpInfo>
) => {
  return useMutation<any, AxiosError, SignUpInfo>(signUpMutationFn, options);
};

//==============

type EmailVerificationInfo = { formData: any };

const requestVerificationMailMutationFn = (
  verifyEmail: EmailVerificationInfo
) => {
  const { formData } = verifyEmail;

  return guestAxios
    .post(url.verification_mail, formData)
    .then((response) => response.data);
};

export const useRequestVerificationMailMutation = (
  options?: UseMutationOptions<any, AxiosError, EmailVerificationInfo>
) => {
  return useMutation<any, AxiosError, EmailVerificationInfo>(
    requestVerificationMailMutationFn,
    options
  );
};

type VerifyEmailInfo = { formData: FormData };

const verifyEmailMutationFn = (verifyEmailInfo: VerifyEmailInfo) => {
  const { formData } = verifyEmailInfo;

  return appAxios
    .post(url.verify_email, formData)
    .then((response) => response.data);
};

export const useVerifyEmailMutation = (options: any) => {
  const queryClient = useQueryClient();

  return useMutation<any, AxiosError<{ ERROR_MSG: string }>, VerifyEmailInfo>(
    verifyEmailMutationFn,
    {
      ...options,
      onSettled: (data) => {
        queryClient.invalidateQueries(["profile"]);
      },
    }
  );
};
