import { Skeleton } from "antd";

const VideoPostSkeleton = () => {
  return (
    <div className="flex flex-row mt-[8px] w-full">
      <Skeleton.Image active className="!w-[170px] rounded-[12px]" />
      <div className="w-full ml-[12px] flex flex-col justify-between">
        <Skeleton.Input block active size="large" />
        <div className="flex flex-row items-center">
          <Skeleton.Avatar active className="mr-[10px]" />
          <Skeleton.Input block active size="small" />
        </div>
      </div>
    </div>
  );
};

export { VideoPostSkeleton };
