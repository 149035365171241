import { useEffect, useState } from "react";
import { Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import {
  IoArrowBackOutline,
  IoArrowForwardOutline,
  IoHomeOutline,
  IoKeyOutline,
  IoRadioOutline,
  IoVideocam,
} from "react-icons/io5";
import {
  Link,
  useLocation,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";

import images from "~/assets/images";
import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from "~/components/Button";
import { PaddedContainer } from "~/components/Container";
import { SubTitle, Title, VideoSectionTitle } from "~/components/Text";
import { VideoInfo } from "~/components/VideoInfo";
import { VideoRow } from "~/components/VideoRow";
import { useRole } from "~/contexts/role";
import { Path } from "~/enums";

import { useGetPostInfiniteQuery } from "./query";
import styles from "./styles.module.less";

const Content = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { isBroadcaster } = useRole();

  const {
    isLoading,
    isError,
    error,
    data,
    fetchNextPage,
    isFetching,
    hasNextPage,
    isFetchingNextPage,
  } = useGetPostInfiniteQuery();

  const { pages } = (data as any) || {
    pages: [],
  };

  const [videos, setVideos] = useState([]);

  useEffect(() => {
    let allPages = pages.map((page: any) => {
      return page.results;
    });

    var flattenedPages = [].concat.apply([], allPages);

    setVideos(flattenedPages);
  }, [pages]);

  const onClickVideo = (postId: string, is_live: boolean) => {
    if (is_live) {
      navigate(`/${Path.editlivestream}/${postId}`);
    } else {
      navigate(`${Path.postdetails}/${postId}`);
    }
  };

  return (
    <PaddedContainer>
      <div className="flex justify-end">
        <div className="space-x-2">
          <PrimaryButton
            onClick={() => {
              navigate(Path.upload);
            }}
          >
            <IoVideocam className="text-base" />
            {t("content.upload")}
          </PrimaryButton>
          {isBroadcaster && (
            <PrimaryButton
              onClick={() => {
                navigate(`/${Path.createlivestream}`);
              }}
            >
              <IoRadioOutline className="text-base" />
              {t("content.golive")}
            </PrimaryButton>
          )}
        </div>
      </div>

      <Title className="mb-5">{t("content.title")}</Title>

      <VideoRow
        size="small"
        data={videos}
        isLoading={isLoading}
        onClickVideo={(post_id, is_live) => {
          onClickVideo(post_id, is_live);
        }}
      />

      <div className="my-5 flex justify-center">
        <div>
          {hasNextPage ? (
            <SecondaryButton
              onClick={fetchNextPage}
              disabled={!hasNextPage}
              loading={isFetchingNextPage}
            >
              {isFetchingNextPage ? t("loadingmore") : t("loadmore")}
            </SecondaryButton>
          ) : null}
        </div>
      </div>
    </PaddedContainer>
  );
};

export default Content;
