import "../index.css";

const TermOfUsePage = () => {
  return (
    <div
      className="privacy"
      dangerouslySetInnerHTML={{
        __html: `
        <p class="underline title"><b>Terms of Use</b></p>

  <ol>
    <li class="list-title">
      <span class="small-title"><b>INTRODUCTION</b></span>

      <ol>
        <li>
          <span>
            These Terms of Use constitute a legally binding agreement made
            between you, whether personally or on behalf of an entity
            (<b>“you”</b>) and BALAENA SOFTTECH SDN. BHD. (<b>"Company"</b>,
            <b>“we”</b>, <b>“us”</b>, or <b>“our”</b>), concerning your access
            to and use of the
            <a target="_blank" href="https://www.thetradveller.com"
              >https://www.thetradveller.com</a
            >
            website as well as any other media form, media channel, mobile
            website or mobile application related, linked, or otherwise
            connected thereto (collectively, the <b>“Site”</b>). The Site enables 
            access to certain premium features or content in exchange for one-time 
            or recurring fees (each a "Paid Service"). The Paid Services include 
            The Tradveller rentals and purchases, channel memberships, paid subscriptions, 
            and other services which may be offered by BALAENA SOFTTECH SDN. BHD..
            You agree
            that by accessing the Site, you have read, understood, and agreed
            to be bound by all of these Terms of Use.
            <b
              >IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU
              ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST
              DISCONTINUE USE IMMEDIATELY.</b
            >
          </span>
        </li>

        <li>
          <span
            >Supplemental terms and conditions or documents that may be posted
            on the Site from time to time are hereby expressly incorporated
            herein by reference. We reserve the right, in our sole discretion,
            to make changes or modifications to these Terms of Use at any time
            and for any reason. We will alert you about any changes by
            updating the <b>“Last updated”</b> date of these Terms of Use, and
            you waive any right to receive specific notice of each such
            change. It is your responsibility to periodically review these
            Terms of Use to stay informed of updates. You will be subject to,
            and will be deemed to have been made aware of and to have
            accepted, the changes in any revised Terms of Use by your
            continued use of the Site after the date such revised Terms of Use
            are posted.
          </span>
        </li>

        <li>
          <span>
            The information provided on the Site is not intended for
            distribution to or use by any person or entity in any jurisdiction
            or country where such distribution or use would be contrary to law
            or regulation or which would subject us to any registration
            requirement within such jurisdiction or country. Accordingly,
            those persons who choose to access the Site from other locations
            do so on their own initiative and are solely responsible for
            compliance with local laws, if and to the extent local laws are
            applicable.
          </span>
        </li>

        <li>
          <span>
            The Site is intended for users who are at least 18 years old.
            Persons under the age of 18 are not permitted to use or register
            for the Site.
          </span>
        </li>
      </ol>
    </li>

    <li class="list-title">
      <span class="small-title"><b>INTELLECTUAL PROPERTY RIGHTS</b></span>

      <ol>
        <li>
          Unless otherwise indicated, the Site is our proprietary property and
          all source code, databases, functionality, software, website
          designs, audio, video, text, photographs, and graphics on the Site
          (collectively, the <b>“Content”</b>) and the trademarks, service
          marks, and logos contained therein (the <b>“Marks”</b>) are owned or
          controlled by us or licensed to us, and are protected by copyright
          and trademark laws and various other intellectual property rights,
          international copyright laws, and international conventions. The
          Content and the Marks are provided on the Site <b>“AS IS”</b> for
          your information and personal use only. Except as expressly provided
          in these Terms of Use, no part of the Site and no Content or Marks
          may be copied, reproduced, aggregated, republished, uploaded,
          posted, publicly displayed, encoded, translated, transmitted,
          distributed, sold, licensed, or otherwise exploited for any
          commercial purpose whatsoever, without our express prior written
          permission.
        </li>

        <li>
          Provided that you are eligible to use the Site, you are granted a
          limited license to access and use the Site and to download or print
          a copy of any portion of the Content to which you have properly
          gained access solely for your personal, non-commercial use. We
          reserve all rights not expressly granted to you in and to the Site,
          the Content and the Marks.
        </li>
      </ol>
    </li>

    <li class="list-title">
      <span class="small-title"><b>USER REPRESENTATIONS</b></span>

      <ol>
        <li>
          <span> By using the Site, you represent and warrant that:- </span>

          <ol class="roman">
            <li>
              all registration information you submit will be true, accurate,
              current, and complete;
            </li>

            <li>
              you will maintain the accuracy of such information and promptly
              update such registration information as necessary;
            </li>

            <li>
              you have the legal capacity and you agree to comply with these
              Terms of Use;
            </li>

            <li>
              you are not a minor in the jurisdiction in which you reside;
            </li>

            <li>
              you will not access the Site through automated or non-human
              means, whether through a bot, script, or otherwise;
            </li>

            <li>
              you will not use the Site for any illegal or unauthorized
              purpose; and
            </li>

            <li>
              your use of the Site will not violate any applicable law or
              regulation.
            </li>
          </ol>
        </li>

        <li>
          <span>
            If you provide any information that is untrue, inaccurate, not
            current, or incomplete, we have the right to suspend or terminate
            your account and refuse any and all current or future use of the
            Site (or any portion thereof).
          </span>
        </li>
      </ol>
    </li>

    <li class="list-title">
      <span class="small-title"><b>USER REGISTRATION</b></span>

      <ol>
        <li>
          You may be required to register with the Site. You agree to keep
          your password confidential and will be responsible for all use of
          your account and password. We reserve the right to remove, reclaim,
          or change a username you select if we determine, in our sole
          discretion, that such username is inappropriate, obscene, or
          otherwise objectionable.
        </li>
      </ol>
    </li>

    <li class="list-title">
      <span class="small-title"><b>PROHIBITED ACTIVITIES</b></span>

      <ol>
        <li>
          <span>
            You may not access or use the Site for any purpose other than that
            for which we make the Site available. The Site may not be used in
            connection with any commercial endeavors except those that are
            specifically endorsed or approved by us.
          </span>
        </li>

        <li>
          <span> As a user of the Site, you agree not to:- </span>

          <ol class="roman">
            <li>
              Systematically retrieve data or other content from the Site to
              create or compile, directly or indirectly, a collection,
              compilation, database, or directory without written permission
              from us.
            </li>

            <li>
              Trick, defraud, or mislead us and other users, especially in any
              attempt to learn sensitive account information such as user
              passwords.
            </li>

            <li>
              Circumvent, disable, or otherwise interfere with
              security-related features of the Site, including features that
              prevent or restrict the use or copying of any Content or enforce
              limitations on the use of the Site and/or the Content contained
              therein.
            </li>

            <li>
              Disparage, tarnish, or otherwise harm, in our opinion, us and/or
              the Site.
            </li>
            <li>
              Use any information obtained from the Site in order to harass,
              abuse, or harm another person.
            </li>
            <li>
              Make improper use of our support services or submit false
              reports of abuse or misconduct.
            </li>
            <li>
              Use the Site in a manner inconsistent with any applicable laws
              or regulations.
            </li>
            <li>
              Use the Site to advertise or offer to sell goods and services.
            </li>
            <li>Engage in unauthorized framing of or linking to the Site.</li>
            <li>
              Upload or transmit (or attempt to upload or to transmit)
              viruses, Trojan horses, or other material, including excessive
              use of capital letters and spamming (continuous posting of
              repetitive text), that interferes with any party’s uninterrupted
              use and enjoyment of the Site or modifies, impairs, disrupts,
              alters, or interferes with the use, features, functions,
              operation, or maintenance of the Site.
            </li>
            <li>
              Engage in any automated use of the system, such as using scripts
              to send comments or messages, or using any data mining, robots,
              or similar data gathering and extraction tools.
            </li>
            <li>
              Delete the copyright or other proprietary rights notice from any
              Content.
            </li>
            <li>
              Attempt to impersonate another user or person or use the
              username of another user.
            </li>
            <li>Sell or otherwise transfer your profile.</li>
            <li>
              Upload or transmit (or attempt to upload or to transmit) any
              material that acts as a passive or active information collection
              or transmission mechanism, including without limitation, clear
              graphics interchange formats (“gifs”), 1×1 pixels, web bugs,
              cookies, or other similar devices (sometimes referred to as
              “spyware” or “passive collection mechanisms” or “pcms”).
            </li>
            <li>
              Interfere with, disrupt, or create an undue burden on the Site
              or the networks or services connected to the Site.
            </li>
            <li>
              Harass, annoy, intimidate, or threaten any of our employees or
              agents engaged in providing any portion of the Site to you.
            </li>
            <li>
              Attempt to bypass any measures of the Site designed to prevent
              or restrict access to the Site, or any portion of the Site.
            </li>
            <li>
              Copy or adapt the Site’s software, including but not limited to
              Flash, PHP, HTML, JavaScript, or other code.
            </li>
            <li>
              Decipher, decompile, disassemble, or reverse engineer any of the
              software comprising or in any way making up a part of the Site.
            </li>
            <li>
              Except as may be the result of standard search engine or
              Internet browser usage, use, launch, develop, or distribute any
              automated system, including without limitation, any spider,
              robot, cheat utility, scraper, or offline reader that accesses
              the Site, or using or launching any unauthorized script or other
              software.
            </li>
            <li>
              Use a buying agent or purchasing agent to make purchases on the
              Site.
            </li>
            <li>
              Make any unauthorized use of the Site, including collecting
              usernames and/or email addresses of users by electronic or other
              means for the purpose of sending unsolicited email, or creating
              user accounts by automated means or under false pretenses.
            </li>
            <li>
              Use the Site as part of any effort to compete with us or
              otherwise use the Site and/or the Content for any
              revenue-generating endeavor or commercial enterprise.
            </li>
          </ol>
        </li>
      </ol>
    </li>

    <li class="list-title">
      <span class="small-title"><b>USER GENERATED CONTRIBUTIONS </b></span>

      <ol>
        <li>
          <span>
            The Site allows users to submit or post content. We may
            provide you with the opportunity to create, submit, post, display,
            transmit, perform, publish, distribute, or broadcast content and
            materials to us or on the Site, including but not limited to text,
            writings, video, audio, photographs, graphics, comments,
            suggestions, or personal information or other material
            (collectively, <b>"Contributions"</b>). Contributions may be
            viewable by other users of the Site and through third-party
            websites. As such, any Contributions you transmit may be treated
            in accordance with the Site Privacy Policy. When you create or
            make available any Contributions, you thereby represent and
            warrant that:-
          </span>

          <ol class="roman">
            <li>
              The creation, distribution, transmission, public display, or
              performance, and the accessing, downloading, or copying of your
              Contributions do not and will not infringe the proprietary
              rights, including but not limited to the copyright, patent,
              trademark, trade secret, or moral rights of any third party.
            </li>
            <li>
              You are the creator and owner of or have the necessary licenses,
              rights, consents, releases, and permissions to use and to
              authorize us, the Site, and other users of the Site to use your
              Contributions in any manner contemplated by the Site and these
              Terms of Use.
            </li>
            <li>
              You have the written consent, release, and/or permission of each
              and every identifiable individual person in your Contributions
              to use the name or likeness of each and every such identifiable
              individual person to enable inclusion and use of your
              Contributions in any manner contemplated by the Site and these
              Terms of Use.
            </li>
            <li>
              Your Contributions are not false, inaccurate, or misleading.
            </li>
            <li>
              Your Contributions are not unsolicited or unauthorized
              advertising, promotional materials, pyramid schemes, chain
              letters, spam, mass mailings, or other forms of solicitation.
            </li>
            <li>
              Your Contributions are not obscene, lewd, lascivious, filthy,
              violent, harassing, libelous, slanderous, or otherwise
              objectionable (as determined by us).
            </li>
            <li>
              Your Contributions do not ridicule, mock, disparage, intimidate,
              or abuse anyone.
            </li>
            <li>
              Your Contributions are not used to harass or threaten (in the
              legal sense of those terms) any other person and to promote
              violence against a specific person or class of people.
            </li>
            <li>
              Your Contributions do not violate any applicable law,
              regulation, or rule.
            </li>
            <li>
              Your Contributions do not violate the privacy or publicity
              rights of any third party.
            </li>
            <li>
              Your Contributions do not contain any material that solicits
              personal information from anyone under the age of 18 or exploits
              people under the age of 18 in a sexual or violent manner.
            </li>
            <li>
              Your Contributions do not violate any applicable law concerning
              child pornography, or otherwise intended to protect the health
              or well-being of minors.
            </li>
            <li>
              Your Contributions do not include any offensive comments that
              are connected to race, national origin, gender, sexual
              preference, or physical handicap.
            </li>
            <li>
              Your Contributions do not otherwise violate, or link to material
              that violates, any provision of these Terms of Use, or any
              applicable law or regulation.
            </li>
          </ol>
        </li>

        <li>
          <span>
            Any use of the Site in violation of the foregoing violates these
            Terms of Use and may result in, among other things, termination or
            suspension of your rights to use the Site.
          </span>
        </li>
      </ol>
    </li>

    <li class="list-title">
      <span class="small-title"><b>CONTRIBUTION LICENSE</b></span>

      <ol>
        <li>
          You and the Site agree that we may access, store, process, and use
          any information and personal data that you provide following the
          terms of the Privacy Policy and your choices (including settings).
        </li>
        <li>
          By submitting suggestions or other feedback regarding the Site, you
          agree that we can use and share such feedback for any purpose
          without compensation to you.
        </li>
        <li>
          We do not assert any ownership over your Contributions. You retain
          full ownership of all of your Contributions and any intellectual
          property rights or other proprietary rights associated with your
          Contributions. We are not liable for any statements or
          representations in your Contributions provided by you in any area on
          the Site. You are solely responsible for your Contributions to the
          Site and you expressly agree to exonerate us from any and all
          responsibility and to refrain from any legal action against us
          regarding your Contributions.
        </li>
      </ol>
    </li>

    <li class="list-title">
      <span class="small-title"><b>MOBILE APPLICATION LICENSE</b></span>

      <ol>
        <li>
          <span>
            <b>Use License</b>
          </span>

          <p>
If you access the Site via a mobile application, then we grant
              you a revocable, non-exclusive, non-transferable, limited right
              to install and use the mobile application on wireless electronic
              devices owned or controlled by you, and to access and use the
              mobile application on such devices strictly in accordance with
              the terms and conditions of this mobile application license
              contained in these Terms of Use. You shall not:
          </p>

          <ol class="roman">
            <li>
decompile,
              reverse engineer, disassemble, attempt to derive the source code
              of, or decrypt the application;

            </li>

            <li>
make any modification,
              adaptation, improvement, enhancement, translation, or derivative
              work from the application;
            </li>

            <li>
                  violate any applicable laws,
              rules, or regulations in connection with your access or use of
              the application;
              </li>

            <li>
               remove, alter, or obscure any proprietary
              notice (including any notice of copyright or trademark) posted
              by us or the licensors of the application;
              </li>

            <li>
               use the
              application for any revenue generating endeavor, commercial
              enterprise, or other purpose for which it is not designed or
              intended;
              </li>

            <li>
               make the application available over a network or
              other environment permitting access or use by multiple devices
              or users at the same time;
              </li>

            <li>
               use the application for creating
              a product, service, or software that is, directly or indirectly,
              competitive with or in any way a substitute for the application;
              </li>

            <li>
               use the application to send automated queries to any website
              or to send any unsolicited commercial e-mail; or
              </li>

            <li>
               use any
              proprietary information or any of our interfaces or our other
              intellectual property in the design, development, manufacture,
              licensing, or distribution of any applications, accessories, or
              devices for use with the application.
              </li>
            </li>
          </ol>
        </li>

        <li>
          <span>
            <b>Apple and Android Devices</b>
          </span>

          <p>
          The following terms apply when you use a mobile application
              obtained from either the Apple Store or Google Play (each an
              “App Distributor”) to access the Site:-
          </p>

          <ol class="roman">
            <li>
                the license granted
              to you for our mobile application is limited to a
              non-transferable license to use the application on a device that
              utilizes the Apple iOS or Android operating systems, as
              applicable, and in accordance with the usage rules set forth in
              the applicable App Distributor’s terms of service;
              </li>

              <li>
               we are
              responsible for providing any maintenance and support services
              with respect to the mobile application as specified in the terms
              and conditions of this mobile application license contained in
              these Terms of Use or as otherwise required under applicable
              law, and you acknowledge that each App Distributor has no
              obligation whatsoever to furnish any maintenance and support
              services with respect to the mobile application;
              </li>

              <li>
               in the
              event of any failure of the mobile application to conform to any
              applicable warranty, you may notify the applicable App
              Distributor, and the App Distributor, in accordance with its
              terms and policies, may refund the purchase price, if any, paid
              for the mobile application, and to the maximum extent permitted
              by applicable law, the App Distributor will have no other
              warranty obligation whatsoever with respect to the mobile
              application;
              </li>

              <li>
               you represent and warrant that (i) you are not
              located in a country that is subject to a U.S. government
              embargo, or that has been designated by the U.S. government as a
              “terrorist supporting” country and (ii) you are not listed on
              any U.S. government list of prohibited or restricted parties;
              </li>


              <li>
               you must comply with applicable third-party terms of
              agreement when using the mobile application, e.g., if you have a
              VoIP application, then you must not be in violation of their
              wireless data service agreement when using the mobile
              application; and
              </li>

              <li>
               you acknowledge and agree that the App
              Distributors are third-party beneficiaries of the terms and
              conditions in this mobile application license contained in these
              Terms of Use, and that each App Distributor will have the right
              (and will be deemed to have accepted the right) to enforce the
              terms and conditions in this mobile application license
              contained in these Terms of Use against you as a third-party
              beneficiary thereof.
            </li>
          </ol>
        </li>
      </ol>
    </li>

    <li class="list-title">
      <span class="small-title"><b>SOCIAL MEDIA </b></span>

      <ol>
        <li>
          As part of the functionality of the Site, you may link your account
          with online accounts you have with third-party service providers
          (each such account, a “Third-Party Account”) by either: (1)
          providing your Third-Party Account login information through the
          Site; or (2) allowing us to access your Third-Party Account, as is
          permitted under the applicable terms and conditions that govern your
          use of each Third-Party Account. You represent and warrant that you
          are entitled to disclose your Third-Party Account login information
          to us and/or grant us access to your Third-Party Account, without
          breach by you of any of the terms and conditions that govern your
          use of the applicable Third-Party Account, and without obligating us
          to pay any fees or making us subject to any usage limitations
          imposed by the third-party service provider of the Third-Party
          Account. By granting us access to any Third-Party Accounts, you
          understand that (1) we may access, make available, and store (if
          applicable) any content that you have provided to and stored in your
          Third-Party Account (the “Social Network Content”) so that it is
          available on and through the Site via your account, including
          without limitation any friend lists and (2) we may submit to and
          receive from your Third-Party Account additional information to the
          extent you are notified when you link your account with the
          Third-Party Account. Depending on the Third-Party Accounts you
          choose and subject to the privacy settings that you have set in such
          Third-Party Accounts, personally identifiable information that you
          post to your Third-Party Accounts may be available on and through
          your account on the Site. Please note that if a Third-Party Account
          or associated service becomes unavailable or our access to such
          Third Party Account is terminated by the third-party service
          provider, then Social Network Content may no longer be available on
          and through the Site. You will have the ability to disable the
          connection between your account on the Site and your Third-Party
          Accounts at any time. PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE
          THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY
          ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH
          THIRD-PARTY SERVICE PROVIDERS. We make no effort to review any
          Social Network Content for any purpose, including but not limited
          to, for accuracy, legality, or non-infringement, and we are not
          responsible for any Social Network Content. You acknowledge and
          agree that we may access your email address book associated with a
          Third-Party Account and your contacts list stored on your mobile
          device or tablet computer solely for purposes of identifying and
          informing you of those contacts who have also registered to use the
          Site. You can deactivate the connection between the Site and your
          Third-Party Account by contacting us using the contact information
          below or through your account settings (if applicable). We will
          attempt to delete any information stored on our servers that was
          obtained through such Third-Party Account, except the username and
          profile picture that become associated with your account.
        </li>
      </ol>
    </li>

    <li class="list-title">
      <span class="small-title"><b>SUBMISSIONS </b></span>

      <ol>
        <li>
          You acknowledge and agree that any questions, comments, suggestions,
          ideas, feedback, or other information regarding the Site
          ("Submissions") provided by you to us are non-confidential and shall
          become our sole property. We shall own exclusive rights, including
          all intellectual property rights, and shall be entitled to the
          unrestricted use and dissemination of these Submissions for any
          lawful purpose, commercial or otherwise, without acknowledgment or
          compensation to you. You hereby waive all moral rights to any such
          Submissions, and you hereby warrant that any such Submissions are
          original with you or that you have the right to submit such
          Submissions. You agree there shall be no recourse against us for any
          alleged or actual infringement or misappropriation of any
          proprietary right in your Submissions.
        </li>
      </ol>
    </li>

    <li class="list-title">
      <span class="small-title"><b>SITE MANAGEMENT </b></span>

      <ol>
        <li>
          We reserve the right, but not the obligation, to: (1) monitor the
          Site for violations of these Terms of Use; (2) take appropriate
          legal action against anyone who, in our sole discretion, violates
          the law or these Terms of Use, including without limitation,
          reporting such user to law enforcement authorities; (3) in our sole
          discretion and without limitation, refuse, restrict access to, limit
          the availability of, or disable (to the extent technologically
          feasible) any of your Contributions or any portion thereof; (4) in
          our sole discretion and without limitation, notice, or liability, to
          remove from the Site or otherwise disable all files and content that
          are excessive in size or are in any way burdensome to our systems;
          and (5) otherwise manage the Site in a manner designed to protect
          our rights and property and to facilitate the proper functioning of
          the Site.
        </li>
      </ol>
    </li>

    <li class="list-title">
      <span class="small-title"><b>PRIVACY POLICY </b></span>

      <ol>
        <li>
          We care about data privacy and security. Please review our Privacy
          Policy:
          <a target="_blank" href="https://www.thetradveller.com/info/privacy-policy"
            >https://www.thetradveller.com/info/privacy-policy</a
          >. By using the Site, you agree to be bound by our Privacy Policy,
          which is incorporated into these Terms of Use. Please be advised the
          Site is hosted in Malaysia . If you access the Site from any other
          region of the world with laws or other requirements governing
          personal data collection, use, or disclosure that differ from
          applicable laws in Malaysia , then through your continued use of the
          Site, you are transferring your data to Malaysia , and you agree to
          have your data transferred to and processed in Malaysia .
        </li>
      </ol>
    </li>

    <li class="list-title">
      <span class="small-title"><b>TERM AND TERMINATION</b></span>

      <ol>
        <li>
          These Terms of Use shall remain in full force and effect while you
          use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF
          USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
          NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING
          BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR
          NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
          REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF
          USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR
          USE OR PARTICIPATION IN THE SITE OR DELETE YOUR ACCOUNT AND ANY
          CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING,
          IN OUR SOLE DISCRETION.
        </li>

        <li>
          If we terminate or suspend your account for any reason, you are
          prohibited from registering and creating a new account under your
          name, a fake or borrowed name, or the name of any third party, even
          if you may be acting on behalf of the third party. In addition to
          terminating or suspending your account, we reserve the right to take
          appropriate legal action, including without limitation pursuing
          civil, criminal, and injunctive redress.
        </li>
      </ol>
    </li>

    <li class="list-title">
      <span class="small-title"
        ><b> MODIFICATIONS AND INTERRUPTIONS </b></span
      >

      <ol>
        <li>
          We reserve the right to change, modify, or remove the contents of
          the Site at any time or for any reason at our sole discretion
          without notice. However, we have no obligation to update any
          information on our Site. We also reserve the right to modify or
          discontinue all or part of the Site without notice at any time. We
          will not be liable to you or any third party for any modification,
          price change, suspension, or discontinuance of the Site.
        </li>

        <li>
          We cannot guarantee the Site will be available at all times. We may
          experience hardware, software, or other problems or need to perform
          maintenance related to the Site, resulting in interruptions, delays,
          or errors. We reserve the right to change, revise, update, suspend,
          discontinue, or otherwise modify the Site at any time or for any
          reason without notice to you. You agree that we have no liability
          whatsoever for any loss, damage, or inconvenience caused by your
          inability to access or use the Site during any downtime or
          discontinuance of the Site. Nothing in these Terms of Use will be
          construed to obligate us to maintain and support the Site or to
          supply any corrections, updates, or releases in connection
          therewith.
        </li>
      </ol>
    </li>

    <li class="list-title">
      <span class="small-title"><b> GOVERNING LAW </b></span>

      <ol>
        <li>
          These Terms shall be governed by and defined following the laws of
          Malaysia. BALAENA SOFTTECH SDN. BHD. and yourself irrevocably consent that
          the courts of Malaysia shall have exclusive jurisdiction to resolve
          any dispute which may arise in connection with these terms.
        </li>
      </ol>
    </li>

    <li class="list-title">
      <span class="small-title"><b> DISPUTE RESOLUTION </b></span>

      <ol>
        <li>
          You agree to irrevocably submit all disputes related to Terms or the
          relationship established by this Agreement to the jurisdiction of
          the Malaysia courts. BALAENA SOFTTECH SDN. BHD. shall also maintain the
          right to bring proceedings as to the substance of the matter in the
          courts of the country where you reside or, if these Terms are
          entered into in the course of your trade or profession, the state of
          your principal place of business.
        </li>
      </ol>
    </li>

    <li class="list-title">
      <span class="small-title"><b> CORRECTIONS </b></span>

      <ol>
        <li>
          There may be information on the Site that contains typographical
          errors, inaccuracies, or omissions, including descriptions, pricing,
          availability, and various other information. We reserve the right to
          correct any errors, inaccuracies, or omissions and to change or
          update the information on the Site at any time, without prior
          notice.
        </li>
      </ol>
    </li>

    <li class="list-title">
      <span class="small-title"><b> DISCLAIMER </b></span>

      <ol>
        <li>
          THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
          THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE
          RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
          WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR
          USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES
          OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
          ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY
          WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR
          RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
          CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF
          ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
          SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
          AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
          STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO
          OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
          WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY,
          AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR
          FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
          OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA
          THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
          RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
          THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY
          WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
          ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
          RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
          THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE
          OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT,
          YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
          APPROPRIATE.
        </li>
      </ol>
    </li>

    <li class="list-title">
      <span class="small-title"><b> LIMITATIONS OF LIABILITY </b></span>

      <ol>
        <li>
          IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
          TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
          EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
          PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM
          YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES.
        </li>
      </ol>
    </li>

    <li class="list-title">
      <span class="small-title"><b> INDEMNIFICATION </b></span>

      <ol>
        <li>
          You agree to defend, indemnify, and hold us harmless, including our
          subsidiaries, affiliates, and all of our respective officers,
          agents, partners, and employees, from and against any loss, damage,
          liability, claim, or demand, including reasonable attorneys’ fees
          and expenses, made by any third party due to or arising out of: (1)
          use of the Site; (2) breach of these Terms of Use; (3) any breach of
          your representations and warranties set forth in these Terms of Use;
          (4) your violation of the rights of a third party, including but not
          limited to intellectual property rights; or (5) any overt harmful
          act toward any other user of the Site with whom you connected via
          the Site. Notwithstanding the foregoing, we reserve the right, at
          your expense, to assume the exclusive defense and control of any
          matter for which you are required to indemnify us, and you agree to
          cooperate, at your expense, with our defense of such claims. We will
          use reasonable efforts to notify you of any such claim, action, or
          proceeding which is subject to this indemnification upon becoming
          aware of it.
        </li>
      </ol>
    </li>

    <li class="list-title">
      <span class="small-title"><b> USER DATA </b></span>

      <ol>
        <li>
          We will maintain certain data that you transmit to the Site for the
          purpose of managing the performance of the Site, as well as data
          relating to your use of the Site. Although we perform regular
          routine backups of data, you are solely responsible for all data
          that you transmit or that relates to any activity you have
          undertaken using the Site. You agree that we shall have no liability
          to you for any loss or corruption of any such data, and you hereby
          waive any right of action against us arising from any such loss or
          corruption of such data.
        </li>
      </ol>
    </li>

    <li class="list-title" id="payment-terms">
      <span class="small-title"
        ><b>
          PAYMENT
        </b></span
      >

      <ol>
        <li>
          The Site accepts payment via the payment method indicated prior to your purchase. 
          You must have a valid, accepted payment method on file in order to purchase Paid 
          Services, or to participate in no-cost trial offers or other no-cost offers 
          related to the Paid Services. You agree to pay for any Paid Service that you order. 
          BALAENA SOFTTECH SDN. BHD. will charge your payment method for the price listed for the 
          relevant Paid Service along with any additional amounts relating to applicable 
          taxes, bank fees and currency fluctuations. If you purchase any automatically 
          renewing Paid Service subscription, you agree that BALAENA SOFTTECH SDN. BHD. will charge 
          the payment method on file on the first day of each billing period for the 
          subscription, and if the payment method on file becomes invalid due to an expired 
          credit card or other reason, and BALAENA SOFTTECH SDN. BHD. is unable to charge you on the 
          next billing period, you may not be able to access the applicable Paid Service 
          until you update your payment method. Where possible, we will provide you with notice 
          when this happens. If you fail to update your payment method within a reasonable 
          amount of time following that notice, BALAENA SOFTTECH SDN. BHD. may cancel your subscription. 
          In some cases, you may transact with a third-party or affiliate platform when you 
          purchase a Paid Service. In these cases, such platform will charge your payment method 
          and be responsible for managing any issues with your payment, including refunds.
        </li>
      </ol>
    </li>

    <li class="list-title">
      <span class="small-title"
        ><b>
          ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
        </b></span
      >

      <ol>
        <li>
          Visiting the Site, sending us emails, and completing online forms
          constitute electronic communications. You consent to receive
          electronic communications, and you agree that all agreements,
          notices, disclosures, and other communications we provide to you
          electronically, via email and on the Site, satisfy any legal
          requirement that such communication be in writing. YOU HEREBY AGREE
          TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
          RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND
          RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE
          SITE. You hereby waive any rights or requirements under any
          statutes, regulations, rules, ordinances, or other laws in any
          jurisdiction which require an original signature or delivery or
          retention of non-electronic records, or to payments or the granting
          of credits by any means other than electronic means.
        </li>
      </ol>
    </li>

    <li class="list-title">
      <span class="small-title"><b> MISCELLANEOUS </b></span>

      <ol>
        <li>
          These Terms of Use and any policies or operating rules posted by us
          on the Site or in respect to the Site constitute the entire
          agreement and understanding between you and us. Our failure to
          exercise or enforce any right or provision of these Terms of Use
          shall not operate as a waiver of such right or provision. These
          Terms of Use operate to the fullest extent permissible by law. We
          may assign any or all of our rights and obligations to others at any
          time. We shall not be responsible or liable for any loss, damage,
          delay, or failure to act caused by any cause beyond our reasonable
          control. If any provision or part of a provision of these Terms of
          Use is determined to be unlawful, void, or unenforceable, that
          provision or part of the provision is deemed severable from these
          Terms of Use and does not affect the validity and enforceability of
          any remaining provisions. There is no joint venture, partnership,
          employment or agency relationship created between you and us as a
          result of these Terms of Use or use of the Site. You agree that
          these Terms of Use will not be construed against us by virtue of
          having drafted them. You hereby waive any and all defenses you may
          have based on the electronic form of these Terms of Use and the lack
          of signing by the parties hereto to execute these Terms of Use.
        </li>
      </ol>
    </li>

    <li class="list-title">
      <span class="small-title"><b>CONTACT US </b></span>

      <ol>
        <li>
          <span>
            In order to resolve a complaint regarding the Site or to receive
            further information regarding use of the Site, please contact us
            at:-
          </span>

          <table>
            <tr>
              <td>Name:</td>
              <td>BALAENA SOFTTECH SDN. BHD.</td>
            </tr>

            <tr>
              <td>Company No:</td>
              <td>201901043025 (1352355-D)</td>
            </tr>

            <tr>
              <td>Address:</td>
              <td>
              NO 2, JALAN ABD KARIM 29/KS2 TAMAN PERINDUSTRIAN SUNGAI JATI 41200 KLANG SELANGOR MALAYSIA
              </td>
            </tr>

            <tr>
              <td>Email:</td>
              <td>support@thetradveller.com</td>
            </tr>

            <tr>
              <td>Tel No:</td>
              <td>0122652653</td>
            </tr>
          </table>
        </li>

        <li>
          Please note that we have the right to refuse your request to access
          and/or make any correction to your Personal Data to the extent
          permitted under the applicable law, in which case we will notify you
          of the same by notice in writing.
        </li>
      </ol>
    </li>
  </ol>
        `,
      }}
    />
  );
};

export default TermOfUsePage;
