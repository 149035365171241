import { useLayoutEffect, useState } from "react";
import { Button, Checkbox, Col, Form, Radio, Row } from "antd";
import { useTranslation } from "react-i18next";
import {
  IoArrowBackOutline,
  IoArrowForwardOutline,
  IoHomeOutline,
  IoKeyOutline,
  IoPerson,
} from "react-icons/io5";
import { Link, Outlet, useNavigate } from "react-router-dom";

import images from "~/assets/images";
import { PrimaryButton, RadioButton, TextButton } from "~/components/Button";
import { PaddedContainer } from "~/components/Container";
import { Input } from "~/components/Input";
import { DiscordOAuth } from "~/components/OAuth";
import { DescriptionText, SubTitle, Title } from "~/components/Text";
import { useAuthContext } from "~/contexts/auth";
import { Path } from "~/enums";
import { useQueryString } from "~/utils/hooks";

import styles from "./styles.module.less";

const ConnectPage = () => {
  const navigate = useNavigate();
  const { referral } = useQueryString();
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const { setAuthInfo } = useAuthContext();

  const [currentTab, setCurrentTab] = useState("login");

  useLayoutEffect(() => {
    if (referral) {
      form.setFieldsValue({ referral_code: referral });
      setCurrentTab("signup");
    }
  }, []);

  const onOAuthSuccess = (data: any) => {
    const { access_token } = data;
    const redirectPath = sessionStorage.getItem("to");

    localStorage.setItem("access_token", access_token);

    //redirect here if redirect path is set before this
    if (redirectPath) {
      sessionStorage.removeItem("to");
      navigate(`${redirectPath}`);
    }

    //else let Route component handle redirect when set isAuthenticated to true
    setAuthInfo({ isAuthenticated: true, isVerifyingToken: false });
  };

  const getReferralValue = () => {
    return form.getFieldValue("referral_code");
  };

  return (
    <div className="relative overflow-hidden min-h-full">
      <PaddedContainer className="space-y-4">
        <Row gutter={[24, 12]}>
          <Col
            xs={{ order: 2, span: 24 }}
            md={{ order: 1, span: 10 }}
            className="space-y-8 md:!p-12"
          >
            <div>
              <Radio.Group
                className="space-x-4"
                value={currentTab}
                onChange={(e) => {
                  setCurrentTab(e.target.value);
                }}
              >
                <RadioButton value="login" className="!p-0">
                  <Title>{t("connect.login")}</Title>
                </RadioButton>
                <span className="text-[2rem] font-semibold">/</span>
                <RadioButton value="signup" className="!p-0">
                  <Title>{t("connect.signup")}</Title>
                </RadioButton>
              </Radio.Group>
              <div className="mt-4">{t("connect.subtitle")}</div>
            </div>
            <div>
              <div>{t("connect.discordsignup")}</div>
              <TextButton
                className="!p-0 mix-blend-difference"
                onClick={() => {
                  window.open("https://discord.com/register", "_blank");
                }}
              >
                {t("connect.creatediscord")}
              </TextButton>
            </div>

            {currentTab == "signup" && (
              <Form
                // onFinish={onFinish}
                form={form}
                name="userinformation_form"
                layout="vertical"
                size="middle"
                requiredMark={false}
                className="space-y-2"
              >
                <div>有推荐人？请在此输入推荐码。</div>
                <Input name="referral_code" label={t("referralcode")} />
              </Form>
            )}

            <div className="!mt-10">
              <DiscordOAuth
                getReferral={getReferralValue}
                onTokenSuccess={onOAuthSuccess}
              />
            </div>
          </Col>
          <Col xs={{ order: 1, span: 24 }} md={{ order: 2, span: 14 }}>
            <div className="flex justify-center md:block py-7 px-7 md:px-2 md:mt-16">
              <img src={images.tradvellerLogo} width={500} />
            </div>
          </Col>
        </Row>
      </PaddedContainer>

      <div className="hidden rotate-180 md:block absolute bottom-[-40px] right-[-150px] -z-10">
        <img src={images.blob3} width={600} />
      </div>
    </div>
  );
};

export default ConnectPage;
