import { AxiosError } from "axios";
import {
  QueryFunctionContext,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";

import { guestAxios, url } from "~/utils/axios";

const oAuthUrlFn = (context: any) => {
  const [_, provider, redirect_uri] = context.queryKey;

  return guestAxios
    .get<any>(url.oauth_auth_url, {
      params: { redirect_uri, provider },
    })
    .then((response) => response.data);
};

export const useOAuthUrlQuery = (
  provider: string,
  redirect_uri: string,
  options?: UseQueryOptions<any, Error | AxiosError<{ detail: string }>>
) => {
  return useQuery<any, Error | AxiosError<{ detail: string }>>(
    ["oauth-url", provider, redirect_uri],
    oAuthUrlFn,
    {
      // enabled: false,
      retry: false,
      ...options,
    }
  );
};

//=====================

type OAuthInfo = {
  formData: {
    code: string;
    provider: string;
    redirect_uri: string;
    referral_code?: string;
  };
};

const oAuthTokenMutationFn = (oAuthInfo: OAuthInfo) => {
  const { formData } = oAuthInfo;

  return guestAxios
    .post(url.oauth_token, formData)
    .then((response) => response.data);
};

export const useOAuthTokenMutation = (
  options?: UseMutationOptions<any, AxiosError, OAuthInfo>
) => {
  return useMutation<any, AxiosError, OAuthInfo>(oAuthTokenMutationFn, options);
};
