import { AxiosError } from "axios";
import { IoHelp } from "react-icons/io5";
import {
  QueryFunction,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { useLocation, useSearchParams } from "react-router-dom";

import { CountryInfoT, UserProfileT } from "~/types";
import { appAxios, guestAxios, url } from "~/utils/axios";

// type VerifyReturn = {
//   is_nominee: boolean;
// };

// const verifyTokenFn: QueryFunction<VerifyReturn> = () => {
//   const token = localStorage.getItem("access_token");

//   console.log(token);

//   if (token === undefined) {
//     throw new Error("No token is provided");
//   }

//   return guestAxios
//     .post<VerifyReturn>(url.profile, { token })
//     .then((response) => response.data);
// };

// export const useVerifyToken = (options?: UseQueryOptions<VerifyReturn>) => {
//   const token = localStorage.getItem("access_token");

//   const [searchParams] = useSearchParams();
//   const location = useLocation();

//   if (searchParams.get("redirect") === "yes" && !token) {
//     sessionStorage.setItem("to", location.pathname);
//   }

//   return useQuery<VerifyReturn>(
//     ["authenticate-token"],
//     verifyTokenFn,
// options
//   ? {
//       ...options,
//       retry: false,
//       staleTime: 0,
//       cacheTime: 0,
//       enabled: !!token,
//     }
//   : undefined
//   );
// };

const userQueryFn = () => {
  return appAxios.get(url.profile).then((response) => {
    console.log("profile profile:", response);
    return response.data;
  });
};

// Get user profile
export const useUserProfileQuery = (
  options?: UseQueryOptions<UserProfileT>
) => {
  return useQuery<UserProfileT>(
    ["profile"],
    userQueryFn,
    options
      ? {
          ...options,
          enabled: true,
        }
      : undefined
  );
};

const countryListQueryFn = () => {
  return guestAxios
    .get<CountryInfoT[]>(`${url.get_countries}`)
    .then((response) => response.data);
};

export const useCountryListQuery = (
  options?: UseQueryOptions<
    CountryInfoT[],
    Error | AxiosError<{ detail: string }>
  >
) => {
  return useQuery<CountryInfoT[], Error | AxiosError<{ detail: string }>>(
    "country",
    countryListQueryFn,
    options
  );
};

type ProfileInfo = { formData: any; method: string };

const UpdateProfileMutationFn = (profileInfo: ProfileInfo) => {
  const { formData, method } = profileInfo;

  return appAxios({
    method,
    url: url.update_profile,
    data: formData,
  }).then((response) => response.data);
};

export const useUpdateProfileMutation = (options: any) => {
  const { onSuccess: userOnSuccess } = options;
  const queryClient = useQueryClient();

  return useMutation<any, AxiosError, ProfileInfo>(UpdateProfileMutationFn, {
    ...options,
    onSettled: (data) => {
      queryClient.invalidateQueries(["profile"]);
    },
  });
};

// type ProfileInfo = { formData: FormData };

// const updateProfileMutationFn = (profileInfo: ProfileInfo) => {
//   const { formData } = initialProfileInfo;

//   return appAxios
//     .patch<string>(url.update_profile, formData)
//     .then((response) => response.data);
// };

// export const useUpdateProfileMutation = (
//   options?: UseMutationOptions<any, AxiosError, InitialProfileInfo>
// ) => {
//   const queryClient = useQueryClient();

//   return useMutation<any, AxiosError, InitialProfileInfo>(
//     initialUpdateProfileMutationFn,
//     {
//       ...options,
//       onSuccess: () => {
//         queryClient.invalidateQueries(["profile"]);
//       },
//     }
//   );
// };
