import { AxiosError } from "axios";
import { useInfiniteQuery, useQuery, UseQueryOptions } from "react-query";

import { VideoInfoT } from "~/types";
import { appAxios, guestAxios, url } from "~/utils/axios";

type GetPostResponse = {
  results: VideoInfoT[];
  next: string;
};

const getPostQueryFn = (context: any) => {
  const [_, publisher_id] = context.queryKey;
  const { pageParam: nextPageUrl } = context;

  return appAxios
    .get<GetPostResponse>(nextPageUrl ?? url.get_posts, {
      params: nextPageUrl ? {} : { publisher_id },
    })
    .then((response) => response.data);
};

const useGetPostInfiniteQuery = (publisher_id?: string, options?: any) => {
  return useInfiniteQuery<
    GetPostResponse,
    Error | AxiosError<{ detail: string }>
  >(["post-infinite", publisher_id], getPostQueryFn, {
    ...options,
    getNextPageParam: (lastPage, pages) => {
      return lastPage.next;
    },
  });
};

const getPublisherDetailsQueryFn = (member_id: string) => {
  return appAxios
    .get(`${url.get_publisher_details}/${member_id}/details/`)
    .then((response) => response.data);
};

const useGetPublisherDetailsQuery = (
  member_id: string,
  options?: UseQueryOptions<any, Error | AxiosError<{ detail: string }>>
) => {
  return useQuery<any, Error | AxiosError<{ detail: string }>>(
    ["publisher", member_id],
    () => getPublisherDetailsQueryFn(member_id),
    options
  );
};

const liveSchedulesQueryFn = (context: any) => {
  const [_, publisher_id] = context.queryKey;

  return guestAxios
    .get<any>(url.live_schedules, {
      params: { page_size: 0, publisher_id },
    })
    .then((response) => response.data);
};

const useLiveSchedulesQuery = (
  publisher_id?: string,
  options?: UseQueryOptions<any, Error | AxiosError<{ detail: string }>>
) => {
  return useQuery<VideoInfoT[], Error | AxiosError<{ detail: string }>>(
    ["live_schedule", publisher_id],
    liveSchedulesQueryFn,
    options
  );
};

export {
  useGetPostInfiniteQuery,
  useGetPublisherDetailsQuery,
  useLiveSchedulesQuery,
};
