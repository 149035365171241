import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import { useTranslation } from "react-i18next";
import {
  IoArrowBackOutline,
  IoArrowForwardOutline,
  IoCallOutline,
  IoChatbubbleEllipses,
  IoChatbubbleEllipsesOutline,
  IoHomeOutline,
  IoKeyOutline,
  IoLocationOutline,
  IoMailOpenOutline,
  IoPhoneLandscapeSharp,
  IoPhonePortraitOutline,
} from "react-icons/io5";
import { Link } from "react-router-dom";

import images from "~/assets/images";
import { PaddedContainer } from "~/components/Container";
import { DescriptionText, SubTitle, Title } from "~/components/Text";

import ContactUsForm from "./ContactUsForm";
import { useGetOrganisationDetailsQuery } from "./query";
import styles from "./styles.module.less";

const ContactUsPage = () => {
  const { t, i18n } = useTranslation();
  const { data, isLoading, isError, refetch } =
    useGetOrganisationDetailsQuery();

  return (
    <PaddedContainer>
      <Title>{t("contactus.title")}</Title>
      <Row gutter={[48, 24]} className="mt-8">
        <Col xs={24} lg={9} className="space-y-8">
          <div>
            <SubTitle className="mb-5">
              {t("contactus.contactinformation")}
            </SubTitle>
            <div className="space-y-4 mt-3">
              {/* {data?.addresses.map(({ address }: { address: string }) => (
                <div className="flex space-x-2">
                  <IoLocationOutline className="text-base text-green-500" />
                  <DescriptionText>{address}</DescriptionText>
                </div>
              ))} */}
              {data?.phones.map(({ phone }: { phone: string }) => (
                <div className="flex space-x-2">
                  <IoCallOutline className="text-base text-green-500" />
                  <DescriptionText>{phone}</DescriptionText>
                </div>
              ))}
              {data?.emails.map(({ email }: { email: string }) => (
                <div className="flex space-x-2">
                  <IoMailOpenOutline className="text-base text-green-500" />
                  <DescriptionText>{email}</DescriptionText>
                </div>
              ))}
              <div className="flex space-x-2">
                <IoChatbubbleEllipsesOutline className="text-base text-green-500" />
                <DescriptionText>
                  <a
                    href="https://discord.gg/NHjk6D7w8Q"
                    target="_blank"
                    rel="noreferrer"
                  >
                    TheTradveller (Discord)
                  </a>
                </DescriptionText>
              </div>
            </div>
          </div>

          <div>
            <SubTitle className="mb-3">
              {t("contactus.socialmedialinks")}
            </SubTitle>
            <div className="space-y-4 mt-3">
              {data?.social_media.map((social: any) => (
                <div
                  className="flex space-x-4 cursor-pointer items-center"
                  onClick={() => {
                    window.open(social.social_media_link, "_blank");
                  }}
                >
                  <img
                    src={images.socialLogo[social.social_media as keyof any]}
                    width={38}
                  />
                  <DescriptionText>
                    {social.social_media_handle ?? social.social_media}
                  </DescriptionText>
                </div>
              ))}
            </div>
          </div>
        </Col>
        <Col xs={24} lg={15}>
          <SubTitle className="mb-5">{t("contactus.dropmessage")}</SubTitle>
          <div className="mt-1">
            <ContactUsForm />
          </div>
        </Col>
      </Row>
    </PaddedContainer>
  );
};

export default ContactUsPage;
