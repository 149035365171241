export enum Path {
  default = "/",
  home = "home",
  browse = "browse",
  aboutus = "aboutus",
  contactus = "contactus",
  login = "login",
  signup = "signup",
  userinfo = "user-information",
  verification = "verification",
  subscribe = "subscribe",
  package = "package",
  profile = "profile",
  subscription = "subscription",
  event = "event",
  changephone = "change-phone",
  changepassword = "change-password",
  content = "content",
  upload = "upload",
  postdetails = "details",
  resetpassword = "reset-password",
  forgotpassword = "forgot-password",
  payment = "payment",
  createlivestream = "livestream/create",
  editlivestream = "livestream/edit",
  video = "video",
  info = "info",
  privacypolicy = "privacy-policy",
  termofuse = "term-of-use",
  connect = "connect",
  changeverifydetails = "change-verify-details",
  oauth = "oauth",
  channel = "channel",
}
