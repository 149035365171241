import { useEffect } from "react";
import { Button, Col, Form, message, Row, Select, Switch, Upload } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { IoImageOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import { PrimaryButton } from "~/components/Button";
import { PaddedContainer } from "~/components/Container";
import { Editor } from "~/components/Editor";
import { HorizontalInput, Input, SelectInput } from "~/components/Input";
import { DescriptionText, SubTitle } from "~/components/Text";
import { Path } from "~/enums";
import { useUserProfileQuery } from "~/pages/profile/query";

import {
  useCreateLivePostMutation,
  useGetAudiencesQuery,
  useGetBroadcasterQuery,
} from "./query";

const CreateLiveStreamPage = () => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const {
    data: audiences,
    isLoading: isLoadingAudiences,
    isError: isErrorAudiences,
  } = useGetAudiencesQuery();

  const {
    data: broadcasters,
    isLoading: isLoadingBroadcasters,
    isError: isErrorBroadcasters,
  } = useGetBroadcasterQuery();

  const {
    data: profileData,
    isLoading: isProfileLoading,
    isError: isProfileError,
  } = useUserProfileQuery();

  const { isLoading: isLoadingCreateLivePost, mutate: createLivePost } =
    useCreateLivePostMutation({
      onSuccess: (data) => {
        message.success("Live stream created successfully.");
        form.resetFields();
        if (data.post_id) navigate(`/${Path.editlivestream}/${data.post_id}`);
      },
      onError: (error: any) => {
        message.error(error.response?.data);
      },
    });

  const onFinish = (values: any) => {
    const formData = new FormData();
    formData.append("user", values["user"] ?? profileData?.id);
    formData.append("title", values["title"]);
    formData.append(
      "live_start_at",
      moment(values["live_start_at"]).format("YYYY-MM-DD HH:mm:ss")
    );
    values["thumbnail"]?.fileList &&
      values["thumbnail"]?.fileList?.forEach((file: any) => {
        formData.append("thumbnail", file.originFileObj as Blob, file.name);
      });
    formData.append("description", values["description"] ?? "");
    formData.append("is_visible", values["visibility"] ? "true" : "false");
    values["audiences"]?.forEach((audience: any) => {
      formData.append("audiences", audience);
    });
    // values["tags"] &&
    //   values["tags"]?.forEach((tag: any) => {
    //     formData.append("tags", tag ?? "");
    //   });

    createLivePost({ formData: formData });
  };

  useEffect(() => {
    if (
      profileData &&
      profileData?.role !== "MEMBER" &&
      profileData?.is_broadcaster
    ) {
      form.setFieldsValue({
        user: profileData?.id,
      });
    }
  }, [profileData]);

  return (
    <PaddedContainer className="pb-10">
      <Row gutter={32}>
        <Col xs={24} lg={24} xl={16}>
          <SubTitle>{t("content.newlive")}</SubTitle>

          <Form
            onFinish={onFinish}
            form={form}
            name="livestream_form"
            layout="vertical"
            size="middle"
            requiredMark={false}
            className="space-y-8 !mt-5"
          >
            {profileData?.role !== "MEMBER" && (
              <SelectInput
                name="user"
                label={t("broadcaster")}
                options={broadcasters}
                nameKey="email"
                valueKey="id"
                required
                optionFilterProp="children"
                showSearch
              />
            )}

            <HorizontalInput
              name="live_start_at"
              label={t("date")}
              type="date"
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              rules={[{ required: true, message: "Date is required" }]}
            />

            <Input
              name="title"
              label={t("title")}
              rules={[{ required: true, message: "Title is required" }]}
            />

            <Form.Item
              name="description"
              label={<DescriptionText>{t("description")}</DescriptionText>}
            >
              <Editor />
            </Form.Item>

            <Form.Item
              name="thumbnail"
              className="!w-[300px]"
              label={<DescriptionText>{t("thumbnail")}</DescriptionText>}
              rules={[{ required: true, message: "Thumbnail is required" }]}
            >
              <Upload
                beforeUpload={() => false}
                maxCount={1}
                accept="image/*"
                listType="picture"
              >
                <Button icon={<IoImageOutline />}>{t("upload")}</Button>
              </Upload>
            </Form.Item>

            <SelectInput
              name="audiences"
              label={t("audiences")}
              options={audiences}
              nameKey="title"
              valueKey="id"
              mode="multiple"
              required
            />

            {/* <Form.Item
              name="Tags"
              label={<DescriptionText>Tags</DescriptionText>}
            >
              <Select mode="tags">
                <Select.Option value="1">h</Select.Option>
              </Select>
            </Form.Item> */}

            <Form.Item
              name="visibility"
              label={<DescriptionText>{t("publishvideo")}</DescriptionText>}
              valuePropName="checked"
              initialValue={true}
            >
              <Switch defaultChecked />
            </Form.Item>

            <div className="mb-0 text-xl space-x-4">
              <PrimaryButton
                htmlType="submit"
                loading={isLoadingCreateLivePost}
              >
                {t("create")}
              </PrimaryButton>
            </div>
          </Form>
        </Col>
      </Row>
    </PaddedContainer>
  );
};

export default CreateLiveStreamPage;
