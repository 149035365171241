import React, { useCallback, useEffect, useState } from "react";
import { Alert, Button, Card, message, Result, Row, Spin } from "antd";
import CryptoJS from "crypto-js";
import moment from "moment";
import QRCode from "react-qr-code";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { sprintf } from "sprintf-js";

import { CopyOutlined } from "@ant-design/icons";

import { PaddedContainer } from "~/components/Container";
import { Path } from "~/enums";
import { url } from "~/utils/axios";

import { useUserProfileQuery } from "./query";

const NowPaymentsPage = () => {
  const location = useLocation();

  const { paymentDetails: locationPaymentDetails } =
    (location.state as any) || {};

  const { data, isLoading, isError, refetch } = useUserProfileQuery();

  const { payment_no } = useParams();
  const navigate = useNavigate();
  const [paymentDetails, setPaymentDetails] = useState<any>({});
  const [pageLoading, setPageLoading] = useState(true);
  const [wsInstance, setWsInstance] = useState<any>(null);
  const [linkExpired, setLinkExpired] = useState(false);

  useEffect(() => {
    fetchPaymentDetailsRequest();
  }, []);

  const fetchPaymentDetailsRequest = () => {
    setPageLoading(true);
    setPaymentDetails(locationPaymentDetails);
    initialiseWebSocket(data?.member_id);
    setPageLoading(false);

    //check order status
    if (paymentDetails?.payment_status !== "Processing") {
      // navigate(`/${Path.subscription}/success`);
    } else {
      //check link expired
      let valid_until = moment(paymentDetails?.expiration_estimate_date);
      var now = moment();

      if (now > valid_until) setLinkExpired(true);
    }
    //     getPaymentDetails({
    //       payment_no,
    //     })
    //       .then((response) => {
    //         setPaymentDetails(response.data);
    //         initialiseWebSocket(response.data?.user?.id);
    //         setPageLoading(false);

    //         //check order status
    //         if (response.data?.status !== "Processing") {
    //           navigate(`/account/orders/${response.data?.order_id}`);
    //         } else {
    //           //check link expired
    //           let valid_until = moment(response.data?.valid_until);
    //           var now = moment();

    //           if (now > valid_until) setLinkExpired(true);
    //         }
    //       })
    //       .catch((error) => {
    //         setPageLoading(false);
    //         navigate("*");
    //       });
  };

  const initialiseWebSocket = (userId?: any) => {
    let webSocketUrl = sprintf(url.payment_websocket, payment_no);

    let signature = CryptoJS.HmacSHA512(payment_no as string, userId).toString(
      CryptoJS.enc.Hex
    );

    let authUrl = `${webSocketUrl}?key=${signature}`;

    const ws = new WebSocket(authUrl);
    setWsInstance(ws as any);
  };

  useEffect(() => {
    if (wsInstance) {
      connectPaymentWebsocket();
    }
    return () => {
      // Cleanup on unmount if ws wasn't closed already
      if (wsInstance?.readyState !== 3) wsInstance?.close();
    };
  }, [wsInstance]);

  const connectPaymentWebsocket = useCallback(() => {
    if (payment_no && wsInstance) {
      wsInstance.onopen = () => {
        // connection opened
        console.log("wsInstance connection open");
      };

      wsInstance.onmessage = (e: any) => {
        // a message was received
        console.log("wsInstance connection on message", e);
        let data = JSON.parse(e.data);
        // console.log('on msg data: ', data);
        if (data.status !== "Processing") {
          if (data.status == "Paid") {
            navigate(`/${Path.subscription}/success`);
          } else {
            navigate(`/${Path.subscription}`);
          }
          // navigate(`/account/orders/${paymentDetails?.order_id}`)
        }
      };
      wsInstance.onerror = (event: any) => {
        // an error occurred
        console.log("wsInstance connection on error", event);
        let reason;
        if (event.code === 1000)
          reason =
            "Normal closure, meaning that the purpose for which the connection was established has been fulfilled.";
        else if (event.code === 1001)
          reason =
            'An endpoint is "going away", such as a server going down or a browser having navigated away from a page.';
        else if (event.code === 1002)
          reason =
            "An endpoint is terminating the connection due to a protocol error";
        else if (event.code === 1003)
          reason =
            "An endpoint is terminating the connection because it has received a type of data it cannot accept (e.g., an endpoint that understands only text data MAY send this if it receives a binary message).";
        else if (event.code === 1004)
          reason =
            "Reserved. The specific meaning might be defined in the future.";
        else if (event.code === 1005)
          reason = "No status code was actually present.";
        else if (event.code === 1006)
          reason =
            "The connection was closed abnormally, e.g., without sending or receiving a Close control frame";
        else if (event.code === 1007)
          reason =
            "An endpoint is terminating the connection because it has received data within a message that was not consistent with the type of the message (e.g., non-UTF-8 [https://www.rfc-editor.org/rfc/rfc3629] data within a text message).";
        else if (event.code === 1008)
          reason =
            'An endpoint is terminating the connection because it has received a message that "violates its policy". This reason is given either if there is no other sutible reason, or if there is a need to hide specific details about the policy.';
        else if (event.code === 1009)
          reason =
            "An endpoint is terminating the connection because it has received a message that is too big for it to process.";
        else if (event.code === 1010)
          // Note that this status code is not used by the server, because it can fail the WebSocket handshake instead.
          reason =
            "An endpoint (client) is terminating the connection because it has expected the server to negotiate one or more extension, but the server didn't return them in the response message of the WebSocket handshake. <br /> Specifically, the extensions that are needed are: " +
            event.reason;
        else if (event.code === 1011)
          reason =
            "A server is terminating the connection because it encountered an unexpected condition that prevented it from fulfilling the request.";
        else if (event.code === 1015)
          reason =
            "The connection was closed due to a failure to perform a TLS handshake (e.g., the server certificate can't be verified).";
        else reason = "Unknown reason";
        console.warn(reason);
      };
      wsInstance.onclose = (e: any) => {
        // connection closed
        console.log("wsInstance connection on close", e);
        // webSocketEventRef.current = null;
        if (wsInstance != null) {
          // console.log('reconnect every 5s');
          setTimeout(() => {
            initialiseWebSocket();
          }, 5000);
        }
      };
    }
  }, [wsInstance]);

  return (
    <>
      <PaddedContainer>
        <Alert
          style={{ marginBottom: 20 }}
          message="重要提示: 在高峰时段您的订单或将需要更长的处理时间。若已付款可前往购买清单查看最新的订单状态。"
          type="warning"
          showIcon
          closable
          action={
            <Button
              size="small"
              type="link"
              onClick={() => {
                navigate(`/${Path.subscription}`);
              }}
            >
              立即前往
            </Button>
          }
        />
        {pageLoading && <Spin />}

        {!pageLoading && (
          <>
            <Row justify="center">
              <Card
                style={{
                  width: "100%",
                  borderRadius: 20,
                  padding: "8px 16px",
                }}
                title={
                  <Row justify="start">订单号: {paymentDetails?.order_id}</Row>
                }
                extra={
                  paymentDetails?.payment_status === "Processing" &&
                  !linkExpired && <Spin></Spin>
                }
              >
                <div
                  style={{
                    border: "1px solid #00000010",
                    padding: 24,
                    borderRadius: 5,
                  }}
                >
                  <Row
                    justify="center"
                    align="bottom"
                    style={{
                      fontSize: 30,
                      fontWeight: 700,
                      color: "#fa8c16",
                      lineHeight: 0.7,
                      marginBottom: 40,
                      marginTop: 10,
                    }}
                  >
                    {`${paymentDetails?.pay_amount}`}&nbsp;
                    <span style={{ fontSize: 18 }}>
                      {paymentDetails?.pay_currency?.toUpperCase()}
                    </span>
                  </Row>
                  <Row
                    justify="center"
                    style={{ width: "100%", marginBottom: 25 }}
                  >
                    请扫描以下二维码进行支付或汇款至以下地址
                  </Row>
                  {linkExpired && (
                    <Result
                      status="error"
                      subTitle="此二维码已过期"
                      extra={
                        <Button type="primary">
                          <Link to={`${Path.home}`}>返回首页</Link>
                        </Button>
                      }
                    />
                  )}
                  {!linkExpired && (
                    <>
                      {paymentDetails?.pay_address && (
                        <Row justify="center">
                          <QRCode
                            size={160}
                            value={paymentDetails?.pay_address}
                          />
                        </Row>
                      )}
                      <Row justify="center" style={{ marginTop: 25 }}>
                        <Button
                          type="link"
                          onClick={() => {
                            navigator.clipboard
                              .writeText(paymentDetails?.pay_address)
                              .then(
                                function () {
                                  message.success({
                                    content: "已复制到剪贴板",
                                    key: "clipboard-copy-success-message",
                                    style: {
                                      marginTop: "52px",
                                    },
                                  });
                                },
                                function () {
                                  /* clipboard write failed */
                                }
                              );
                          }}
                        >
                          {paymentDetails?.pay_address} <CopyOutlined />
                        </Button>
                      </Row>
                    </>
                  )}
                </div>

                {!linkExpired && (
                  <Row justify="center" style={{ marginTop: 40, color: "red" }}>
                    *请在
                    <b>
                      &nbsp;
                      {moment(paymentDetails?.expiration_estimate_date).format(
                        "YYYY-MM-DD, h:mm A"
                      )}
                      &nbsp;
                    </b>
                    前完成支付,以免订单失效
                  </Row>
                )}
              </Card>
            </Row>
          </>
        )}
      </PaddedContainer>
    </>
  );
};

export default NowPaymentsPage;
