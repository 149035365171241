import {
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Breadcrumb,
  Button,
  Drawer,
  Grid,
  Radio,
  Spin,
  Statistic,
  Tooltip,
} from "antd";
import { icons } from "antd/lib/image/PreviewGroup";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.less";

type ButtonProps = {
  children?: ReactNode;
  size?: "large" | "middle" | "small";
  htmlType?: "submit";
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  danger?: boolean;
  loading?: boolean;
};

const PrimaryButton = ({
  children,
  size = "middle",
  htmlType,
  onClick,
  loading,
}: ButtonProps) => {
  return (
    <Button
      type="primary"
      className={styles.primaryButton + " space-x-2"}
      size={size}
      htmlType={htmlType}
      onClick={onClick}
      loading={loading}
    >
      {children}
    </Button>
  );
};

const SecondaryButton = ({
  children,
  onClick,
  size = "middle",
  className,
  disabled,
  loading,
  danger,
}: ButtonProps) => {
  return (
    <Button
      type="default"
      className={styles.secondaryButton + " space-x-1 " + className}
      onClick={onClick}
      size={"middle"}
      disabled={disabled}
      loading={loading}
      danger={danger}
    >
      {children}
    </Button>
  );
};

const TertiaryButton = ({ children }: ButtonProps) => {
  return (
    <Button type="default" className={styles.tertiaryButton}>
      {children}
    </Button>
  );
};

const TextButton = ({
  children,
  onClick,
  danger,
  className,
  disabled,
}: ButtonProps) => {
  return (
    <Button
      type="link"
      onClick={onClick}
      danger={danger}
      className={className}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

type RadioButtonProps = {
  value: string;
  children?: ReactNode;
  className?: string;
};

const RadioButton = ({ value, children, className }: RadioButtonProps) => {
  return (
    <Radio.Button
      className={styles.radioButton + " " + className}
      value={value}
    >
      {children}
    </Radio.Button>
  );
};

type OTPButtonProps = {
  isLoading: boolean;
  duration: number;
  onClick: () => void;
};

const OTPButton = forwardRef(
  ({ isLoading, duration, onClick }: OTPButtonProps, ref) => {
    const { t, i18n } = useTranslation();
    const [countdownDeadline, setCountdownDeadline] = useState<number>(0);

    const startCountdown = () => {
      setCountdownDeadline(Date.now() + duration);
    };

    useImperativeHandle(ref, () => ({
      startCountdown,
    }));

    const onCountdownFinish = () => {
      setCountdownDeadline(0);
    };

    if (isLoading) {
      return <Spin size="small" />;
    }

    if (countdownDeadline) {
      return (
        <div className="flex">
          <Statistic.Countdown
            value={countdownDeadline}
            onFinish={onCountdownFinish}
            format="s"
            valueStyle={{ fontSize: "13px" }}
          />{" "}
          s
        </div>
      );
    }

    return (
      <Button type="link" className="!p-0" onClick={onClick}>
        {t("changeverify.getcode")}
      </Button>
    );
  }
);

export {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  RadioButton,
  TextButton,
  OTPButton,
};
