import {
  FC,
  HTMLAttributes,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Breadcrumb, Drawer, Grid, Tooltip } from "antd";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  IoArrowBackOutline,
  IoArrowForwardOutline,
  IoBodyOutline,
  IoBookmarkOutline,
  IoChatboxEllipsesOutline,
  IoHomeOutline,
  IoKeyOutline,
  IoLogOutOutline,
  IoMenuOutline,
  IoPeopleCircle,
  IoPlayCircleOutline,
  IoPlayOutline,
  IoSendOutline,
  IoTvOutline,
} from "react-icons/io5";
import {
  Link,
  useLocation,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";

import images from "~/assets/images";
import { useAuthContext } from "~/contexts/auth";
import { useRole } from "~/contexts/role";
import { useSidebarContext } from "~/contexts/sidebar";
import { Path } from "~/enums";

import { DescriptionText } from "../Text";

import { useGetOrganisationDetailsQuery } from "./query";

const Sidebar = () => {
  const { t, i18n } = useTranslation();
  const { collapse, setSidebarInfo } = useSidebarContext();
  const { logout } = useAuthContext();
  const { isCreator, isBroadcaster } = useRole();
  const breakPoint = Grid.useBreakpoint();

  const { data, isLoading, isError, refetch } =
    useGetOrganisationDetailsQuery();

  useEffect(() => {
    const { md, lg } = breakPoint;
    if (!md) {
      setSidebarInfo({ collapse: true });
    } else if (lg) {
      setSidebarInfo({ collapse: false });
    }
  }, [breakPoint, setSidebarInfo]);

  const toggleSidebar = useCallback(() => {
    setSidebarInfo({ collapse: !collapse });
  }, [collapse, setSidebarInfo]);

  const dynamicWidth = classNames({
    "w-[80px]": collapse,
    "w-[256px]": !collapse,
  });
  const dynamicLogo = classNames({
    "justify-center": collapse,
    "": !collapse,
  });

  return (
    <div
      className={`hidden sm:flex flex-col bg-neutral-800 border-r-green-600 transition-all shadow-lg ${dynamicWidth}`}
    >
      {/*----- Header -----*/}
      <div className="h-[64px] relative shadow-sm z-10">
        <Link to={`${Path.home}`}>
          <div
            className={`h-full flex items-center px-4 space-x-4 ${dynamicLogo}`}
          >
            <img
              className="w-16 h-8"
              alt="tradveller-logo"
              src="/TradvellerLogo.png"
            />

            {!collapse ? (
              <h1 className="mb-0 text-lg tracking-wide">TheTradveller</h1>
            ) : null}
          </div>
        </Link>
      </div>

      {/*----- Content -----*/}
      <div className="flex-1 py-2 overflow-y-auto overflow-x-hidden">
        <div className="px-4 my-1">
          <DescriptionText className="text-[0.7rem]">
            {!collapse && t("menu").toUpperCase()}
          </DescriptionText>
        </div>
        <SidebarItem
          path={Path.home}
          title={t("home")}
          icon={<IoHomeOutline className="text-xl" />}
        />
        <SidebarItem
          path={`${Path.browse}/all`}
          title={t("browse")}
          icon={<IoTvOutline className="text-xl" />}
        />

        <SidebarItem
          path={Path.aboutus}
          title={t("aboutus")}
          icon={<IoChatboxEllipsesOutline className="text-xl" />}
        />

        <SidebarItem
          path={`${Path.subscribe}/${Path.package}`}
          title={t("membership")}
          icon={<IoBookmarkOutline className="text-xl" />}
        />

        <SidebarItem
          path={Path.contactus}
          title={t("contactus")}
          icon={<IoSendOutline className="text-xl" />}
        />

        {(isCreator || isBroadcaster) && (
          <>
            <div className="px-4 mt-7 mb-1">
              <DescriptionText className="text-[0.7rem]">
                {!collapse && t("creatorstudio").toUpperCase()}
              </DescriptionText>
            </div>
          </>
        )}

        {isCreator && (
          <SidebarItem
            path={Path.content}
            title={t("content")}
            icon={<IoPlayCircleOutline className="text-xl" />}
          />
        )}

        {/* {isBroadcaster && (
          <SidebarItem
            path={Path.createlivestream}
            title="Live Stream"
            icon={<IoBodyOutline className="text-2xl" />}
          />
        )} */}
      </div>

      {/*----- Footer -----*/}

      <div className="px-4 space-y-3 my-2">
        <div
          className={
            collapse
              ? "flex flex-col space-y-2 items-center"
              : "flex space-x-3 mb-3"
          }
        >
          {data?.social_media.map((social: any) => (
            <div
              className="cursor-pointer"
              onClick={() => {
                window.open(social.social_media_link, "_blank");
              }}
            >
              <img
                src={images.socialLogo[social.social_media as keyof any]}
                width={28}
              />
            </div>
          ))}
        </div>
        <div className={collapse ? "hidden" : "text-neutral-400 text-xs"}>
          <Link
            className="text-neutral-400"
            to={`/${Path.info}/${Path.privacypolicy}`}
          >
            {t("privacypolicy")}
          </Link>{" "}
          |{" "}
          <Link
            className="text-neutral-400"
            to={`/${Path.info}/${Path.termofuse}`}
          >
            {t("termsofuse")}
          </Link>
        </div>
        <div className={collapse ? "hidden" : "text-neutral-500 text-xs"}>
          © 2022 Balaena SoftTech Sdn Bhd
        </div>
      </div>

      <button onClick={toggleSidebar} className="py-2 px-3">
        {collapse ? (
          <IoArrowForwardOutline className="text-2xl" />
        ) : (
          <IoArrowBackOutline className="text-2xl" />
        )}
      </button>
    </div>
  );
};

type SidebarItemProps = {
  title: string;
  icon: JSX.Element;
  path?: string;
} & HTMLAttributes<unknown>;

const SidebarItem = ({
  title,
  icon,
  path,
  onClick,
  tabIndex,
}: SidebarItemProps) => {
  const { pathname } = useLocation();
  // QUESTION:: Is "-" appropriate?
  const { pathname: resolvePathname } = useResolvedPath(path || "-");
  const navigate = useNavigate();
  const { collapse } = useSidebarContext();

  const dynamicWidth = classNames({ "justify-center": collapse });
  const activeClass = pathname.startsWith(resolvePathname)
    ? "bg-gradient-to-r from-green-600 text-neutral-50"
    : "text-neutral-500";

  const innerOnClick = useCallback(
    (e) => {
      if (onClick) onClick(e);
      if (path) navigate(path);
    },
    [path, onClick]
  );

  return (
    <div className="group">
      <button
        className={`flex w-full items-center h-12 px-5 py-2 space-x-4 cursor-pointer hover:text-neutral-50 ${dynamicWidth} ${activeClass}`}
        onClick={innerOnClick}
        tabIndex={tabIndex}
      >
        <Tooltip title={title} placement="right">
          {icon}
        </Tooltip>
        {!collapse ? <p className="m-0 text-base">{title}</p> : null}
      </button>
    </div>
  );
};

export default Sidebar;
