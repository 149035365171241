import { useEffect, useLayoutEffect, useState } from "react";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import {
  IoArrowBackOutline,
  IoArrowForwardOutline,
  IoHomeOutline,
  IoKeyOutline,
  IoLogoDiscord,
  IoLogoGoogle,
} from "react-icons/io5";

import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from "~/components/Button";
import { guestAxios, url } from "~/utils/axios";
import { useQueryString } from "~/utils/hooks";

import { useOAuthTokenMutation, useOAuthUrlQuery } from "./query";

declare global {
  interface Window {
    google: any;
  }
}

type OAuthProps = {
  onTokenSuccess: (data: any) => void;
  getReferral?: () => string;
};

const GoogleOAuth = ({ onTokenSuccess }: OAuthProps) => {
  const { t, i18n } = useTranslation();
  const {
    data,
    isLoading: isLoadingGoogleOAuthUrl,
    isError: isErrorGoogleOAuthUrl,
    refetch: refetchGoogleOAuthUrl,
  } = useOAuthUrlQuery("google-oauth2", url.google_oauth_redirect, {
    onSuccess: (data) => {
      // navigate(`/signup/verification`);
      // window.open(data.authorization_url, "_blank");

      // const auth = window.gapi.auth2.getAuthInstance();
      // auth.signIn().then(
      //   (res: any) => {
      //     console.log("Success");
      //   },
      //   (err: any) => {
      //     console.log("Failed");
      //   }
      // );

      // window.gapi.load("auth2", () => {
      //   window.gapi.auth2
      //     .init({
      //       client_id: data.client_id,
      //       scope: data.scope.join(" "),
      //       redirect_uri: url.oauth_redirect,
      //       response_type: "code",
      //       include_granted_scopes: true,
      //       plugin_name: "GeneralOAuthFlow",
      //     })
      //     .then(() => {
      //       const auth = window.gapi.auth2.getAuthInstance();
      //       auth.signIn().then(
      //         (res: any) => {
      //           console.log(res);
      //         },
      //         (err: any) => {
      //           console.log("Failed");
      //         }
      //       );
      //     })
      //     .catch((error: any) => console.log(error));
      // });

      // window.google.accounts.id.initialize({
      //   client_id: data.client_id,
      //   ux_mode: "popup",
      //   // scope: data.scope.join(" "),
      //   // redirect_uri: url.oauth_redirect,
      //   // response_type: "code",
      //   // include_granted_scopes: true,
      //   callback: (res: any) => {
      //     console.log(res);
      //   },
      // });
      // window.google.accounts.id.prompt();

      const client = window.google.accounts.oauth2.initCodeClient({
        client_id: data.client_id,
        scope: data.scope.join(" "),
        redirect_uri: "",
        ux_mode: "popup",
        callback: (response: any) => {
          if (response.error) {
            message.error(response.error_description);
            return;
          }

          mutate({
            formData: {
              code: response.code,
              redirect_uri: url.google_oauth_redirect,
              provider: "google-oauth2",
            },
          });
        },
      });
      client.requestCode();
    },
    onError: (error: any) => {
      message.error("Failed to get OAuth URL");
    },
  });

  const { mutate, isLoading } = useOAuthTokenMutation({
    onSuccess: (data) => {
      onTokenSuccess(data);
    },
    onError: (error: any) => {
      message.error("Failed to get OAuth Token");
    },
  });

  const onGoogleLogin = () => {
    refetchGoogleOAuthUrl();
  };

  return (
    <SecondaryButton
      className="!bg-[#EA4335] !text-white"
      onClick={onGoogleLogin}
    >
      <IoLogoGoogle className="text-lg" />
      {t("continuewithgoogle")}
    </SecondaryButton>
  );
};

const DiscordOAuth = ({ onTokenSuccess, getReferral }: OAuthProps) => {
  const { t, i18n } = useTranslation();
  const [externalPopup, setExternalPopup] = useState<any>(null);
  const [oAuthUrl, setOAuthUrl] = useState(null);
  const {
    data: discordOAuthUrlData,
    isLoading: isLoadingDiscordOAuthUrl,
    isError: isErrorDiscordOAuthUrl,
    refetch: refetchDiscordOAuthUrl,
  } = useOAuthUrlQuery("discord", url.discord_oauth_redirect, {
    onSuccess: (data) => {
      setOAuthUrl(data.authorization_url);
    },
    onError: (error: any) => {
      message.error("Failed to get OAuth URL");
    },
  });

  const { mutate, isLoading } = useOAuthTokenMutation({
    onSuccess: (data) => {
      onTokenSuccess(data);
    },
    onError: (error: any) => {
      message.error(
        error.response?.data?.ERROR_MSG ?? "Failed to get OAuth Token"
      );
    },
  });

  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(() => {
      if (!externalPopup || externalPopup.closed) {
        timer && clearInterval(timer);
        return;
      }
      const currentUrl = externalPopup.location.href;
      if (!currentUrl) {
        return;
      }
      const searchParams = new URL(currentUrl).searchParams;
      const code = searchParams.get("code");
      if (code) {
        externalPopup.close();
        setExternalPopup(null);
        timer && clearInterval(timer);

        mutate({
          formData: {
            code: code,
            redirect_uri: url.discord_oauth_redirect,
            provider: "discord",
            referral_code: getReferral?.(),
          },
        });
      }
    }, 500);
  }, [externalPopup]);

  const onDiscordLogin = () => {
    const width = 550;
    const height = 700;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const title = `Discord`;
    const url = oAuthUrl || "";
    const popup = window.open(
      url,
      title,
      `width=${width},height=${height},left=${left},top=${top}`
    );
    setExternalPopup(popup);
  };
  return (
    <SecondaryButton
      className="!bg-[#7087D3] !text-white"
      onClick={onDiscordLogin}
      disabled={!oAuthUrl}
      loading={isLoadingDiscordOAuthUrl}
    >
      <IoLogoDiscord className="text-lg" />
      {t("continuewithdiscord")}
    </SecondaryButton>
  );
};

export { GoogleOAuth, DiscordOAuth };
