import { Breadcrumb, Button, Drawer, Grid, Input, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import {
  IoArrowBackOutline,
  IoArrowForwardOutline,
  IoBodyOutline,
  IoHomeOutline,
  IoKeyOutline,
  IoLogOutOutline,
  IoMenuOutline,
  IoPeopleCircle,
  IoSearch,
} from "react-icons/io5";

import { useSidebarContext } from "~/contexts/sidebar";

import styles from "./styles.module.less";

type SearchBarProps = {
  value?: string;
  onSearch?: any;
};

export const SearchBar = ({ value, onSearch }: SearchBarProps) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="flex rounded-full items-center bg-neutral-800 py-1 pl-2 pr-4 flex-1 max-w-md">
      {/* <Input
        placeholder="Search videos"
        bordered={false}
        value={value}
        onPressEnter={onSearch}
        allowClear
      />
      <Tooltip title="search">
        <Button
          shape="circle"
          icon={<IoSearch />}
          className={styles.searchButton}
          onClick={onSearch}
        />
      </Tooltip> */}
      <Input.Search
        className={styles.searchInput}
        placeholder={t("searchvideos")}
        bordered={false}
        allowClear
        onSearch={onSearch}
      />
    </div>
  );
};
