import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "react-query";

import { PackageInfoT, VideoInfoT } from "~/types";
import { guestAxios, url } from "~/utils/axios";

type GetPostResponse = {
  results: VideoInfoT[];
};

const getPostQueryFn = (context: any) => {
  const [_, publish_status] = context.queryKey;

  return guestAxios
    .get<GetPostResponse>(url.get_posts, {
      params: { publish_status },
    })
    .then((response) => response.data.results);
};

export const useGetPostQuery = (
  publish_status?: string,
  options?: UseQueryOptions<
    VideoInfoT[],
    Error | AxiosError<{ detail: string }>
  >
) => {
  return useQuery<VideoInfoT[], Error | AxiosError<{ detail: string }>>(
    ["post", publish_status],
    getPostQueryFn,
    options
  );
};

const liveSchedulesQueryFn = () => {
  return guestAxios
    .get<GetPostResponse>(url.live_schedules)
    .then((response) => response.data.results);
};

export const useLiveSchedulesQuery = (
  publish_status?: string,
  options?: UseQueryOptions<
    VideoInfoT[],
    Error | AxiosError<{ detail: string }>
  >
) => {
  return useQuery<VideoInfoT[], Error | AxiosError<{ detail: string }>>(
    ["post", publish_status],
    liveSchedulesQueryFn,
    options
  );
};

const getPackagesQueryFn = () => {
  return guestAxios
    .get<PackageInfoT[]>(url.get_packages, {
      params: { page_size: 0 },
    })
    .then((response) => {
      // return Array(5).fill(response.data[0]);
      return response.data;
    });
};

export const useGetPackagesQuery = (
  options?: UseQueryOptions<
    PackageInfoT[],
    Error | AxiosError<{ detail: string }>
  >
) => {
  return useQuery<PackageInfoT[], Error | AxiosError<{ detail: string }>>(
    "packages",
    getPackagesQueryFn,
    options
  );
};
