import { createContext, MutableRefObject, useContext, useRef } from "react";

const InitialAppMountContext = createContext<MutableRefObject<boolean>>(
  {} as MutableRefObject<boolean>
);

type InitialAppMountProviderProps = {
  children: React.ReactNode;
};

// NOTE:: We need to ensure this provider is very straight forward and "won't crash"
const InitialAppMountProvider = ({
  children,
}: InitialAppMountProviderProps) => {
  const isInitialMount = useRef(false);

  return (
    <InitialAppMountContext.Provider value={isInitialMount}>
      {children}
    </InitialAppMountContext.Provider>
  );
};

const useInitialAppMount = () => useContext(InitialAppMountContext);

export { InitialAppMountProvider, useInitialAppMount };
