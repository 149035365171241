import { Result } from "antd";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { PrimaryButton } from "~/components/Button";
import { Path } from "~/enums";

const Unauthorized = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="h-full flex flex-col justify-center items-center">
      <div className="flex items-center space-x-4">
        <Result
          status="403"
          title="403"
          subTitle={t("403.message")}
          extra={
            <PrimaryButton
              onClick={() => {
                navigate(`/${Path.home}`);
              }}
            >
              {t("backhome")}
            </PrimaryButton>
          }
        />
      </div>
    </div>
  );
};

export default Unauthorized;
