import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Empty,
  Form,
  Input,
  message,
  Modal,
  Result,
  Row,
  Skeleton,
  Space,
  Spin,
} from "antd";
import { useTranslation } from "react-i18next";
import { AiFillLike, AiOutlineLike } from "react-icons/ai";
import { IoAdd, IoFilter } from "react-icons/io5";
import { InView } from "react-intersection-observer";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { sprintf } from "sprintf-js";

import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";

import {
  LiveChatButton,
  LiveChatContainer,
  LiveChatText,
} from "~/components/Chat";
import { Comment, CommentContainer } from "~/components/Comment";
import { PaddedContainer } from "~/components/Container";
import { VideoPostSkeleton } from "~/components/Skeleton";
import { VideoSectionTitle } from "~/components/Text";
import { VideoInfoHorizontal, VideoPlayerInfo } from "~/components/VideoInfo";
import { VideoPlayer } from "~/components/VideoPlayer";
import { useAuthContext } from "~/contexts/auth";
import { Path } from "~/enums";
import { url } from "~/utils/axios";

import {
  useCreatePostCommentMutation,
  useGetChatMessageQuery,
  useGetPostCommentsInfiniteQuery,
  useGetPostDetailsQuery,
  useGetPostInfiniteQuery,
  useGetPostStreamQuery,
  useReactPostMutation,
  useUserProfileQuery,
} from "./query";
import styles from "./styles.module.less";

const VideoPage = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [relatedPost, setRelatedPost] = useState([]);
  const [postComments, setPostComments] = useState([]);
  const [toggleComment, setToggleComment] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [createPostCommentForm] = Form.useForm();
  const { isAuthenticated, isVerifyingToken, setAuthInfo, logout } =
    useAuthContext();
  const [wsInstance, setWsInstance] = useState<any>(null);
  const [viewerCount, setViewerCount] = useState<any>(0);
  const [liveChatMessage, setLiveChatMessage] = useState<any>(null);
  const [chatMessageList, setChatMessageList] = useState<any>([]);

  const {
    data: postDetails,
    isLoading: isLoadingPostDetails,
    isError: isErrorPostDetails,
    refetch: refetchPostDetails,
  } = useGetPostDetailsQuery(id!);

  const {
    data: chatMessages,
    isLoading: isLoadingChatMessages,
    isError: isErrorChatMessages,
    refetch: refetchChatMessages,
  } = useGetChatMessageQuery(postDetails?.chatroom_id!);

  useEffect(() => {
    document.querySelector("#page-content")?.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (chatMessages?.length > 0) {
      setChatMessageList(chatMessages);
    } else {
      setChatMessageList([]);
    }
  }, [chatMessages]);

  useEffect(() => {
    if (postDetails) {
      console.log("post details", postDetails);
      initialiseWebSocket();
    }
  }, [postDetails]);

  const initialiseWebSocket = () => {
    let webSocketUrl = sprintf(
      url.livechat_websocket,
      postDetails?.chatroom_id
    );

    const ws = new WebSocket(webSocketUrl);
    setWsInstance(ws as any);
  };

  const closeWebsocketPermanently = () => {
    if (wsInstance) {
      //replace onclose call back before closing
      wsInstance.onclose = () => {
        console.log("closed permanently", wsInstance.url);
      };
      wsInstance?.close();
    }
  };

  const connectLiveChatWebsocket = useCallback(() => {
    if (postDetails?.chatroom_id && wsInstance) {
      wsInstance.onopen = () => {
        // connection opened
        console.log("wsInstance connection open");
      };

      wsInstance.onmessage = (e: any) => {
        // a message was received
        console.log("wsInstance connection on message", e);
        let data = JSON.parse(e.data);
        if (data?.type === "viewer.count") {
          setViewerCount(data?.viewers);
        }
        if (data?.type === "live.chat") {
          let liveChatResponse = {
            chatroom: null,
            content: data?.content,
            created_at: data?.timestamp,
            id: data?.id,
            is_reply: null,
            reply_to: null,
            sender: {
              email: null,
              fullname: data?.sender_name,
              id: data?.sender,
              member_id: null,
              photo: {
                id: null,
                file: data?.photo_url
                  ? process.env.REACT_APP_BACKEND_DOMAIN + data?.photo_url
                  : null,
                name: null,
              },
              role: null,
            },
          };
          setChatMessageList((arr: any) => [liveChatResponse, ...arr]);
        }
      };
      wsInstance.onerror = (event: any) => {
        // an error occurred
        console.log("wsInstance connection on error", event);
        let reason;
        if (event.code === 1000)
          reason =
            "Normal closure, meaning that the purpose for which the connection was established has been fulfilled.";
        else if (event.code === 1001)
          reason =
            'An endpoint is "going away", such as a server going down or a browser having navigated away from a page.';
        else if (event.code === 1002)
          reason =
            "An endpoint is terminating the connection due to a protocol error";
        else if (event.code === 1003)
          reason =
            "An endpoint is terminating the connection because it has received a type of data it cannot accept (e.g., an endpoint that understands only text data MAY send this if it receives a binary message).";
        else if (event.code === 1004)
          reason =
            "Reserved. The specific meaning might be defined in the future.";
        else if (event.code === 1005)
          reason = "No status code was actually present.";
        else if (event.code === 1006)
          reason =
            "The connection was closed abnormally, e.g., without sending or receiving a Close control frame";
        else if (event.code === 1007)
          reason =
            "An endpoint is terminating the connection because it has received data within a message that was not consistent with the type of the message (e.g., non-UTF-8 [https://www.rfc-editor.org/rfc/rfc3629] data within a text message).";
        else if (event.code === 1008)
          reason =
            'An endpoint is terminating the connection because it has received a message that "violates its policy". This reason is given either if there is no other sutible reason, or if there is a need to hide specific details about the policy.';
        else if (event.code === 1009)
          reason =
            "An endpoint is terminating the connection because it has received a message that is too big for it to process.";
        else if (event.code === 1010)
          // Note that this status code is not used by the server, because it can fail the WebSocket handshake instead.
          reason =
            "An endpoint (client) is terminating the connection because it has expected the server to negotiate one or more extension, but the server didn't return them in the response message of the WebSocket handshake. <br /> Specifically, the extensions that are needed are: " +
            event.reason;
        else if (event.code === 1011)
          reason =
            "A server is terminating the connection because it encountered an unexpected condition that prevented it from fulfilling the request.";
        else if (event.code === 1015)
          reason =
            "The connection was closed due to a failure to perform a TLS handshake (e.g., the server certificate can't be verified).";
        else reason = "Unknown reason";
        console.warn(reason);
      };
      wsInstance.onclose = (e: any) => {
        // connection closed
        console.log("wsInstance connection on close", e);
        // webSocketEventRef.current = null;
        if (wsInstance != null) {
          // console.log('reconnect every 5s');
          setTimeout(() => {
            initialiseWebSocket();
          }, 5000);
        }
      };
    }
  }, [wsInstance]);

  useEffect(() => {
    if (wsInstance && wsInstance.readyState != WebSocket.OPEN) {
      connectLiveChatWebsocket();
    }

    return () => {
      closeWebsocketPermanently();
    };
  }, [wsInstance]);

  const {
    data: postStream,
    isLoading: isLoadingPostStream,
    isError: isErrorPostStream,
    refetch: refetchPostStream,
  } = useGetPostStreamQuery(id!, {
    enabled:
      postDetails?.is_live === false ||
      (postDetails?.is_live === true && postDetails?.is_live_ended === true),
  });

  const {
    isLoading: isLoadingPostInfinite,
    isError: isErrorPostInfinite,
    error: errorPostInfinite,
    data: postInfinite,
    fetchNextPage: fetchNextPagePostInfinite,
    isFetching: isFetchingPostInfinite,
    hasNextPage: hasNextPagePostInfinite,
    isFetchingNextPage: isFetchingNextPagePostInfinite,
  } = useGetPostInfiniteQuery(id!);

  const {
    isLoading: isLoadingPostCommentsInfinite,
    isError: isErrorPostCommentsInfinite,
    error: errorPostCommentsInfinite,
    data: postCommentsInfinite,
    fetchNextPage: fetchNextPagePostCommentsInfinite,
    isFetching: isFetchingPostCommentsInfinite,
    hasNextPage: hasNextPagePostCommentsInfinite,
    isFetchingNextPage: isFetchingNextPagePostCommentsInfinite,
  } = useGetPostCommentsInfiniteQuery(id!);

  const { isLoading: isLoadingCreatePostComment, mutate: createPostComment } =
    useCreatePostCommentMutation({
      onSuccess: (data) => {
        createPostCommentForm.resetFields();
      },
      onError: (error: any) => {
        message.error(error.response?.data);
      },
      onSettled: () => {
        queryClient.invalidateQueries(["useGetPostCommentsInfiniteQuery", id]);
      },
    });

  const { isLoading: isLoadingReactPost, mutate: reactPost } =
    useReactPostMutation({
      onSuccess: (data) => {},
      onError: (error: any) => {
        message.error(error.response?.data);
      },
      onSettled: () => {
        queryClient.invalidateQueries(["useGetPostDetailsQuery", id]);
      },
    });

  const {
    data: profileData,
    isLoading: isProfileLoading,
    isError: isProfileError,
  } = useUserProfileQuery();

  const postInfinitePages = (postInfinite as any) || {
    pages: [],
  };

  const postCommentsPages = (postCommentsInfinite as any) || {
    pages: [],
  };

  useEffect(() => {
    if (postInfinitePages?.pages?.length > 0) {
      let allPages = postInfinitePages?.pages.map((page: any) => {
        return page.results;
      });

      let flattenedPages = [].concat.apply([], allPages);

      setRelatedPost(flattenedPages);
    }
  }, [postInfinitePages?.pages]);

  useEffect(() => {
    if (postCommentsPages?.pages?.length > 0) {
      let allPages = postCommentsPages?.pages.map((page: any) => {
        return page.results;
      });

      let flattenedPages = [].concat.apply([], allPages);

      setPostComments(flattenedPages);
    }
  }, [postCommentsPages?.pages]);

  const onCreatePostCommentFormFinish = (values: any) => {
    const formData = new FormData();
    formData.append("content", values?.content ?? "");
    createPostComment({
      id: id!,
      formData: formData,
    });
  };

  const promptLogin = () => {
    Modal.confirm({
      centered: true,
      title: "Please Login",
      okText: "Proceed to Login",
      content:
        "You are required to be logged in to leave comments and/or like a post.",
      onOk() {
        sessionStorage.setItem("to", location.pathname);
        navigate(`/${Path.connect}`);
      },
      onCancel() {},
    });
  };

  if (isErrorPostDetails) {
    return (
      <Result
        status="warning"
        icon={<SearchOutlined />}
        title={t("video.notfound")}
        extra={
          <Button
            type="primary"
            onClick={() => navigate(`/${Path.browse}/all`)}
          >
            {t("backhome")}
          </Button>
        }
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      />
    );
  }

  return (
    <PaddedContainer className="pb-10 max-w-screen-2xl mx-auto">
      <Row gutter={[30, 30]}>
        <Col xs={24} lg={24} xl={15}>
          {/* Live now */}
          {!isLoadingPostDetails &&
            postDetails?.has_watch_perm &&
            postDetails?.is_live &&
            postDetails?.is_live_now &&
            postDetails?.live_stream_url && (
              <VideoPlayer
                video_url={postDetails?.live_stream_url}
                user_email={profileData?.email ?? ""}
                thumbnail_url={postDetails?.thumbnail?.file}
                is_autoplay={postDetails?.is_autoplay}
              />
            )}
          {/* Live starting soon */}
          {!isLoadingPostDetails &&
            postDetails?.has_watch_perm &&
            postDetails?.is_live &&
            !postDetails?.is_live_now &&
            !postDetails?.is_live_ended && (
              <div
                style={{
                  height: 450.56,
                  width: "100%",
                  background: "#181818",
                  borderRadius: 20,
                  padding: 20,
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Result
                  icon={<LoadingOutlined />}
                  title="Live stream starting soon"
                />
              </div>
            )}
          {/* Normal video or Ended live video */}
          {!isLoadingPostDetails &&
            postDetails?.has_watch_perm &&
            postStream?.access_url && (
              <VideoPlayer
                video_url={postStream?.access_url}
                user_email={profileData?.email ?? ""}
                thumbnail_url={postDetails?.thumbnail?.file}
                is_autoplay={postDetails?.is_autoplay}
              />
            )}
          {/* Live video under processing */}
          {!isLoadingPostDetails &&
            postDetails?.has_watch_perm &&
            postDetails?.is_live &&
            postDetails?.is_live_ended &&
            !postStream?.access_url && (
              <div
                style={{
                  height: 450.56,
                  width: "100%",
                  background: "#181818",
                  borderRadius: 20,
                  padding: 20,
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Result
                  icon={<LoadingOutlined />}
                  title="Video unavailable"
                  subTitle="This video is under processing."
                />
              </div>
            )}
          {/* Normal video under processing */}
          {!isLoadingPostDetails &&
            postDetails?.has_watch_perm &&
            !postDetails?.is_live &&
            !postStream?.access_url && (
              <div
                style={{
                  height: 450.56,
                  width: "100%",
                  background: "#181818",
                  borderRadius: 20,
                  padding: 20,
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Result
                  icon={<LoadingOutlined />}
                  title="Video unavailable"
                  subTitle="This video is under processing."
                />
              </div>
            )}
          {/* No permission */}
          {!isLoadingPostDetails && !postDetails?.has_watch_perm && (
            <div
              style={{
                height: 450.56,
                width: "100%",
                background: "#181818",
                borderRadius: 20,
                padding: 20,
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Result
                status="warning"
                title="Video unavailable"
                subTitle="This video is restricted. Please subscribe to view this video."
                extra={
                  <Button
                    type="primary"
                    onClick={() => {
                      navigate(`/${Path.subscribe}/${Path.package}`);
                    }}
                  >
                    Subscribe Now
                  </Button>
                }
              />
            </div>
          )}
          {/* Loading */}
          {isLoadingPostDetails && (
            <div
              style={{
                height: 450.56,
                width: "100%",
                background: "#181818",
                borderRadius: 20,
                padding: 20,
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                marginBottom: 20,
              }}
            />
          )}
          {postDetails && !isLoadingPostDetails ? (
            <VideoPlayerInfo
              video={postDetails}
              actions={
                <Button
                  type="text"
                  icon={
                    postDetails?.is_liked ? (
                      <AiFillLike className={styles.primaryColor} />
                    ) : (
                      <AiOutlineLike />
                    )
                  }
                  onClick={() =>
                    isAuthenticated ? reactPost({ id: id! }) : promptLogin()
                  }
                >
                  <span
                    className={postDetails?.is_liked ? styles.primaryColor : ""}
                  >
                    {t("like")}
                  </span>
                </Button>
              }
              liveViewCount={viewerCount}
            />
          ) : (
            <Skeleton active />
          )}
          {((postDetails?.is_live && postDetails?.is_live_ended) ||
            !postDetails?.is_live) && (
            <div className="mt-10">
              {!isLoadingPostCommentsInfinite && (
                <Row justify="space-between">
                  <Col>
                    <Space>
                      <div className="text-[18px] font-medium">
                        {`${postCommentsPages?.pages[0]?.count ?? 0} ${
                          postCommentsPages?.pages[0]?.count !== 1
                            ? t("video.comments")
                            : t("video.comment")
                        }`}
                      </div>
                      <Button
                        type="link"
                        icon={<IoAdd />}
                        onClick={() =>
                          isAuthenticated
                            ? setToggleComment(!toggleComment)
                            : promptLogin()
                        }
                      >
                        {t("video.addcomment")}
                      </Button>
                    </Space>
                  </Col>
                  {/* <Col>
                  <Button type="text" icon={<IoFilter />}>
                    Sort By
                  </Button>
                </Col> */}
                </Row>
              )}

              <CommentContainer>
                {toggleComment && (
                  <Form
                    onFinish={onCreatePostCommentFormFinish}
                    form={createPostCommentForm}
                    name="createpostcomment_form"
                    layout="vertical"
                    size="middle"
                    requiredMark={false}
                  >
                    <div className="mt-5">
                      <Form.Item shouldUpdate name="content">
                        <Input.TextArea
                          placeholder="Add a comment..."
                          autoSize={{ minRows: 4, maxRows: 6 }}
                          className="!bg-neutral-800 !border-neutral-700 !text-sm"
                        />
                      </Form.Item>

                      <Row justify="end" className="mt-5 space-x-2">
                        <Form.Item>
                          <Button
                            type="text"
                            onClick={() => setToggleComment(!toggleComment)}
                          >
                            {t("cancel")}
                          </Button>
                        </Form.Item>

                        <Form.Item shouldUpdate>
                          {() => (
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={isLoadingCreatePostComment}
                              disabled={
                                !createPostCommentForm.isFieldsTouched() ||
                                isLoadingCreatePostComment
                              }
                            >
                              {t("video.comment.action")}
                            </Button>
                          )}
                        </Form.Item>
                      </Row>
                    </div>
                  </Form>
                )}
                {isLoadingPostCommentsInfinite &&
                  Array.from({ length: 3 }, (element, index) => {
                    return (
                      <Skeleton
                        className="mt-3"
                        active
                        key={index}
                        avatar
                        paragraph={{ rows: 2 }}
                      />
                    );
                  })}
                {!isLoadingPostCommentsInfinite &&
                  postComments?.map((data: any) => {
                    return (
                      <Comment
                        className="my-8"
                        key={data?.id}
                        date={data?.created_at}
                        name={data?.user?.fullname}
                        commentText={data?.content}
                        imgUrl={data?.user?.photo?.file}
                      />
                    );
                  })}
                <InView
                  as="div"
                  onChange={(inView, entry) => {
                    if (inView && hasNextPagePostCommentsInfinite) {
                      fetchNextPagePostCommentsInfinite();
                    }
                  }}
                >
                  <Row justify="center">
                    {isFetchingNextPagePostCommentsInfinite && (
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 24 }} spin />
                        }
                      />
                    )}
                  </Row>
                </InView>
              </CommentContainer>
            </div>
          )}
        </Col>
        <Col xs={24} lg={24} xl={9}>
          {postDetails?.is_live && (
            <div className="mb-5">
              <VideoSectionTitle className="mb-2">
                {t("video.livechat")}
              </VideoSectionTitle>
              <LiveChatContainer className="!bg-neutral-800 !border-neutral-700 p-4 rounded-md h-[500px]">
                {postDetails?.has_watch_perm &&
                  chatMessageList?.length > 0 &&
                  chatMessageList?.map((item: any, index: any) => {
                    return (
                      <LiveChatText
                        key={item.id}
                        name={item.sender.fullname}
                        chatContent={item.content}
                        className={index !== 0 ? "mb-4" : ""}
                        url={item.sender.photo.file}
                        createdAt={item.created_at}
                      />
                    );
                  })}
                {postDetails?.has_watch_perm && chatMessageList?.length === 0 && (
                  <div className="flex h-full justify-center items-center">
                    <Empty description="No Chat Found" className="p-7" />
                  </div>
                )}
                {!isLoadingPostDetails && !postDetails?.has_watch_perm && (
                  <div className="flex h-full justify-center items-center">
                    <Result
                      status="warning"
                      title="Chat unavailable"
                      subTitle="This chat is restricted."
                    />
                  </div>
                )}
              </LiveChatContainer>
              <div className="pt-4 pb-1 px-3">
                {postDetails?.has_watch_perm && !postDetails?.is_live_ended && (
                  <LiveChatButton
                    url={profileData?.photo?.file}
                    value={liveChatMessage}
                    onChange={(e: any) => {
                      setLiveChatMessage(e.target.value);
                    }}
                    onPressEnter={(e: any) => {
                      e.preventDefault();
                      if (liveChatMessage.match(/^ *$/) === null) {
                        wsInstance.send(
                          JSON.stringify({
                            sender: profileData?.id,
                            content: liveChatMessage,
                          })
                        );
                      }
                      setLiveChatMessage(null);
                    }}
                  />
                )}
              </div>
            </div>
          )}

          <VideoSectionTitle>
            {t("video.youmayalsolike").toUpperCase()}
          </VideoSectionTitle>
          <Row gutter={[0, 20]} className="mt-2">
            {isLoadingPostInfinite &&
              Array.from({ length: 8 }, (element, index) => {
                return <VideoPostSkeleton key={index} />;
              })}
            {!isLoadingPostInfinite &&
              relatedPost?.map((data: any) => {
                return (
                  <Col
                    span={24}
                    key={data.post_id}
                    onClick={() => {
                      navigate(`/video/${data.post_id}`);
                    }}
                  >
                    <VideoInfoHorizontal video={data} />
                  </Col>
                );
              })}
          </Row>
          <InView
            as="div"
            onChange={(inView, entry) => {
              if (inView && hasNextPagePostInfinite) {
                fetchNextPagePostInfinite();
              }
            }}
          >
            <Row justify="center">
              {isFetchingNextPagePostInfinite && (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              )}
            </Row>
          </InView>
        </Col>
      </Row>
    </PaddedContainer>
  );
};

export default VideoPage;
