import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState,
} from "react";

interface RoleContextProps extends RoleInfo {
  setRoleInfo: Dispatch<SetStateAction<RoleInfo>>;
}

type RoleInfo = {
  isCreator: boolean;
  isBroadcaster?: boolean;
};

const RoleContext = createContext<RoleContextProps>({} as RoleContextProps);

const RoleProvider: FC = ({ children }) => {
  const [roleInfo, setRoleInfo] = useState<RoleInfo>({
    isCreator: false,
    isBroadcaster: false,
  });

  return (
    <RoleContext.Provider value={{ ...roleInfo, setRoleInfo }}>
      {children}
    </RoleContext.Provider>
  );
};

const useRole = () => useContext(RoleContext);

export { RoleProvider, useRole };
