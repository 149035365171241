import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { useMutation, UseMutationOptions } from "react-query";

import { CountryInfoT, UserProfileT } from "~/types";
import { UserInformationFormField } from "~/types/form";
import { appAxios, guestAxios, url } from "~/utils/axios";

type CreateFileUploadPolicyInfo = { formData: FormData };

const createFileUploadPolicyMutationFn = (
  createFileUploadPolicyInfo: CreateFileUploadPolicyInfo
) => {
  const { formData } = createFileUploadPolicyInfo;

  return appAxios
    .post(url.create_upload_policy, formData)
    .then((response) => response.data);

  //   return appAxios({
  //     method: "POST",
  //     headers: { "Content-Type": "multipart/form-data" },
  //     url: url.create_upload_policy,
  //     data: formData,
  //   }).then((response) => response.data);
};

export const useCreateFileUploadPolicyMutation = (
  options?: UseMutationOptions<any, AxiosError, CreateFileUploadPolicyInfo>
) => {
  return useMutation<any, AxiosError, CreateFileUploadPolicyInfo>(
    createFileUploadPolicyMutationFn,
    options
  );
};

type UploadFileInfo = {
  postId: string;
  uploadURL: any;
  file: any;
  onProgress: (e: any) => void;
};

const uploadFileMutationFn = (uploadFileInfo: UploadFileInfo) => {
  const { postId, uploadURL, file, onProgress } = uploadFileInfo;

  //   return guestAxios.put(uploadURL, formData).then((response) => response.data);

  return guestAxios({
    method: "PUT",
    headers: {
      "Content-Type": file.type,
    },
    url: uploadURL,
    data: file,
    onUploadProgress: (e) => {
      onProgress(e);
    },
  }).then((response) => {
    let data = response.data;
    return { ...data, post_id: postId };
  });
};

export const useUploadFileMutation = (
  options?: UseMutationOptions<any, AxiosError, UploadFileInfo>
) => {
  return useMutation<any, AxiosError, UploadFileInfo>(
    uploadFileMutationFn,
    options
  );
};

type FinalizePostInfo = { id?: string };

const finalizePostMutationFn = (finalizePostInfo: FinalizePostInfo) => {
  const { id } = finalizePostInfo;

  return appAxios
    .post(`${url.finalize_post}/${id}/file-upload/finalize/`)
    .then((response) => response.data);
};

export const useFinalizePostMutation = (
  options?: UseMutationOptions<any, AxiosError, FinalizePostInfo>
) => {
  return useMutation<any, AxiosError, FinalizePostInfo>(
    finalizePostMutationFn,
    options
  );
};

const getAudiencesQueryFn = () => {
  return guestAxios
    .get<any>(url.get_audiences)
    .then((response) => response.data);
};

export const useGetAudiencesQuery = (
  options?: UseQueryOptions<any, Error | AxiosError<{ detail: string }>>
) => {
  return useQuery<any, Error | AxiosError<{ detail: string }>>(
    "audiences",
    getAudiencesQueryFn,
    options
  );
};

const getAdminListQueryFn = () => {
  return appAxios
    .get<any>(url.get_admin, {
      params: { page_size: 0 },
    })
    .then((response) => response.data);
};

export const useGetAdminListQuery = (
  options?: UseQueryOptions<any, Error | AxiosError<{ detail: string }>>
) => {
  return useQuery<any, Error | AxiosError<{ detail: string }>>(
    "admin",
    getAdminListQueryFn,
    options
  );
};

const userQueryFn = () => {
  return appAxios.get(url.profile).then((response) => {
    console.log("upload profile:", response);
    return response.data;
  });
};

export const useUserProfileQuery = (
  options?: UseQueryOptions<UserProfileT>
) => {
  return useQuery<UserProfileT>(
    ["profile"],
    userQueryFn,
    options
      ? {
          ...options,
          // retry: false,
          staleTime: 300000,
          cacheTime: 300000,
        }
      : undefined
  );
};
