import { message, Modal } from "antd";
import axios from "axios";

import { useAuthContext } from "~/contexts/auth";

const protocol = window.location.protocol;
const hostName = window.location.hostname;
export const backendPort = process.env.REACT_APP_BACKEND_PORT;
const backendUserPath = "api/v1";

export const websocketBaseURL = process.env.REACT_APP_WEBSOCKET_BASE_URL;

const urlMap = {
  //   development: `${protocol}//${hostName}:${backendPort}/${backendUserPath}`,
  development: `${process.env.REACT_APP_BACKEND_DOMAIN}/${backendUserPath}`,
  production: `${process.env.REACT_APP_BACKEND_DOMAIN}/${backendUserPath}`,
};

let baseURL =
  process.env.NODE_ENV === "development"
    ? urlMap["development"]
    : urlMap["production"];

const guestAxios = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

guestAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.message === "Network Error") {
      message.error(
        "Please check your internet connection, or check with website technician"
      );
    }

    return Promise.reject(error);
  }
);

const appAxios = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

appAxios.interceptors.request.use((config) => {
  const token =
    localStorage.getItem("access_token") ||
    sessionStorage.getItem("access_token");
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  return config;
});

const adminModulePath = "/admin";
const userModulePath = "/auth";
const orgModulePath = "/organisation";
const postModulePath = "/post";
const configModulePath = "/config";
const membershipModulePath = "/membership";
const paymentModulePath = "/payment";
const chatModulePath = "/chat";

const url = {
  /**
   * NOTE:: If a path without a "/" behind, means we use it to do substitution `${path}/${id}/detail`
   */

  /*----- Auth -----*/
  userModulePath,
  login: `${userModulePath}/login/`,
  sign_up: `${userModulePath}/signup/`,
  verification_mail: `${userModulePath}/verification-mail/request/`,
  verification_otp: `${userModulePath}/phone/otp/request/`,
  verify_phone: `${userModulePath}/phone/otp/verify/`,
  profile: `${userModulePath}/profile/`,
  update_profile: `${userModulePath}/profile/update/`,
  oauth_auth_url: `${userModulePath}/oauth2/auth-url/`,
  oauth_token: `${userModulePath}/oauth2/token/`,
  google_oauth_redirect: `${window.location.origin}`,
  discord_oauth_redirect: `${window.location.origin}/oauth/`,
  change_phone: `${userModulePath}/phone/change/request/`,
  change_password: `${userModulePath}/password/change/`,
  forgot_password: `${userModulePath}/password/forgot/`,
  reset_password: `${userModulePath}/password/reset/`,
  get_referrals: `${userModulePath}/referral/referee/list/`,
  verify_email: `${userModulePath}/verification-mail/verify-and-change/`,
  get_broadcaster_list: `${userModulePath}/broadcaster/list/`,
  get_admin: `${adminModulePath}${userModulePath}/admin/list/`,
  get_publisher_details: `${userModulePath}/publisher`, //append {member_id}/details

  /*----- Organisation -----*/
  contact_us: `${orgModulePath}/contact-us/mail/send/`,
  org_details: `${orgModulePath}/details/`,

  /*----- Post -----*/
  get_posts: `${postModulePath}/list/`,
  live_schedules: `${postModulePath}/live/schedule/list/`,
  create_upload_policy: `${adminModulePath}${postModulePath}/file-upload/policy/create/`,
  post_details: `${postModulePath}`, //append {post_id}/details
  live_stream: `${postModulePath}`, //append {post_id}/stream
  related_post: `${postModulePath}`, //append {post_id}/related
  update_post: `${adminModulePath}${postModulePath}`, //append {post_id}/update
  delete_post: `${adminModulePath}${postModulePath}`, //append {post_id}/delete
  get_my_posts: `${adminModulePath}${postModulePath}/list/self/`,
  finalize_post: `${adminModulePath}${postModulePath}`, //append {post_id}/file-upload/finalize
  get_posts_admin: `${adminModulePath}${postModulePath}/list`,
  get_posts_comments: `${postModulePath}/%s/comment/list/`,
  create_posts_comments: `${postModulePath}/%s/comment/create/`,
  react_post: `${postModulePath}/%s/react/`,
  schedule_live_post: `${postModulePath}/live/schedule/create/`,
  generate_stream_link: `/livestream/generate_stream_link/%s/`,

  /*----- Live Chat -----*/
  livechat_websocket: `${websocketBaseURL}/livechat/ws/%s/`,
  get_chatroom_message: `${chatModulePath}/chatroom/%s/message/list/`,
  livechat_replay: `${chatModulePath}/chatroom/%s/replay/`,

  /*----- Config -----*/
  get_countries: `${configModulePath}/country/list/`,

  /*----- Membership -----*/
  get_packages: `${membershipModulePath}/subscription/package/list/`,
  get_subscription: `${membershipModulePath}/subscription/history/`,
  get_audiences: `${membershipModulePath}/tier/list`,
  subscribe_free: `${membershipModulePath}/subscription/package`, //append {package_id}/subscribe
  get_details: `${membershipModulePath}/subscription/details`,

  /*----- Payment -----*/
  initiate_payment: `${paymentModulePath}/membership/package/price`, //append {price_id}/initiate-payment
  payment_websocket: `${websocketBaseURL}/payment/ws/%s/`,
  cancel_subscription: `${paymentModulePath}/stripe/subscription/cancel/`,
};

export { appAxios, guestAxios, url };
