import { Alert, Button, Checkbox, Col, Form, message, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";
import {
  IoArrowBackOutline,
  IoArrowForwardOutline,
  IoHomeOutline,
  IoKeyOutline,
} from "react-icons/io5";
import { Link } from "react-router-dom";

import { PrimaryButton } from "~/components/Button";
import { Input, TextAreaInput } from "~/components/Input";
import { DescriptionText, SubTitle, Title } from "~/components/Text";
import { ContactUsFormField } from "~/types/form";

import { useSendContactUsMailMutation } from "./query";
import styles from "./styles.module.less";

type ContactUsFormProps = {
  initialValue?: ContactUsFormField;
};

const ContactUsForm = ({ initialValue }: ContactUsFormProps) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    mutate({ formData: values });
  };

  const { mutate, isLoading, isError, error } = useSendContactUsMailMutation({
    onSuccess: () => {
      Modal.success({
        centered: true,
        title: "Thank You",
        content: "Your message has been received.",
        onOk() {
          form.resetFields();
        },
      });
    },
    onError: (error) => {
      message.error("Failed to Send Message. Please try again.");
    },
  });

  return (
    <Form
      onFinish={onFinish}
      form={form}
      name="contactus_form"
      layout="vertical"
      size="middle"
      requiredMark={false}
      initialValues={initialValue || undefined}
    >
      {/* Required field here is a trick to make antd auto remove error message when user change input */}
      <div className="w-full md:w-2/3">
        <Input name="name" label={t("name")} required />

        <Input
          name="email"
          label={t("email")}
          rules={[{ type: "email", message: "Invalid email format" }]}
          required
        />

        <TextAreaInput name="message" label={t("message")} required />
      </div>

      <PrimaryButton htmlType="submit" loading={isLoading}>
        {t("send")}
      </PrimaryButton>
    </Form>
  );
};

export default ContactUsForm;
