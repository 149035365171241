import { AxiosError } from "axios";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";

import { guestAxios, url } from "~/utils/axios";

type ForgotPasswordInfo = {
  email: string;
  redirect_url: string;
};

const forgotPasswordMutationFn = (forgotPasswordInfo: ForgotPasswordInfo) => {
  return guestAxios
    .post(url.forgot_password, forgotPasswordInfo)
    .then((response) => response.data);
};

export const useForgotPasswordMutation = (
  options?: UseMutationOptions<
    { refresh_token: string; access_token: string },
    AxiosError<{ detail: string }>,
    ForgotPasswordInfo
  >
) => {
  return useMutation(forgotPasswordMutationFn, options);
};
