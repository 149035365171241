import { AxiosError } from "axios";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";

import { guestAxios, url } from "~/utils/axios";

type ResetPasswordInfo = {
  reset_token: string;
  new_password: string;
};

const resetPasswordMutationFn = (resetPasswordInfo: ResetPasswordInfo) => {
  return guestAxios
    .post(url.reset_password, resetPasswordInfo)
    .then((response) => response.data);
};

export const useResetPasswordMutation = (
  options?: UseMutationOptions<
    any,
    AxiosError<{ detail: string }>,
    ResetPasswordInfo
  >
) => {
  return useMutation(resetPasswordMutationFn, options);
};
