import { Button, Col, Form, Row } from "antd";
import { useTranslation } from "react-i18next";
import {
  IoArrowBackOutline,
  IoArrowForwardOutline,
  IoHomeOutline,
  IoKeyOutline,
  IoLogoDiscord,
  IoLogoGoogle,
} from "react-icons/io5";

import images from "~/assets/images";
import { PrimaryButton, SecondaryButton } from "~/components/Button";
import { PaddedContainer } from "~/components/Container";
import { HorizontalInput } from "~/components/Input";
import {
  DescriptionText,
  SubTitle,
  Title,
  VideoSectionTitle,
} from "~/components/Text";
import { VideoInfo } from "~/components/VideoInfo";

import ProfileForm from "./ProfileForm";
import { useUserProfileQuery } from "./query";
import styles from "./styles.module.less";

const ProfilePage = () => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const { data, isLoading, isError, refetch } = useUserProfileQuery();

  return (
    <PaddedContainer>
      <div>
        <SubTitle>{t("header.profile")}</SubTitle>

        <ProfileForm initialValue={data} />
      </div>

      <div className="mt-16">
        <SubTitle>{t("accountsettings")}</SubTitle>

        <div className="space-y-5 my-5">
          {/* <Row gutter={[24, 8]} className="w-2/3 items-center">
            <Col xs={24} sm={12}>
              <DescriptionText>Connect Discord</DescriptionText>
              <div className="text-neutral-500 text-xs flex-1">
                We'll send you notifications on Discord
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <SecondaryButton className="!bg-[#7087D3]">
                <IoLogoDiscord className="text-lg" />
                Discord
              </SecondaryButton>
            </Col>
          </Row> */}
          <Row gutter={[24, 8]} className="w-2/3 items-center">
            <Col xs={24} sm={12}>
              <DescriptionText>{t("connectwithgoogle")}</DescriptionText>
              <div className="text-neutral-500 text-xs flex-1">
                {t("profile.transferyoutube")}
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <SecondaryButton className="!bg-[#EA4335]">
                <IoLogoGoogle className="text-lg" />
                Google
              </SecondaryButton>
            </Col>
          </Row>
        </div>
      </div>
    </PaddedContainer>
  );
};

export default ProfilePage;
