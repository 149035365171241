import { QueryFunction, useQuery, UseQueryOptions } from "react-query";
import { useLocation, useSearchParams } from "react-router-dom";

import { UserProfileT } from "~/types";
import { appAxios, url } from "~/utils/axios";

// type VerifyReturn = {
//   is_nominee: boolean;
// };

// const verifyTokenFn: QueryFunction<VerifyReturn> = () => {
//   const token = localStorage.getItem("access_token");

//   console.log(token);

//   if (token === undefined) {
//     throw new Error("No token is provided");
//   }

//   return guestAxios
//     .post<VerifyReturn>(url.profile, { token })
//     .then((response) => response.data);
// };

// export const useVerifyToken = (options?: UseQueryOptions<VerifyReturn>) => {
//   const token = localStorage.getItem("access_token");

//   const [searchParams] = useSearchParams();
//   const location = useLocation();

//   if (searchParams.get("redirect") === "yes" && !token) {
//     sessionStorage.setItem("to", location.pathname);
//   }

//   return useQuery<VerifyReturn>(
//     ["authenticate-token"],
//     verifyTokenFn,
// options
//   ? {
//       ...options,
//       retry: false,
//       staleTime: 0,
//       cacheTime: 0,
//       enabled: !!token,
//     }
//   : undefined
//   );
// };

const userQueryFn = () => {
  return appAxios.get(url.profile).then((response) => {
    console.log("route profile:", response);
    return response.data;
  });
};

// Get user profile
export const useUserProfileQuery = (
  options?: UseQueryOptions<UserProfileT>
) => {
  const token = localStorage.getItem("access_token");

  return useQuery<UserProfileT>(
    ["profile"],
    userQueryFn,
    options
      ? {
          ...options,
          // retry: false,
          staleTime: 300000,
          cacheTime: 300000,
          enabled: !!token,
        }
      : undefined
  );
};
