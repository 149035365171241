import { AxiosError } from "axios";
import { useInfiniteQuery, useQuery, UseQueryOptions } from "react-query";

import { VideoInfoT } from "~/types";
import { guestAxios, url } from "~/utils/axios";

type GetPostResponse = {
  results: VideoInfoT[];
  next: string;
};

const getPostQueryFn = (context: any) => {
  const [_, publish_status, search] = context.queryKey;
  const { pageParam: nextPageUrl } = context;

  return guestAxios
    .get<GetPostResponse>(nextPageUrl ?? url.get_posts, {
      params: nextPageUrl ? {} : { publish_status, search },
    })
    .then((response) => response.data);
};

// export const useGetPostQuery = (
//   publish_status?: string,
//   options?: UseQueryOptions<
//     VideoInfoT[],
//     Error | AxiosError<{ detail: string }>
//   >
// ) => {
//   return useQuery<any, Error | AxiosError<{ detail: string }>>(
//     ["post", publish_status],
//     getPostQueryFn,
//     options
//   );
// };

export const useGetPostInfiniteQuery = (
  publish_status?: string,
  search?: string,
  options?: any
) => {
  return useInfiniteQuery<
    GetPostResponse,
    Error | AxiosError<{ detail: string }>
  >(["post-infinite", publish_status, search], getPostQueryFn, {
    ...options,
    getNextPageParam: (lastPage, pages) => {
      return lastPage.next;
    },
  });
};

const liveSchedulesQueryFn = (context: any) => {
  const [_, search] = context.queryKey;

  const { pageParam: nextPageUrl } = context;

  return guestAxios
    .get<GetPostResponse>(nextPageUrl ?? url.live_schedules, {
      params: nextPageUrl ? {} : { search },
    })
    .then((response) => response.data);
};

export const useLiveSchedulesInfiniteQuery = (
  search?: string,
  options?: any
) => {
  return useInfiniteQuery<
    GetPostResponse,
    Error | AxiosError<{ detail: string }>
  >(["post-infinite", search], liveSchedulesQueryFn, {
    ...options,
    getNextPageParam: (lastPage, pages) => {
      return lastPage.next;
    },
  });
};
