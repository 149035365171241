import React, { useState } from "react";
import { Button, Col, Collapse, Radio, Row, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import {
  IoArrowBackOutline,
  IoArrowDownCircle,
  IoArrowForwardOutline,
  IoCheckmarkCircle,
  IoChevronDownCircle,
  IoChevronForwardCircle,
  IoHomeOutline,
  IoKeyOutline,
  IoTicket,
} from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import images from "~/assets/images";
import { PrimaryButton, RadioButton } from "~/components/Button";
import { SecondaryButton } from "~/components/Button";
import { PaddedContainer } from "~/components/Container";
import { ProfileFeatureImage } from "~/components/Image";
import {
  DescriptionText,
  SubTitle,
  Title,
  VideoSectionTitle,
} from "~/components/Text";
import { VideoInfo } from "~/components/VideoInfo";
import { Path } from "~/enums";

// import { useGetPackagesQuery } from "./query";
import { useGetOrganisationDetailsQuery } from "./query";
import styles from "./styles.module.less";

const MOCKSTREAMERS = [
  {
    id: 132,
    profile_img:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Golde33443.jpg/220px-Golde33443.jpg",
    username: "Streamer1",
    description:
      "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odtione voluptatem sequi nesciunt.",
    subscribers: 500000,
  },
  {
    id: 132,
    profile_img:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Golde33443.jpg/220px-Golde33443.jpg",
    username: "Streamer1",
    description:
      "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odtione voluptatem sequi nesciunt.",
    subscribers: 500000,
  },
];

const BlogPosts = [
  {
    title: "Getting Started",
    description:
      "Is be upon sang fond must shew. Really boy law county she unable her sister. Feet you off its like like six. ",
  },
  {
    title: "Building Your Community",
    description:
      "The its enable direct men depend highly. Ham windows sixteen who inquiry fortune demands.",
  },
  {
    title: "Growing Your Channel",
    description:
      "Among sex are leave law built now. In built table in an rapid blush. Merits behind on afraid or warmly.",
  },
];

const AboutUsPage = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  // const { data, isLoading, isError, refetch } = useGetPackagesQuery();
  const {
    data: organisationData,
    isLoading: isLoadingOrganisation,
    isError: isErrorOrganisation,
  } = useGetOrganisationDetailsQuery();

  return (
    <PaddedContainer>
      {/*----- COMPANY INFO -----*/}
      <div className="mb-7 mx-7 mt-3 flex flex-col items-center">
        <div className="py-5 max-w-6xl">
          <img src={images.aboutUsBannerLogo} />
        </div>
        <div className="flex space-x-4 p-3 justify-center">
          {organisationData?.social_media.map((social: any) => (
            <div
              className="cursor-pointer"
              onClick={() => {
                window.open(social.social_media_link, "_blank");
              }}
            >
              <img
                src={images.socialLogo[social.social_media as keyof any]}
                width={38}
              />
            </div>
          ))}
        </div>

        <p className="max-w-3xl text-base">{t("aboutus.description")}</p>
      </div>

      {/*----- FEATURED STREAMERS -----*/}
      {/* <div className="mb-20 mt-12">
        <Title>{t("aboutus.featuredstreamers")}</Title>
        <Row gutter={16} className="mt-5">
          {MOCKSTREAMERS && Array.isArray(MOCKSTREAMERS)
            ? MOCKSTREAMERS.slice(0, 4).map((streamer) => (
                <Col xs={{ span: 8 }} md={{ span: 6 }} lg={{ span: 4 }}>
                  <Tooltip
                    placement="bottomLeft"
                    arrowPointAtCenter
                    title={
                      <div className="space-y-2">
                        <div className="text-green-500 font-semibold">
                          {streamer.username}
                        </div>
                        <DescriptionText>
                          {streamer.description}
                        </DescriptionText>
                        <p className="text-green-200">
                          {Intl.NumberFormat("en-US", {
                            notation: "compact",
                            maximumFractionDigits: 1,
                          }).format(streamer.subscribers)}{" "}
                          Subscribers
                        </p>
                      </div>
                    }
                  >
                    <div className={styles.featuredStreamer}>
                      <ProfileFeatureImage src={streamer.profile_img} />
                    </div>
                  </Tooltip>
                </Col>
              ))
            : null}
        </Row>
      </div> */}

      {/*----- OPTIMIZED -----*/}
      <Row
        gutter={[36, 60]}
        className="px-8 my-20"
        justify="center"
        align="middle"
      >
        <Col xs={24} xxl={11} className="!max-w-4xl">
          <div className="relative py-20">
            <Title>{t("aboutus.vision")}</Title>
            <div className="mt-4">{t("aboutus.vision.description")}</div>
            <div className="scale-75 absolute bottom-0 right-0 -z-10">
              <img src={images.aboutUsGraph} width={550} />
            </div>
          </div>
        </Col>
        <Col xs={24} xxl={11}>
          <div className="flex flex-col items-center">
            <Title>{t("aboutus.mission")}</Title>
            <ul className="list-disc space-y-2 mt-4">
              {Array.from({ length: 5 }, (_, i) => i + 1).map((number) => (
                <li className="text-neutral-300">
                  {t(`aboutus.mission.${number}`)}
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>

      {/*----- SUCCEED -----*/}
      {/* <div className="relative overflow-hidden mt-8 p-5">
        <Row justify="center" className="mt-5 mb-8">
          <div className="flex flex-col items-center">
            <Title className="!text-2xl">{t("aboutus.mission")}</Title>
          </div>
        </Row>
        <div className="flex flex-col items-center">
          <Row gutter={[48, 32]} className="max-w-2xl" justify="center">
            <ul className="list-disc space-y-2">
              {Array.from({ length: 5 }, (_, i) => i + 1).map((number) => (
                <li className="text-neutral-300">
                  {t(`aboutus.mission.${number}`)}
                </li>
              ))}
            </ul>
          </Row>
        </div>
      </div> */}

      {/*----- PRICING -----*/}
      {/* <div className="relative overflow-hidden mt-8 p-8">
        <Row gutter={24}>
          <Col xs={24} lg={13} className="my-8">
            <Title>{t("aboutus.pricingplans")}</Title>
            <DescriptionText className="mt-2 mix-blend-multiply">
              {t("packages.subtitle")}
            </DescriptionText>

            <div className="rounded-lg p-4 bg-neutral-800 mt-8">
              <div>
                <IoCheckmarkCircle className="text-base text-green-500 mr-3" />
                <span className="text-sm">Free 1 month trial for new user</span>
              </div>
              <div>
                <IoCheckmarkCircle className="text-base text-green-500 mr-3" />
                <span className="text-sm">Cancel anytime you want</span>
              </div>
              <div className="flex justify-end mt-4">
                <div>
                  <PrimaryButton
                    onClick={() => {
                      navigate(`/${Path.subscribe}/${Path.package}`);
                    }}
                  >
                    {t("aboutus.fullpricing")}
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} lg={11}>
            <Collapse
              className="space-y-3"
              defaultActiveKey={["1"]}
              ghost
              expandIcon={(props) =>
                props.isActive ? (
                  <IoChevronDownCircle className="!text-base text-green-500" />
                ) : (
                  <IoChevronForwardCircle className="!text-base text-green-500" />
                )
              }
              expandIconPosition={"end"}
            >
              {data?.map((membership) => (
                <Collapse.Panel
                  className="!bg-neutral-800 !rounded-lg"
                  header={membership.title}
                  key={membership.id}
                >
                  <DescriptionText>{membership.description}</DescriptionText>
                  <div className="flex items-center justify-between bg-neutral-800 flex-1 rounded-md mt-4 space-x-3">
                    <div>
                      {membership.subscribe_price.map((price) => (
                        <div>
                          <span className="font-semibold text-lg">
                            {price.currency.toUpperCase()} {price.amount}
                          </span>
                        </div>
                      ))}
                      <span className="text-xs text-gray-400">
                        {" "}
                        per{" "}
                        {membership.expiry_duration > 1
                          ? membership.expiry_duration + " months"
                          : "month"}
                      </span>
                    </div>
                    <div>
                      <PrimaryButton
                        onClick={() => {
                          navigate(
                            `/${Path.subscribe}/${Path.package}/${membership.id}`,
                            { state: { selectedPackage: membership } }
                          );
                        }}
                      >
                        {t("aboutus.subscribenow")}
                      </PrimaryButton>
                    </div>
                  </div>
                </Collapse.Panel>
              ))}
            </Collapse>
          </Col>
        </Row>
        <div className="rotate-180 scale-50 absolute bottom-[-300px] left-[-190px] -z-10">
          <img src={images.blob1} width={600} />
        </div>
        <div className="rotate-180 scale-50 md:scale-100 absolute top-[-390px] md:top-[-200px] right-[-150px] -z-10">
          <img src={images.blob1} width={600} />
        </div>
      </div> */}

      {/*----- TUTORIALS -----*/}
      {/* <div className="my-8 space-y-8">
        <Title>{t("aboutus.howthingswork")}</Title>
        <Row gutter={[16, 16]}>
          {BlogPosts.map((blog) => (
            <Col xs={24} lg={8}>
              <div className="bg-neutral-800 rounded-xl p-7 pt-16 h-full">
                <SubTitle>{blog.title}</SubTitle>
                <p className="text-xs text-neutral-300 mt-7 mb-4">
                  {blog.description}
                </p>
                <div className="flex items-center space-x-2 text-green-500 text-xs">
                  <div>Read More</div>
                  <IoArrowForwardOutline />
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <div className="flex justify-center">
          <div>
            <PrimaryButton>More About Platform</PrimaryButton>
          </div>
        </div>
      </div> */}
    </PaddedContainer>
  );
};

export default AboutUsPage;
