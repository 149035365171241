import { useEffect, useState } from "react";
import { Avatar, Button, Col, Image, Result, Row } from "antd";
import { useTranslation } from "react-i18next";
import {
  IoArrowBackOutline,
  IoArrowForwardOutline,
  IoHomeOutline,
  IoKeyOutline,
  IoPerson,
  IoRadioOutline,
  IoVideocam,
} from "react-icons/io5";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useResolvedPath,
} from "react-router-dom";

import images from "~/assets/images";
import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from "~/components/Button";
import { PaddedContainer } from "~/components/Container";
import {
  DescriptionText,
  SubTitle,
  Title,
  VideoSectionTitle,
} from "~/components/Text";
import { VideoInfo } from "~/components/VideoInfo";
import { VideoRow } from "~/components/VideoRow";
import { useRole } from "~/contexts/role";
import { Path } from "~/enums";

import {
  useGetPostInfiniteQuery,
  useGetPublisherDetailsQuery,
  useLiveSchedulesQuery,
} from "./query";
import styles from "./styles.module.less";

import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";

const Content = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { isBroadcaster } = useRole();

  const {
    data: publisherDetails,
    isLoading: isLoadingPublisherDetails,
    isError: isErrorPublisherDetails,
    refetch: refetchPublisherDetails,
  } = useGetPublisherDetailsQuery(id!);

  const {
    isLoading,
    isError,
    error,
    data,
    fetchNextPage,
    isFetching,
    hasNextPage,
    isFetchingNextPage,
  } = useGetPostInfiniteQuery(id);

  const {
    data: liveSchedule,
    isLoading: isLoadingLiveSchedule,
    isError: isErrorLiveSchedule,
  } = useLiveSchedulesQuery(id);

  const { pages } = (data as any) || {
    pages: [],
  };

  const [videos, setVideos] = useState([]);

  useEffect(() => {
    let allPages = pages.map((page: any) => {
      return page.results;
    });

    var flattenedPages = [].concat.apply([], allPages);

    setVideos(flattenedPages);
  }, [pages]);

  if (isErrorPublisherDetails) {
    return (
      <Result
        status="warning"
        icon={<SearchOutlined />}
        title={t("channel.notfound")}
        extra={
          <Button
            type="primary"
            onClick={() => navigate(`/${Path.browse}/all`)}
          >
            {t("backhome")}
          </Button>
        }
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      />
    );
  }

  return (
    <PaddedContainer>
      <div className="flex items-center space-x-4 p-2 mb-6">
        <div>
          <Avatar
            className="!h-[80px] !w-[80px]"
            icon={
              <div className="h-full w-full flex justify-center items-center">
                <IoPerson className="text-[50px]" />
              </div>
            }
            src={
              !!publisherDetails?.photo?.file && (
                <Image
                  draggable={false}
                  src={publisherDetails?.photo?.file}
                  style={{
                    width: 80,
                    height: 80,
                    objectFit: "contain",
                    background: "white",
                  }}
                  preview={false}
                />
              )
            }
          />
        </div>
        <div>
          <div className="text-xl">{publisherDetails?.fullname}</div>
        </div>
      </div>

      {liveSchedule && liveSchedule.length > 0 && (
        <div className="mb-8">
          <VideoSectionTitle>
            <span className="text-red-500 mr-2">•</span>
            {t("livenow")}
          </VideoSectionTitle>
          <VideoRow
            size="small"
            data={liveSchedule}
            isLoading={isLoadingLiveSchedule}
            hidePublisher
          />
        </div>
      )}

      <VideoSectionTitle>{t("videos")}</VideoSectionTitle>
      <VideoRow
        size="small"
        data={videos}
        isLoading={isLoading}
        hidePublisher
      />

      <div className="my-5 flex justify-center">
        <div>
          {hasNextPage ? (
            <SecondaryButton
              onClick={fetchNextPage}
              disabled={!hasNextPage}
              loading={isFetchingNextPage}
            >
              {isFetchingNextPage ? t("loadingmore") : t("loadmore")}
            </SecondaryButton>
          ) : null}
        </div>
      </div>
    </PaddedContainer>
  );
};

export default Content;
