import { ReactNode } from "react";
import { Avatar as AntdAvatar, Col, Input, Row } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { IoPerson } from "react-icons/io5";

import { Avatar } from "../Avatar";

type LiveChatContainerProps = {
  children?: ReactNode;
  className?: string;
};

const LiveChatContainer = ({ children, className }: LiveChatContainerProps) => {
  return (
    <div className={"overflow-auto w-full flex flex-col-reverse " + className}>
      {children}
    </div>
  );
};

type LiveChatTextProps = {
  url?: string;
  name: string;
  chatContent: string;
  className?: string;
  createdAt?: any;
};

const LiveChatText = ({
  url,
  name,
  chatContent,
  className,
  createdAt,
}: LiveChatTextProps) => {
  return (
    <div className={"flex flex-row " + className}>
      <div className="mr-2">
        {url ? (
          <Avatar
            src={url}
            widthClassName="w-[30px]"
            heightClassName="h-[30px]"
          />
        ) : (
          <AntdAvatar icon={<IoPerson />} />
        )}
      </div>

      <div>
        <div className="text-[#FFFFFF80] mr-2">
          {name} {moment(createdAt).format("HH:mm")}{" "}
          <div className="text-[#FFFFFF]  break-all">{chatContent}</div>
        </div>
      </div>
    </div>
  );
};

type LiveChatButtonProps = {
  url?: string;
  onPressEnter?: any;
  value?: string;
  onChange?: any;
};

const LiveChatButton = ({
  url,
  onPressEnter,
  value,
  onChange,
}: LiveChatButtonProps) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="flex flex-row mb-3 items-center">
      <div className="mr-2">
        {url ? (
          <Avatar
            src={url}
            widthClassName="w-[30px]"
            heightClassName="h-[30px]"
          />
        ) : (
          <AntdAvatar icon={<IoPerson />} />
        )}
      </div>
      <Input.TextArea
        className="w-full"
        showCount
        maxLength={200}
        autoSize={{ minRows: 1, maxRows: 6 }}
        placeholder={t("content.livechat.prompt")}
        bordered={false}
        onPressEnter={onPressEnter}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export { LiveChatContainer, LiveChatText, LiveChatButton };
