import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import {
  IoArrowBackOutline,
  IoArrowForwardOutline,
  IoHomeOutline,
  IoKeyOutline,
  IoPerson,
} from "react-icons/io5";
import { Link, Outlet } from "react-router-dom";

import images from "~/assets/images";
import { PrimaryButton, TextButton } from "~/components/Button";
import { PaddedContainer } from "~/components/Container";
import { DescriptionText, SubTitle, Title } from "~/components/Text";

import ForgotPasswordForm from "./ForgotPasswordForm";
import styles from "./styles.module.less";

const SignUpSkeleton = () => {
  return (
    <div className="relative overflow-hidden min-h-full">
      <PaddedContainer className="space-y-4">
        <Row gutter={[36, 36]}>
          <Col xs={24} md={10} className="space-y-4 md:!px-8">
            <Title>Reset Password</Title>
            <DescriptionText>
              Forgot your password? Don't worry, just fill in your email and
              we'll help you reset it.
            </DescriptionText>

            <DescriptionText>Don't have an account yet?</DescriptionText>
            <Link to="/signup/user-information">Register here</Link>
          </Col>
          <Col xs={24} md={14}>
            <ForgotPasswordForm />
          </Col>
        </Row>
      </PaddedContainer>

      <div className="hidden md:block absolute bottom-[-40px] left-[-200px] -z-10">
        <img src={images.blob3} width={600} />
      </div>
    </div>
  );
};

export default SignUpSkeleton;
