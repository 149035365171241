import { FC, ReactElement, ReactNode, useEffect, useLayoutEffect } from "react";
import { DatePicker, Form, Input as AntdInput, Select } from "antd";
import {
  IoEye,
  IoEyeOffOutline,
  IoEyeOutline,
  IoOptions,
} from "react-icons/io5";
import { useMutation } from "react-query";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";

import { DescriptionText } from "../Text";

type RenderInputProps = {
  type?: "password" | "date";
  addonBefore?: ReactNode;
  suffix?: ReactNode;
  disabled?: boolean;
  showTime?: boolean;
  format?: string;
};

const renderInput = ({
  type,
  addonBefore,
  suffix,
  disabled,
  showTime,
  format,
}: RenderInputProps) => {
  switch (type) {
    case "password":
      return <AntdInput.Password className="!text-sm" />;
    case "date":
      return (
        <DatePicker
          showTime={showTime}
          format={format}
          className="w-full"
          disabled={disabled}
        />
      );
    default:
      return (
        <AntdInput
          addonBefore={addonBefore}
          className="!text-sm"
          suffix={suffix}
          disabled={disabled}
        />
      );
  }
};

type InputProps = RenderInputProps & {
  name: string;
  label: ReactElement | string;
  rules?: {}[];
  description?: string;
  required?: boolean;
  onChange?: any;
};

const Input = ({
  name,
  label,
  rules = [],
  required,
  addonBefore,
  type,
  suffix,
  disabled,
}: InputProps) => {
  return (
    <Form.Item
      name={name}
      label={<DescriptionText>{label}</DescriptionText>}
      rules={[
        ...(required
          ? [{ required: true, message: label + " is required" }]
          : []),
        ...rules,
      ]}
    >
      {renderInput({ type, addonBefore, suffix, disabled })}
    </Form.Item>
  );
};

const HorizontalInput = ({
  name,
  label,
  rules = [],
  description,
  required,
  addonBefore,
  type,
  suffix,
  disabled,
  showTime,
  format,
}: InputProps) => {
  return (
    <Form.Item
      name={name}
      label={
        <div>
          <DescriptionText>{label}</DescriptionText>
          {description && <div className="text-xs flex-1">{description}</div>}
        </div>
      }
      labelAlign="left"
      rules={
        required
          ? [{ required: true, message: label + " is required" }, ...rules]
          : rules
      }
    >
      {renderInput({ type, addonBefore, suffix, disabled, showTime, format })}
    </Form.Item>
  );
};

const TextAreaInput = ({ name, label, rules = [], required }: InputProps) => {
  return (
    <Form.Item
      name={name}
      label={<DescriptionText>{label}</DescriptionText>}
      rules={
        required
          ? [{ required: true, message: label + " is required" }, ...rules]
          : rules
      }
    >
      <AntdInput.TextArea className="!text-sm !h-[100px]" />
    </Form.Item>
  );
};

type SelectInputProps = InputProps & {
  options: any;
  valueKey?: string;
  noStyle?: boolean;
  nameKey?: string;
  optionFilterProp?: string;
  showSearch?: boolean;
  disabled?: boolean;
  mode?: "multiple" | "tags" | undefined;
};

const SelectInput = ({
  name,
  label,
  rules = [],
  description,
  options = [],
  required,
  valueKey,
  noStyle,
  nameKey,
  onChange,
  optionFilterProp,
  showSearch,
  disabled,
  mode,
}: SelectInputProps) => {
  return (
    <Form.Item
      name={name}
      label={
        label && (
          <div>
            <DescriptionText>{label}</DescriptionText>
            {description && <div className="text-xs flex-1">{description}</div>}
          </div>
        )
      }
      labelAlign="left"
      rules={[
        required ? { required: true, message: label + " is required" } : {},
        ...rules,
      ]}
      noStyle={noStyle}
    >
      <Select
        className="min-w-[70px]"
        onChange={onChange}
        optionFilterProp={optionFilterProp}
        showSearch={showSearch}
        disabled={disabled}
        mode={mode}
      >
        {options.map((option: any, index: number) => (
          <Select.Option
            key={name + index}
            value={valueKey ? option[valueKey] : option.value}
            object={option}
          >
            {nameKey ? option[nameKey] : option.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export { Input, TextAreaInput, HorizontalInput, SelectInput };
