import { ReactNode } from "react";
import { Avatar, Col, Divider, Image, Row, Tag, Typography } from "antd";
import parse from "html-react-parser";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { GiAlliedStar } from "react-icons/gi";
import { IoPerson } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";

import { DescriptionText } from "~/components/Text";
import { Path } from "~/enums";
import { VideoInfoT } from "~/types";

import styles from "./styles.module.less";

type VideoInfoProps = {
  video: VideoInfoT;
  onClick?: () => void;
  hidePublisher?: boolean;
};

const renderDetails = (video: VideoInfoT, liveViewCount?: number) => {
  if (video?.is_live) {
    if (video?.is_live_now) {
      return (
        Intl.NumberFormat("en-US", {
          notation: "compact",
          maximumFractionDigits: 1,
        }).format(liveViewCount ?? video.statistic?.view_count) +
        " watching • Streaming live"
      );
    } else if (video?.is_live_ended) {
      return (
        (video?.statistic?.view_count
          ? Intl.NumberFormat("en-US", {
              notation: "compact",
              maximumFractionDigits: 1,
            }).format(video.statistic?.view_count)
          : 0) +
        " views • " +
        "Streamed live on " +
        moment(video.live_start_at).calendar(null, {
          sameElse: "LLL",
        })
      );
    } else {
      return (
        <Tag>
          {moment(video.live_start_at).calendar(null, {
            sameElse: "LLL",
          })}
        </Tag>
      );
    }
  }

  return (
    (video?.statistic?.view_count
      ? Intl.NumberFormat("en-US", {
          notation: "compact",
          maximumFractionDigits: 1,
        }).format(video?.statistic?.view_count)
      : 0) +
    " views • " +
    moment(video?.created_at).fromNow()
  );
};

export const VideoInfo = ({
  video,
  onClick,
  hidePublisher,
}: VideoInfoProps) => {
  const navigate = useNavigate();

  const onClickStreamer = () => {
    navigate(`/${Path.channel}/${video.user?.member_id}`);
  };

  return (
    <div className="flex flex-col items-center">
      <div
        className={
          styles.thumbnailContainer + " w-5/6 md:w-full cursor-pointer "
        }
        onClick={onClick}
      >
        <img
          alt="thumbnail-img"
          className={styles.thumbnailImg}
          src={video.thumbnail?.file}
          onError={(e: any) => {
            e.target.src =
              "https://media.istockphoto.com/vectors/thumbnail-image-vector-graphic-vector-id1147544807?k=20&m=1147544807&s=612x612&w=0&h=pBhz1dkwsCMq37Udtp9sfxbjaMl27JUapoyYpQm0anc=";
          }}
        />
        {video.is_live_now && (
          <div className="bg-red-500 absolute bottom-2 right-2 z-100 text-[10px] py-px px-2 rounded">
            LIVE
          </div>
        )}
      </div>
      {/* <div className="mt-2 w-3/6 md:w-full px-2 md:px-1">
        <Typography.Paragraph ellipsis={{ rows: 2 }}>
          {video.title}
        </Typography.Paragraph>
        <DescriptionText>{renderDetails(video)}</DescriptionText>
        <div className="flex flex-row items-center mt-2">
          <Avatar
            icon={<IoPerson />}
            src={
              !!video.user?.photo?.file && (
                <Image
                  draggable={false}
                  src={video.user.photo.file}
                  style={{
                    width: 32,
                    height: 32,
                    objectFit: "contain",
                    background: "white",
                  }}
                  preview={false}
                />
              )
            }
          />
          <div className="ml-2">{video.user?.fullname}</div>
        </div>
      </div> */}
      <div className="flex mt-2 w-5/6 md:w-full px-2 md:px-1">
        {!hidePublisher && (
          <div>
            <Avatar
              className="cursor-pointer"
              icon={<IoPerson />}
              onClick={onClickStreamer}
              src={
                !!video.user?.photo?.file && (
                  <Image
                    draggable={false}
                    src={video.user.photo.file}
                    style={{
                      width: 32,
                      height: 32,
                      objectFit: "contain",
                      background: "white",
                    }}
                    preview={false}
                  />
                )
              }
            />
          </div>
        )}
        <div className="ml-2">
          <Typography.Paragraph
            ellipsis={{ rows: 2 }}
            className="!mb-2 cursor-pointer"
            onClick={onClick}
          >
            {video.title}
          </Typography.Paragraph>
          {!hidePublisher && (
            <div className="cursor-pointer" onClick={onClickStreamer}>
              <DescriptionText className="mb-1">
                {video.user?.fullname}
              </DescriptionText>
            </div>
          )}
          <DescriptionText>{renderDetails(video)}</DescriptionText>
        </div>
      </div>
    </div>
  );
};

export const VideoInfoHorizontal = ({ video }: VideoInfoProps) => {
  const navigate = useNavigate();

  const onClickStreamer = (e: any) => {
    e.stopPropagation();

    navigate(`/${Path.channel}/${video.user?.member_id}`);
  };

  return (
    <div className="flex flex-row h-[94px] select-none cursor-pointer transform transition duration-300 hover:scale-105">
      <div>
        <div className={styles.horizontalThumbnailContainer}>
          <img
            alt="thumbnail-img"
            className={styles.thumbnailImg}
            src={video.thumbnail?.file}
            onError={(e: any) => {
              e.target.src =
                "https://media.istockphoto.com/vectors/thumbnail-image-vector-graphic-vector-id1147544807?k=20&m=1147544807&s=612x612&w=0&h=pBhz1dkwsCMq37Udtp9sfxbjaMl27JUapoyYpQm0anc=";
            }}
            draggable={false}
          />
          {video.is_live && !video.is_live_ended && (
            <div className="bg-red-500 absolute bottom-2 right-2 z-100 text-[10px] py-px px-2 rounded">
              LIVE
            </div>
          )}
        </div>
      </div>
      <div className="ml-3">
        <div className="line-clamp-2 leading-[1.2]">{video.title}</div>
        <DescriptionText>{renderDetails(video)}</DescriptionText>
        <div className="flex flex-row items-center mt-2">
          <Avatar
            draggable={false}
            icon={<IoPerson />}
            src={
              !!video.user?.photo?.file && (
                <Image
                  draggable={false}
                  onClick={onClickStreamer}
                  src={video.user.photo.file}
                  style={{
                    width: 32,
                    height: 32,
                    background: "white",
                    objectFit: "contain",
                  }}
                  preview={false}
                />
              )
            }
          />
          <div className="ml-2" onClick={onClickStreamer}>
            {video.user?.fullname}
          </div>
        </div>
      </div>
    </div>
  );
};

type VideoPlayerInfoProps = {
  video: VideoInfoT;
  onClick?: () => void;
  actions?: ReactNode;
  liveViewCount?: number;
};

export const VideoPlayerInfo = ({
  video,
  actions,
  liveViewCount,
}: VideoPlayerInfoProps) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const onClickStreamer = () => {
    navigate(`/${Path.channel}/${video.user?.member_id}`);
  };

  return (
    <div className="flex">
      <div className="mt-2 w-full">
        <div className="mb-2">
          {video.is_live && !video.is_live_ended && (
            <span className="bg-red-500 z-100 text-[10px] py-1 px-2 rounded mr-2 align-text-bottom">
              LIVE
            </span>
          )}
          <span className="text-lg font-normal">{video?.title}</span>
        </div>
        <div className="my-4">
          {video?.audiences?.length > 0 &&
            video?.audiences?.map((item: any) => {
              return (
                <Tag key={item.id} icon={<GiAlliedStar className="mr-1" />}>
                  {item.title}
                </Tag>
              );
            })}
        </div>
        <DescriptionText>{renderDetails(video, liveViewCount)}</DescriptionText>

        <div className="flex flex-row items-center mt-4 justify-between">
          <div
            className="cursor-pointer flex flex-row items-center"
            onClick={onClickStreamer}
          >
            <div className={styles.avatarContainer}>
              <Avatar
                draggable={false}
                icon={<IoPerson />}
                src={
                  !!video.user?.photo?.file && (
                    <Image
                      draggable={false}
                      src={video.user.photo.file}
                      style={{
                        width: 32,
                        height: 32,
                        background: "white",
                        objectFit: "contain",
                      }}
                      preview={false}
                    />
                  )
                }
              />
            </div>
            <div className="ml-2">{video?.user?.fullname}</div>
          </div>
          <div>{actions}</div>
        </div>
        <Divider className="!border-t-[0.5px] !my-[16px]" />
        <div className={`items-center mt-2 ${styles.videoDescription}`}>
          {video?.description
            ? parse(video?.description!)
            : t("video.nodescription")}
        </div>
      </div>
    </div>
  );
};
