import { useLayoutEffect, useState } from "react";
import {
  Avatar,
  Button,
  Col,
  Descriptions,
  Form,
  Image,
  message,
  Modal,
  Radio,
  Row,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  IoArrowBackOutline,
  IoArrowForwardOutline,
  IoChevronDown,
  IoCloseOutline,
  IoHomeOutline,
  IoKeyOutline,
  IoLogoDiscord,
  IoLogoGoogle,
  IoPerson,
} from "react-icons/io5";
import { useParams } from "react-router-dom";

import images from "~/assets/images";
import {
  PrimaryButton,
  RadioButton,
  SecondaryButton,
  TextButton,
} from "~/components/Button";
import { PaddedContainer } from "~/components/Container";
import { HorizontalInput } from "~/components/Input";
import {
  DescriptionText,
  SubTitle,
  Title,
  VideoSectionTitle,
} from "~/components/Text";
import { VideoInfo } from "~/components/VideoInfo";
import { Path } from "~/enums";
import { useQueryString } from "~/utils/hooks";

import {
  useCancelSubscriptionMutation,
  useGetReferralsQuery,
  useGetSubscriptionDetailsQuery,
  useUserProfileQuery,
} from "./query";
import styles from "./styles.module.less";

const HomePage = () => {
  const { t, i18n } = useTranslation();
  const { status } = useParams<{ status: string }>();
  const [form] = Form.useForm();
  const [currentTab, setCurrentTab] = useState("invoices");

  const {
    data: profileData,
    isLoading: isLoadingProfile,
    isError: isErrorProfile,
  } = useUserProfileQuery();
  const {
    data: subscriptionData,
    isLoading: isLoadingSubscription,
    isError: isErrorSubscription,
  } = useGetSubscriptionDetailsQuery();
  const {
    data: referralsData,
    isLoading: isLoadingReferrals,
    isError: isErrorReferrals,
  } = useGetReferralsQuery();

  const { mutate: cancelSubscription, isLoading: isLoadingCancel } =
    useCancelSubscriptionMutation({
      onSuccess: () => {
        message.success(
          "Your membership subscription has been cancelled successfully."
        );
      },
      onError: () => {
        message.error("Failed to cancel your subscription. Please try again.");
      },
    });

  const onFinish = (values: any) => {
    // navigate(`/subscribe/package`);
  };

  useLayoutEffect(() => {
    if (status == "success") {
      message.success(
        "Payment success. Please refresh page if subscription status is not updated.",
        7
      );
    } else if (status == "failed") {
      message.error("Payment failed. Please try again.", 7);
    }
  }, []);

  const onCancel = () => {
    Modal.confirm({
      title: "Confirm cancel subscription?",
      content: "Your subscription will be cancelled.",
      onOk() {
        cancelSubscription();
      },
    });
  };

  return (
    <PaddedContainer>
      <Row gutter={[48, 24]}>
        <Col xs={24} md={16}>
          <SubTitle>{t("header.subscription")}</SubTitle>

          {subscriptionData && (
            <div className="space-y-4 mt-8">
              <Row align="middle">
                <Col xs={12}>
                  <DescriptionText>{t("subscription.tier")}</DescriptionText>
                </Col>
                <Col xs={12}>
                  {profileData.membership_tier.membership_tier?.title}
                </Col>
              </Row>
              <Row align="middle">
                <Col xs={12}>
                  <DescriptionText>{t("subscription.status")}</DescriptionText>
                </Col>
                <Col xs={12}>
                  {profileData.membership_tier.is_active
                    ? "ACTIVE"
                    : "NON-ACTIVE"}
                </Col>
              </Row>
              <Row align="middle">
                <Col xs={12}>
                  <DescriptionText>{t("subscription.period")}</DescriptionText>
                </Col>
                <Col xs={12}>
                  {subscriptionData.current_period_start &&
                  subscriptionData.current_period_end
                    ? moment(subscriptionData.current_period_start).format(
                        "LL"
                      ) +
                      " to " +
                      moment(subscriptionData.current_period_end).format("LL")
                    : "-"}
                </Col>
              </Row>
              <Row align="middle">
                <Col xs={12}>
                  <DescriptionText>
                    {t("subscription.renewaldate")}
                  </DescriptionText>
                </Col>
                <Col xs={12}>
                  {subscriptionData.auto_renew
                    ? moment(subscriptionData.current_period_end)
                        .add(1, "days")
                        .format("LL")
                    : "-"}
                </Col>
              </Row>

              {subscriptionData.is_active && (
                <div className="flex justify-end">
                  <div>
                    <SecondaryButton
                      onClick={onCancel}
                      loading={isLoadingCancel}
                      danger
                    >
                      {t("subscription.cancel")}
                    </SecondaryButton>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="my-8">
            <Radio.Group
              value={currentTab}
              onChange={(e) => {
                setCurrentTab(e.target.value);
              }}
            >
              <RadioButton value="invoices">
                {t("subscription.invoices")}
              </RadioButton>
              {/* <RadioButton value="receipts">{t("subscription.receipts")}</RadioButton> */}
            </Radio.Group>
          </div>
        </Col>

        <Col xs={24} md={8} className="space-y-4">
          <SubTitle>{t("subscription.referrals")}</SubTitle>

          <div>
            <DescriptionText>{t("subscription.referralcode")}</DescriptionText>
            <div className="text-base">{profileData?.member_id}</div>
          </div>

          <div>
            <DescriptionText>{t("subscription.referrallink")}</DescriptionText>
            <div>
              <Typography.Text className="font-medium text-white" copyable>
                {`${window.location.origin}/${Path.connect}?referral=${profileData?.member_id}`}
              </Typography.Text>
            </div>
          </div>

          <div>
            <DescriptionText>{t("subscription.credits")}</DescriptionText>
            {/* <Title>{profileData.wallet_credit}</Title> */}
            <div className="rounded-lg mt-2 overflow-hidden">
              <div className="flex space-x-3 justify-between px-5 pt-5 pb-3 bg-neutral-900 items-center">
                <div>
                  <div className="text-xs">
                    {t("subscription.availablewithdraw")}
                  </div>
                  <div>
                    <Title>{profileData.wallet_credit}</Title>
                  </div>
                </div>
                <div>
                  <div className="text-xs text-neutral-400">
                    {t("subscription.earnings")}
                  </div>
                  <div className="text-xl text-neutral-400 font-semibold">
                    {profileData.wallet_credit}
                  </div>
                </div>
              </div>
              <div className="py-2 px-4 flex bg-neutral-800 justify-end">
                <Tooltip placement="left" title={t("subscription.comingsoon")}>
                  <div>
                    <TextButton
                      disabled
                      className="!text-xs !bg-neutral-900 !px-2 !rounded-md !h-auto"
                    >
                      {t("subscription.withdraw")}
                    </TextButton>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>

          {referralsData && (
            <div>
              <DescriptionText>
                {referralsData.length > 0 && referralsData.length + " "}
                {t("subscription.referreduser")}
              </DescriptionText>
              {referralsData.map((referral: any) => (
                <div>
                  <Avatar
                    icon={<IoPerson />}
                    src={
                      referral.photo.file && (
                        <Image
                          src={referral.photo.file}
                          style={{ width: 32, background: "white" }}
                        />
                      )
                    }
                  />
                  <span>{referral.fullname ?? referral.email}</span>
                </div>
              ))}
            </div>
          )}
        </Col>
      </Row>
    </PaddedContainer>
  );
};

export default HomePage;
