import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { useRole } from "./role";

interface AuthContextProps extends AuthInfo {
  setAuthInfo: Dispatch<SetStateAction<AuthInfo>>;
  logout: () => void;
}

type AuthInfo = {
  isVerifyingToken: boolean;
  isAuthenticated: boolean;
};

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

type AuthProviderProps = {
  children: React.ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const { setRoleInfo } = useRole();
  const [AuthInfo, setAuthInfo] = useState(() => {
    const token = localStorage.getItem("access_token");
    return {
      isVerifyingToken: !!token,
      isAuthenticated: false,
    };
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const logout = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    queryClient.clear();

    setAuthInfo({ isAuthenticated: false, isVerifyingToken: false });
    setRoleInfo({ isCreator: false, isBroadcaster: false });

    navigate("/");
  }, []);

  return (
    <AuthContext.Provider value={{ ...AuthInfo, setAuthInfo, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => useContext(AuthContext);

export { AuthProvider, useAuthContext };
