import {
  FC,
  HTMLAttributes,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Avatar,
  Breadcrumb,
  Button,
  Drawer,
  Dropdown,
  Grid,
  Image,
  Input,
  Menu,
  Tooltip,
} from "antd";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  IoArrowBackOutline,
  IoArrowDown,
  IoArrowForwardOutline,
  IoBodyOutline,
  IoBookmark,
  IoBookmarkOutline,
  IoChatboxEllipsesOutline,
  IoChevronDown,
  IoHomeOutline,
  IoKeyOutline,
  IoLayersOutline,
  IoLogOutOutline,
  IoMenuOutline,
  IoPeopleCircle,
  IoPerson,
  IoPersonOutline,
  IoPlayCircleOutline,
  IoSearch,
  IoSendOutline,
  IoTvOutline,
} from "react-icons/io5";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useResolvedPath,
} from "react-router-dom";

import images from "~/assets/images";
//   import { useAuthContext } from "~/contexts/auth";
import { PrimaryButton, SecondaryButton } from "~/components/Button";
import { SearchBar } from "~/components/SearchBar";
import { useAuthContext } from "~/contexts/auth";
import { useRole } from "~/contexts/role";
import { useSidebarContext } from "~/contexts/sidebar";
import { Path } from "~/enums";

import { useGetOrganisationDetailsQuery, useUserProfileQuery } from "./query";

const Header = () => {
  const { t, i18n } = useTranslation();
  const { logout } = useAuthContext();
  const { isCreator } = useRole();
  //   const { logout } = useAuthContext();
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const { setRoleInfo } = useRole();
  const resolvePath = useResolvedPath("");

  const {
    data: organisationData,
    isLoading: isLoadingOrganisation,
    isError: isErrorOrganisation,
  } = useGetOrganisationDetailsQuery();

  useEffect(() => {
    const leftPath = location.pathname.replace(resolvePath.pathname, "");
    const paths = leftPath.split("/").filter(Boolean);

    // const title = constructTitle(paths);
    // setTitle(title);
  }, [location.pathname, resolvePath.pathname]);

  const toggleDrawer = useCallback(() => {
    setShow(!show);
  }, [show, setShow]);

  const {
    data: profileData,
    isLoading: isProfileLoading,
    isError: isProfileError,
  } = useUserProfileQuery({
    onSuccess: (data) => {
      setRoleInfo({
        isCreator: data.role != "MEMBER",
        isBroadcaster:
          data.role != "MEMBER" ||
          (data.role == "MEMBER" && data.is_broadcaster),
      });
    },
  });

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <div
              onClick={() => {
                navigate(Path.profile);
              }}
            >
              <IoPersonOutline className="mr-1" /> {t("header.profile")}
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div
              onClick={() => {
                navigate(Path.subscription);
              }}
            >
              <IoBookmarkOutline className="mr-1" /> {t("header.subscription")}
            </div>
          ),
        },
        {
          key: "3",
          label: (
            <div onClick={logout} className="text-red-500">
              <IoLogOutOutline className="mr-1" /> {t("logout")}
            </div>
          ),
        },
      ]}
    />
  );
  const { category } = useParams<{ category: string }>();

  const onSearchPost = (value: string) => {
    let searchParam = "";
    if (value) {
      searchParam = `?keyword=${value}`;
    }
    if (category) {
      navigate(`/${Path.browse}/${category}${searchParam}`);
    } else {
      navigate(`/${Path.browse}/all${searchParam}`);
    }
  };

  return (
    <div className="h-[64px] relative z-10">
      <div className="h-full flex items-center justify-between px-4 space-x-5">
        <button
          tabIndex={-1}
          className="sm:hidden px-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
          onClick={toggleDrawer}
        >
          <IoMenuOutline className="text-3xl" />
        </button>
        <SearchBar onSearch={onSearchPost} />
        {profileData ? (
          <Dropdown overlay={menu} placement="bottom">
            <div className="flex items-center space-x-3">
              <Avatar
                icon={<IoPerson />}
                src={
                  !!profileData.photo.file && (
                    <Image
                      src={profileData.photo.file}
                      style={{ background: "white" }}
                      preview={false}
                    />
                  )
                }
                className="!bg-neutral-800"
              />
              <span>{profileData.fullname}</span>
              <span>
                <IoChevronDown className="text-green-500" />
              </span>
            </div>
          </Dropdown>
        ) : (
          <div className="mb-0 text-xl space-x-4">
            {/* <SecondaryButton
              onClick={() => {
                navigate(`/${Path.login}`);
              }}
            >
              Log In
            </SecondaryButton> */}
            <PrimaryButton
              onClick={() => {
                navigate(`/${Path.connect}`);
              }}
            >
              {t("header.loginsignup")}
            </PrimaryButton>
          </div>
        )}
      </div>

      <Drawer visible={show} onClose={toggleDrawer} width="80%">
        <div className="h-full flex flex-col">
          <div className="flex flex-1 flex-col space-y-4 justify-center">
            <SidebarItem
              path={Path.home}
              title={t("home")}
              icon={<IoHomeOutline className="text-xl" />}
              onClick={toggleDrawer}
              showText
              tabIndex={show ? 0 : -1}
            />

            <SidebarItem
              path={`${Path.browse}/all`}
              title={t("browse")}
              icon={<IoTvOutline className="text-xl" />}
              onClick={toggleDrawer}
              showText
              tabIndex={show ? 0 : -1}
            />

            <SidebarItem
              path={Path.aboutus}
              title={t("aboutus")}
              icon={<IoChatboxEllipsesOutline className="text-xl" />}
              onClick={toggleDrawer}
              showText
              tabIndex={show ? 0 : -1}
            />

            <SidebarItem
              path={`${Path.subscribe}/${Path.package}`}
              title={t("membership")}
              icon={<IoBookmarkOutline className="text-xl" />}
              onClick={toggleDrawer}
              showText
              tabIndex={show ? 0 : -1}
            />

            <SidebarItem
              path={Path.contactus}
              title={t("contactus")}
              icon={<IoSendOutline className="text-xl" />}
              onClick={toggleDrawer}
              showText
              tabIndex={show ? 0 : -1}
            />

            {isCreator && (
              <SidebarItem
                path={Path.content}
                title={t("content")}
                icon={<IoPlayCircleOutline className="text-xl" />}
                onClick={toggleDrawer}
                showText
                tabIndex={show ? 0 : -1}
              />
            )}
          </div>

          <div className="px-4 space-y-3 my-2">
            <div className="flex space-x-3 mb-3 justify-center">
              {organisationData?.social_media.map((social: any) => (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    window.open(social.social_media_link, "_blank");
                  }}
                >
                  <img
                    src={images.socialLogo[social.social_media as keyof any]}
                    width={28}
                  />
                </div>
              ))}
            </div>
            <div className="text-neutral-400 text-xs text-center">
              <Link
                className="text-neutral-400"
                to={`/${Path.info}/${Path.privacypolicy}`}
              >
                {t("privacypolicy")}
              </Link>{" "}
              |{" "}
              <Link
                className="text-neutral-400"
                to={`/${Path.info}/${Path.termofuse}`}
              >
                {t("termsofuse")}
              </Link>
            </div>
            <div className="text-neutral-500 text-xs text-center">
              © 2022 Balaena SoftTech Sdn Bhd
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

type SidebarItemProps = {
  title: string;
  icon: JSX.Element;
  path?: string;
  showText?: boolean;
} & HTMLAttributes<unknown>;

const SidebarItem = ({
  title,
  icon,
  path,
  onClick,
  showText = false,
  tabIndex,
}: SidebarItemProps) => {
  const { pathname } = useLocation();
  // QUESTION:: Is "-" appropriate?
  const { pathname: resolvePathname } = useResolvedPath(path || "-");
  const navigate = useNavigate();
  const { collapse } = useSidebarContext();

  const dynamicWidth = classNames({ "justify-center": collapse });
  const activeClass = pathname.startsWith(resolvePathname)
    ? "bg-green-600"
    : "text-neutral-400";

  const innerOnClick = useCallback(
    (e) => {
      if (onClick) onClick(e);
      if (path) navigate(path);
    },
    [path, onClick]
  );

  return (
    <div className="group p-2">
      <button
        className={`flex w-full items-center h-12 px-4 py-2 space-x-4 rounded-full cursor-pointer hover:bg-green-50 ${dynamicWidth} ${activeClass}`}
        onClick={innerOnClick}
        tabIndex={tabIndex}
      >
        <Tooltip title={title} placement="right">
          {icon}
        </Tooltip>
        {showText ? <p className="m-0 text-base">{title}</p> : null}
      </button>
    </div>
  );
};

export default Header;
