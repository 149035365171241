import { Checkbox, Col, Divider, Form, message, Modal, Row } from "antd";
import {
  IoArrowBackOutline,
  IoArrowForwardOutline,
  IoHomeOutline,
  IoKeyOutline,
  IoLogoDiscord,
  IoLogoGoogle,
} from "react-icons/io5";
import {
  Link,
  useLocation,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";

import images from "~/assets/images";
import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from "~/components/Button";
import { Input, TextAreaInput } from "~/components/Input";
import { DiscordOAuth, GoogleOAuth } from "~/components/OAuth";
import { DescriptionText, SubTitle, Title } from "~/components/Text";
import { useAuthContext } from "~/contexts/auth";
import { Path } from "~/enums";

import { useForgotPasswordMutation } from "./query";
import styles from "./styles.module.less";

const ForgotPasswordForm = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { setAuthInfo } = useAuthContext();
  const { isLoading, mutate, isError, error } = useForgotPasswordMutation({
    onSuccess: () => {
      Modal.success({
        title: "Email sent",
        content: "Please click the reset password link in your email.",
        centered: true,
        onOk() {
          navigate(`/${Path.login}`);
        },
      });
    },
    onError: (error: any) => {
      message.error("Failed to reset password. Please try again.");
    },
  });

  const onFinish = (values: any) => {
    mutate({
      ...values,
      redirect_url: `${window.location.origin}/${Path.resetpassword}`,
    });
  };

  const onOAuthSuccess = (data: any) => {
    const { access_token } = data;
    localStorage.setItem("access_token", access_token);
    setAuthInfo({ isAuthenticated: true, isVerifyingToken: false });
    navigate("/");
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      name="forgotpassword_form"
      layout="vertical"
      size="middle"
      requiredMark={false}
      className="w-full md:w-2/3"
    >
      <div>
        <Input
          name="email"
          label={"Email"}
          rules={[{ type: "email", message: "Invalid email format" }]}
          required
        />
      </div>

      <Divider dashed plain className="!border-neutral-700 !mt-12" />

      <div className="flex space-x-2 justify-end">
        <div>
          <PrimaryButton htmlType="submit" loading={isLoading}>
            Submit
          </PrimaryButton>
        </div>
      </div>
    </Form>
  );
};

export default ForgotPasswordForm;
