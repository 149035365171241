import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import {
  Badge,
  Button,
  Checkbox,
  Col,
  Descriptions,
  Form,
  List,
  message,
  Radio,
  Row,
  Spin,
  Switch,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  IoArrowBackOutline,
  IoArrowForwardOutline,
  IoCardOutline,
  IoCheckmark,
  IoCheckmarkCircle,
  IoCheckmarkCircleOutline,
  IoCheckmarkDone,
  IoHomeOutline,
  IoKeyOutline,
  IoWalletOutline,
} from "react-icons/io5";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";

import images from "~/assets/images";
import {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from "~/components/Button";
import { PaddedContainer } from "~/components/Container";
import {
  DescriptionText,
  SubTitle,
  Title,
  VideoSectionTitle,
} from "~/components/Text";
import { VideoInfo } from "~/components/VideoInfo";
import { Path } from "~/enums";
import { PackageInfoT } from "~/types";
import { backendPort } from "~/utils/axios";

import PaymentInfoForm from "./PaymentInfoForm";
import { useInitiatePaymentMutation } from "./query";
import styles from "./styles.module.less";

// const SelectedPackage = {
//   id: 3,
//   package_name: "Pro",
//   description:
//     "Pro account gives you freedom with uploading HD videos and can also meet all your business needs apasih kamu",
//   feature_list: [
//     "Upload Video with HD Resolution",
//     "Attachment & Post Scheduling",
//     "Set your rates",
//     "Exclusive Deals",
//   ],
//   price: 123,
// };

type SubscribePackageState = {
  selectedPackage: PackageInfoT;
};

const PaymentMethods = [
  {
    id: 5,
    bank: "RHB",
    last_4_digits: "9807",
    name_on_card: "John Doe",
    expiry: "06/22",
  },
  {
    id: 7,
    bank: "Maybank",
    last_4_digits: "9807",
    name_on_card: "John Doe",
    expiry: "06/22",
  },
];

const SubscriptionPackages = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const { selectedPackage } = (location.state as SubscribePackageState) || {};

  const navigate = useNavigate();
  const [selectedMethod, setSelectedMethod] = useState<any>(PaymentMethods[0]);
  const [addNewMethod, setAddNewMethod] = useState<boolean>(false);
  const [selectedPrice, setSelectedPrice] = useState<any>();
  const [isAutoDebit, setIsAutoDebit] = useState<boolean>(true);

  const onSelectMethod = (method: any) => {
    setSelectedMethod(method);
    setAddNewMethod(false);
  };

  const onAddNewMethod = () => {
    setSelectedMethod({});
    setAddNewMethod(true);
  };

  const onChangeDebit = (value: boolean) => {
    setIsAutoDebit(value);
  };

  const { isLoading, mutate } = useInitiatePaymentMutation({
    onSuccess: (data) => {
      if (data.url) {
        const newTab = window.open(data.url, "_blank");
        newTab?.focus();
      } else if (data.order_id) {
        navigate(`/${Path.subscribe}/${Path.payment}/${data.order_id}`, {
          state: { paymentDetails: data },
        });
      }
    },
    onError: (error: any) => {
      message.error("Failed to initiate payment. Please try again.");
    },
  });

  const onRegister = () => {
    if (!selectedPrice) {
      message.info("Please select a payment type");
      return;
    }

    let redirectInfo = {
      success_url: `${window.location.origin}/${Path.subscription}/success`,
      cancel_url: `${window.location.origin}/${Path.subscription}/failed`,
      is_subscription: isAutoDebit,
      payment_gateway:
        selectedPrice.currency_type == "crypto" ? "nowpayments" : "stripe",
    };

    mutate({
      id: selectedPrice.id,
      formData: redirectInfo,
    });
  };

  const renderPaymentOption = (price: any) => {
    if (price.currency_type == "fiat") {
      return (
        <Row gutter={12} align="middle">
          <Col xs={16}>
            <div
              onClick={() => {
                setSelectedPrice(price);
              }}
              className={
                "px-6 py-3 rounded-xl border flex justify-between items-center" +
                (selectedPrice?.id == price.id
                  ? " border-green-500"
                  : " border-neutral-700")
              }
            >
              <div className="flex flex-1">{t("payment.creditdebit")}</div>
              <div className="space-x-1">
                <img src={images.masterLogo} width={37} />
                <img src={images.visaLogo} width={37} />
              </div>
            </div>
          </Col>
          <Col xs={8}>
            <div className="font-semibold text-lg">
              {price.currency.toUpperCase()}
              {price.amount}
            </div>
            {/* <div className="text-xs text-gray-400">
              /
              {selectedPackage.expiry_duration > 1
                ? selectedPackage.expiry_duration + " months"
                : "month"}
            </div> */}
          </Col>
        </Row>
      );
    }

    if (price.currency_type == "crypto" && !isAutoDebit) {
      return (
        <Row gutter={12} align="middle">
          <Col xs={16}>
            <div
              onClick={() => {
                setSelectedPrice(price);
              }}
              className={
                "px-6 py-3 rounded-xl border flex justify-between items-center" +
                (selectedPrice?.id == price.id
                  ? " border-green-500"
                  : " border-neutral-700")
              }
            >
              <div className="flex flex-1">{t("payment.crypto")}</div>
              <div className="space-x-1">
                <img src={images.tetherLogo} width={26} />
              </div>
            </div>
          </Col>
          <Col xs={8}>
            <div className="font-semibold text-lg">
              USDT{price.amount} (TRC20)
            </div>
            {/* <div className="text-xs text-gray-400">
              /
              {selectedPackage.expiry_duration > 1
                ? selectedPackage.expiry_duration + " months"
                : "month"}
            </div> */}
          </Col>
        </Row>
      );
    }

    return null;
  };

  if (selectedPackage) {
    return (
      <div className="flex flex-col items-center space-y-8">
        <div className="text-center w-2/3">
          <Title>{t("payment.title")}</Title>
          <DescriptionText className="mt-3">
            {t("payment.subtitle")}
          </DescriptionText>
        </div>

        <div className="space-x-3">
          <span>{t("payment.onetime")}</span>
          <Switch checked={isAutoDebit} onChange={onChangeDebit} />
          <span>{t("payment.auto")}</span>
        </div>

        {!isAutoDebit &&
          selectedPackage.membership_tier.title == "VIP Tradveller" && (
            <Radio.Group defaultValue="1" className="text-center">
              <Radio.Button
                value="1"
                className="!p-0 !text-left"
                style={{ height: "unset" }}
              >
                <div className="p-3 pt-6">
                  <span>1 {t("payment.month")}</span>
                  <div className="text-base font-semibold">
                    {selectedPackage.subscribe_price.map((price) => (
                      <div>
                        {price.currency == "USDTTRC20"
                          ? "USDT" + price.amount + " (TRC20)"
                          : price.currency.toUpperCase() + price.amount}
                      </div>
                    ))}
                  </div>
                  <DescriptionText>{t("payment.permonth")}</DescriptionText>
                </div>
              </Radio.Button>
              <Radio.Button
                value="3"
                disabled
                className="!p-0 !text-left"
                style={{ height: "unset" }}
              >
                <Badge.Ribbon
                  text={"10% " + t("payment.off")}
                  color="red"
                  style={{ zIndex: 2 }}
                >
                  <div className="p-3 pt-6">
                    <span>3 {t("payment.month")}</span>
                    <span> ({t("subscription.comingsoon")})</span>
                    <span className="ml-2 text-green-500 font-semibold"></span>
                    <div className="text-base font-semibold">
                      {selectedPackage.subscribe_price.map((price) => (
                        <div>
                          {price.currency == "USDTTRC20"
                            ? "USDT" +
                              (parseInt(price.amount) * (1 - 10 / 100)).toFixed(
                                2
                              ) +
                              " (TRC20)"
                            : price.currency.toUpperCase() + price.amount}{" "}
                          {price.currency != "myr" && (
                            <span className="text-xs font-normal line-through text-neutral-500">
                              {price.amount}
                            </span>
                          )}
                        </div>
                      ))}
                      <DescriptionText>{t("payment.permonth")}</DescriptionText>
                    </div>
                  </div>
                </Badge.Ribbon>
              </Radio.Button>
              <Radio.Button
                value="6"
                disabled
                className="!p-0 !text-left"
                style={{ height: "unset" }}
              >
                <Badge.Ribbon
                  text={"20% " + t("payment.off")}
                  color="red"
                  style={{ zIndex: 2 }}
                >
                  <div className="p-3 pt-6">
                    <span>6 {t("payment.month")}</span>
                    <span> ({t("subscription.comingsoon")})</span>
                    <span className="ml-2 text-green-500 font-semibold"></span>
                    <div className="text-base font-semibold">
                      {selectedPackage.subscribe_price.map((price) => (
                        <div>
                          {price.currency == "USDTTRC20"
                            ? "USDT" +
                              (parseInt(price.amount) * (1 - 20 / 100)).toFixed(
                                2
                              ) +
                              " (TRC20)"
                            : price.currency.toUpperCase() + price.amount}{" "}
                          {price.currency != "myr" && (
                            <span className="text-xs font-normal line-through text-neutral-500">
                              {price.amount}
                            </span>
                          )}
                        </div>
                      ))}
                      <DescriptionText>{t("payment.permonth")}</DescriptionText>
                    </div>
                  </div>
                </Badge.Ribbon>
              </Radio.Button>
              <Radio.Button
                value="12"
                disabled
                className="!p-0 !text-left"
                style={{ height: "unset" }}
              >
                <Badge.Ribbon
                  text={"30% " + t("payment.off")}
                  color="red"
                  style={{ zIndex: 2 }}
                >
                  <div className="p-3 pt-6">
                    <span>12 {t("payment.month")}</span>
                    <span> ({t("subscription.comingsoon")})</span>
                    <span className="ml-2 text-green-500 font-semibold"></span>
                    <div className="text-base font-semibold">
                      {selectedPackage.subscribe_price.map((price) => (
                        <div>
                          {price.currency == "USDTTRC20"
                            ? "USDT" +
                              (parseInt(price.amount) * (1 - 30 / 100)).toFixed(
                                2
                              ) +
                              " (TRC20)"
                            : price.currency.toUpperCase() + price.amount}{" "}
                          {price.currency != "myr" && (
                            <span className="text-xs font-normal line-through text-neutral-500">
                              {price.amount}
                            </span>
                          )}
                        </div>
                      ))}
                      <DescriptionText>{t("payment.permonth")}</DescriptionText>
                    </div>
                  </div>
                </Badge.Ribbon>
              </Radio.Button>
            </Radio.Group>
          )}

        <Row gutter={[24, 24]} justify={"center"} className="w-full">
          <Col xs={24} md={6}>
            <div
              className={
                "rounded-xl p-5 space-y-3 bg-neutral-900 border-green-500 border"
              }
            >
              <div className="text-green-200 font-semibold">
                {selectedPackage.title}
              </div>
              <div className="space-y-1 flex-1">
                {selectedPackage.features?.map((item) => (
                  <div className="flex space-x-2">
                    <IoCheckmark className="mt-1" />{" "}
                    <div className="flex-1">{item.feature}</div>
                  </div>
                ))}
              </div>
            </div>
          </Col>
          <Col xs={24} md={16} className="space-y-4">
            {/* <div
            className={
              "rounded-xl space-y-3 bg-neutral-900 border-neutral-700 border"
            }
          >
            <div className="border-b border-neutral-700 px-6 py-4 space-x-3">
              <IoWalletOutline />
              <span>Registered payment method(s)</span>
            </div>
            <div className="px-6 divide-y divide-neutral-700">
              {PaymentMethods.map((method) => (
                <div
                  className="py-4"
                  onClick={() => {
                    onSelectMethod(method);
                  }}
                >
                  <Descriptions
                    colon={false}
                    layout="vertical"
                    column={5}
                    labelStyle={{
                      textAlign: "center",
                      fontSize: "0.7rem",
                      color: "rgb(86 86 86)",
                    }}
                    size="small"
                    contentStyle={
                      selectedMethod?.id === method.id
                        ? {}
                        : {
                            color: "rgb(135 135 135)",
                          }
                    }
                  >
                    <Descriptions.Item
                      label={
                        selectedMethod?.id === method.id ? (
                          <IoCheckmarkCircle className="text-lg text-green-500" />
                        ) : (
                          <IoCheckmarkCircleOutline className="text-lg" />
                        )
                      }
                    >
                      <IoCardOutline className="text-xl" />
                    </Descriptions.Item>
                    <Descriptions.Item label="Bank">
                      {method.bank}
                    </Descriptions.Item>
                    <Descriptions.Item label="Last 4 digits">
                      {method.last_4_digits}
                    </Descriptions.Item>
                    <Descriptions.Item label="Name on card">
                      {method.name_on_card}
                    </Descriptions.Item>
                    <Descriptions.Item label="Expires on">
                      {method.expiry}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              ))}
            </div>
          </div> */}

            <div
              className={
                "px-6 py-4 rounded-xl space-y-3 bg-neutral-900 border-neutral-700 border"
              }
              onClick={() => {
                onAddNewMethod();
              }}
            >
              <div className="space-x-3">
                {/* {addNewMethod ? (
                <IoCheckmarkCircle className="text-base text-green-500" />
              ) : (
                <IoCheckmarkCircleOutline className="text-base" />
              )} */}

                <span>{t("payment.selectpayment")}</span>
              </div>
              {/* {addNewMethod &&
              (selectedNewType ? (
                <PaymentInfoForm isLoading={false} type={selectedNewType} />
              ) : ( */}
              <div className="space-y-3 mx-5 !mb-6">
                {selectedPackage.subscribe_price.map((price) =>
                  renderPaymentOption(price)
                )}
              </div>
              {/* ))} */}
            </div>
            <div className="flex space-x-2 justify-between">
              <span className="text-sm">
                {t("payment.terms")}
                <Link
                  to={`/${Path.info}/${Path.termofuse}`}
                  className="mix-blend-difference"
                >
                  {t("termsofuse").toLowerCase()}
                </Link>
                .
              </span>
              <div className="flex items-center space-x-2">
                <SecondaryButton
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  {t("cancel")}
                </SecondaryButton>
                <PrimaryButton onClick={onRegister} loading={isLoading}>
                  {t("payment.checkout")}
                </PrimaryButton>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  return <Navigate to={`/${Path.subscribe}`} />;
};

export default SubscriptionPackages;
