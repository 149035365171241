import { Button, Carousel, Col, Collapse, Row } from "antd";
import { useTranslation } from "react-i18next";
import {
  IoArrowBack,
  IoArrowBackOutline,
  IoArrowForward,
  IoArrowForwardOutline,
  IoCheckmark,
  IoCheckmarkCircle,
  IoChevronDownCircle,
  IoChevronForward,
  IoChevronForwardCircle,
  IoHomeOutline,
  IoKeyOutline,
} from "react-icons/io5";
import {
  Link,
  useLocation,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";

import images from "~/assets/images";
import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from "~/components/Button";
import { PaddedContainer } from "~/components/Container";
import {
  DescriptionText,
  SubTitle,
  Title,
  VideoSectionTitle,
} from "~/components/Text";
import { VideoInfo } from "~/components/VideoInfo";
import { VideoRow } from "~/components/VideoRow";
import { useAuthContext } from "~/contexts/auth";
import { Path } from "~/enums";

import {
  useGetPackagesQuery,
  useGetPostQuery,
  useLiveSchedulesQuery,
} from "./query";
import styles from "./styles.module.less";

const FeatureList = [
  {
    icon: "sdf",
    title: "Upload & Organize",
    description:
      "Upload in bulk, organize content in categories, add custom filters & upload extras.",
  },
  {
    icon: "sdf",
    title: "Upload & Organize",
    description:
      "Upload in bulk, organize content in categories, add custom filters & upload extras.",
  },
  {
    icon: "sdf",
    title: "Upload & Organize",
    description:
      "Upload in bulk, organize content in categories, add custom filters & upload extras.",
  },
  {
    icon: "sdf",
    title: "Upload & Organize",
    description:
      "Upload in bulk, organize content in categories, add custom filters & upload extras.",
  },
  {
    icon: "sdf",
    title: "Upload & Organize",
    description:
      "Upload in bulk, organize content in categories, add custom filters & upload extras.",
  },
  {
    icon: "sdf",
    title: "Upload & Organize",
    description:
      "Upload in bulk, organize content in categories, add custom filters & upload extras.",
  },
];

const HomePage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { isAuthenticated, isVerifyingToken, setAuthInfo, logout } =
    useAuthContext();

  const { data, isLoading } = useGetPackagesQuery();

  const {
    data: trending,
    isLoading: isLoadingTrending,
    isError,
    refetch,
  } = useGetPostQuery("trending");
  const {
    data: newReleases,
    isLoading: isLoadingNewReleases,
    isError: isErrorNewRelease,
  } = useGetPostQuery("new-release");
  const {
    data: liveSchedule,
    isLoading: isLoadingLiveSchedule,
    isError: isErrorLiveSchedule,
  } = useLiveSchedulesQuery();

  const onChange = (currentSlide: any) => {
    console.log(currentSlide);
  };

  return (
    <PaddedContainer>
      {/*----- BANNER -----*/}
      <div className="relative px-12 pt-6 pb-8 overflow-hidden">
        <Row gutter={20} align="middle">
          <Col
            xs={{ order: 2, span: 24 }}
            md={{ order: 1, span: 10 }}
            className="mb-[140px]"
          >
            <Title>{t("home.banner.title")}</Title>
            <p className="mt-4">{t("home.banner.description")}</p>
          </Col>
          <Col
            xs={{ order: 1, span: 24 }}
            md={{ order: 2, span: 14 }}
            className="!flex justify-center mb-6 md:mb-0"
          >
            <img src={images.bannerLive} width={500} />
          </Col>
        </Row>
        <div className="absolute bottom-[100px] left-[50px]">
          <SecondaryButton
            onClick={() => {
              if (isAuthenticated) {
                navigate(`/${Path.subscribe}/${Path.package}`);
              } else {
                navigate(`/${Path.connect}`);
              }
            }}
          >
            {t("joinus")}
          </SecondaryButton>
        </div>
        <div className="absolute bottom-[-470px] left-[-120px] -z-10">
          <img src={images.blob1} width={600} />
        </div>
        <div className="absolute top-[-200px] right-[-150px] -z-10">
          <img src={images.blob2} width={600} />
        </div>
      </div>

      {/*----- LIVE NOW -----*/}
      <div className="mt-8">
        <div className="flex justify-between">
          <VideoSectionTitle>
            <span className="text-red-500 mr-2">•</span>
            {t("livenow")}
          </VideoSectionTitle>
          <TextButton
            onClick={() => {
              navigate(`/${Path.browse}/live`);
            }}
          >
            {t("seemore")} <IoChevronForward />
          </TextButton>
        </div>
        <VideoRow
          size="small"
          data={liveSchedule?.slice(0, 4)}
          isLoading={isLoadingLiveSchedule}
        />
      </div>

      {/*----- NEW RELEASE VIDEOS -----*/}
      <div className="mt-8">
        <div className="flex justify-between">
          <VideoSectionTitle>{t("newreleases")}</VideoSectionTitle>
          <TextButton
            onClick={() => {
              navigate(`/${Path.browse}/new-release`);
            }}
          >
            {t("seemore")} <IoChevronForward />
          </TextButton>
        </div>
        <VideoRow
          data={newReleases?.slice(0, 3)}
          isLoading={isLoadingNewReleases}
        />
      </div>

      {/*----- TOP WATCHED VIDEOS -----*/}
      <div className="mt-8">
        <div className="flex justify-between">
          <VideoSectionTitle>{t("trending")}</VideoSectionTitle>
          <TextButton
            onClick={() => {
              navigate(`/${Path.browse}/trending`);
            }}
          >
            {t("seemore")} <IoChevronForward />
          </TextButton>
        </div>
        <VideoRow data={trending?.slice(0, 3)} isLoading={isLoadingTrending} />
      </div>

      {/*----- PRICING -----*/}
      <div className="relative overflow-hidden mt-8 p-8">
        <Title>{t("aboutus.pricingplans")}</Title>
        <DescriptionText className="mt-2 my-3 mix-blend-multiply">
          {t("packages.subtitle")}
        </DescriptionText>
        <Link to={`/${Path.subscribe}/${Path.package}`}>
          <div className="flex items-center space-x-2">
            <div className="text-neutral-200">{t("aboutus.fullpricing")}</div>
            <IoArrowForwardOutline className="text-base text-green-500" />
          </div>
        </Link>

        <div className="flex justify-center mt-7">
          <Carousel
            className="w-[65vw]"
            afterChange={onChange}
            slidesToShow={Math.min(data?.length ?? 1, 4)}
            slidesToScroll={4}
            autoplay={true}
            arrows={true}
            nextArrow={<IoArrowForward />}
            prevArrow={<IoArrowBack />}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: Math.min(data?.length ?? 1, 3),
                  slidesToScroll: 3,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: Math.min(data?.length ?? 1, 2),
                  slidesToScroll: 2,
                },
              },
              {
                breakpoint: 640,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
          >
            {data?.map((membership, index) => (
              <div className="px-2 py-5">
                <div className="rounded-xl p-6 space-y-3 bg-neutral-800">
                  <div className="text-base text-green-200 font-semibold">
                    {membership.title}
                  </div>
                  <div className="text-xs text-gray-400">
                    {membership.description}
                  </div>
                  <div className="pt-2">
                    <div className="min-h-[50px] flex flex-col justify-end">
                      {membership.subscribe_price.map((price) => (
                        <div>
                          <span className="font-semibold text-base">
                            <div>
                              {price.currency == "USDTTRC20"
                                ? "USDT" + price.amount + " (TRC20)"
                                : price.currency.toUpperCase() + price.amount}
                            </div>
                          </span>
                        </div>
                      ))}
                    </div>
                    <span className="text-xs text-gray-400">
                      per{" "}
                      {membership.expiry_duration > 1
                        ? membership.expiry_duration + " months"
                        : "month"}
                    </span>
                  </div>
                  <div className="flex lg:block">
                    <PrimaryButton
                      onClick={() => {
                        navigate(
                          `/${Path.subscribe}/${Path.package}/${membership.id}`,
                          { state: { selectedPackage: membership } }
                        );
                      }}
                    >
                      {t("aboutus.subscribenow")}
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        <div className="rotate-180 scale-50 absolute bottom-[-300px] left-[-190px] -z-10">
          <img src={images.blob1} width={600} />
        </div>
        <div className="rotate-180 scale-50 md:scale-100 absolute top-[-390px] md:top-[-200px] right-[-150px] -z-10">
          <img src={images.blob1} width={600} />
        </div>
      </div>

      {/*----- FEATURES -----*/}
      {/* <div className="relative overflow-hidden mt-8 p-5">
        <Row justify="center" className="m-5">
          <div className="flex flex-col items-center">
            <Title>All-in-one Platform</Title>
            <p className="mt-2 max-w-md text-center">
              You take care of the video quality and we take care of everything
              else
            </p>
          </div>
        </Row>
        <div className="flex flex-col items-center">
          <Row gutter={[48, 32]} className="max-w-4xl">
            {FeatureList.map((feature) => (
              <Col xs={12} lg={8}>
                <div className="flex items-center space-x-2">
                  <IoKeyOutline className="text-base text-green-500" />
                  <SubTitle>{feature.title}</SubTitle>
                </div>
                <p className="mt-2 text-xs">{feature.description}</p>
              </Col>
            ))}
          </Row>
        </div>
        <div className="rotate-180 scale-50 absolute top-[-370px] left-[-300px] md:left-[-200px] -z-10">
          <img src={images.blob1} width={600} />
        </div>
        <div className="scale-50 absolute top-[-160px] right-[-345px] md:right-[-190px] -z-10">
          <img src={images.blob2} width={600} />
        </div>
      </div> */}

      {/*----- SUBSCRIBERS -----*/}
      {/* <div className="relative overflow-hidden mt-16 py-8 px-12 md:px-16 rounded-3xl border-green-500 border">
        <Row gutter={24} justify="center">
          <Col xs={24} lg={12} xl={10} className="my-8">
            <Title className="text-green-300">400k Subscribers</Title>
            <Title>Monetize your way</Title>
            <p className="mt-4 !mb-4">
              Set up subscriptions, rentals, or one-time buys for access to your
              VOD and live streams. Create exclusive experiences for your
              subscribers with coupons and promotions. One predictable fee,
              transparent pricing, and same-day setup.
            </p>
            <Link to={`/${Path.connect}`}>
              <div className="flex items-center space-x-2">
                <div className="text-neutral-200">Get Started</div>
                <IoArrowForwardOutline className="text-base text-green-500 !mix-blend-difference" />
              </div>
            </Link>
          </Col>
          <Col xs={24} lg={12} xl={10} className="!flex items-center">
            <img src={images.homeSubscriber} width={500} />
          </Col>
        </Row>
        <div className="scale-75 absolute bottom-[-80px] md:bottom-[-220px] left-[160px] -z-10">
          <img src={images.blob2} width={600} />
        </div>
      </div> */}
    </PaddedContainer>
  );
};

export default HomePage;
