import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      //GENERAL
      joinus: "Join Us",
      logout: "Log Out",
      loadingmore: "Loading More...",
      loadmore: "Load More",
      send: "Send",
      name: "Name",
      message: "Message",
      referralcode: "Referral Code",
      continuewithdiscord: "Continue with Discord",
      continuewithgoogle: "Continue with Google",
      cancel: "Cancel",
      edit: "Edit",
      savechanges: "Save Changes",
      replace: "Replace",
      delete: "Delete",
      done: "Done",
      upload: "Upload",
      backhome: "Back to Home",
      next: "Next",
      back: "Back",
      seemore: "See More",
      videos: "Videos",

      //BROWSE
      menu: "Menu",
      creatorstudio: "Creator Studio",
      livenow: "Live Now",
      newreleases: "New Releases",
      trending: "Trending",
      all: "All",
      comingsoon: "Coming Soon",

      //HEADER & SIDEBAR
      "header.profile": "My Profile",
      "header.subscription": "My Subscription",
      "header.loginsignup": "Log In / Sign Up",
      home: "Home",
      browse: "Browse",
      aboutus: "About Us",
      membership: "Membership",
      contactus: "Contact Us",
      content: "Content",
      privacypolicy: "Privacy Policy",
      termsofuse: "Terms of Use",
      searchvideos: "Search videos",

      //HOME PAGE
      "home.banner.title": "Reach more people in real time",
      "home.banner.description":
        "The Tradveller ecosystem is designed to help you generate profit. Set up complete sales and marketing funnels with ease using TheTradveller.",

      //ABOUT US
      "aboutus.featuredstreamers": "Featured Streamers",
      "aboutus.description":
        'The Tradveller is a mission and vision-oriented cryptocurrency teaching platform and community, dedicated to providing the most comprehensive and efficient teaching and information-sharing about cryptocurrency, helping members of the community to enhance their lives with cryptocurrency. “Providing guidance, pursuing excellence, and being close to our clients" is our motto, which drives us to excel in cryptocurrency trading with lessons and guidance of the highest quality.',
      "aboutus.vision": "Our Vision",
      "aboutus.vision.description":
        "To become the representative and most influential cryptocurrency teaching platform and community in Southeast Asia",
      "aboutus.mission": "Our Mission",
      "aboutus.mission.1":
        "With the ongoing development of cryptocurrencies, we provide latest news and updates and also comprehensive lessons about cryptocurrency trading",
      "aboutus.mission.2":
        "Help members turn their lives around with cryptocurrencies",
      "aboutus.mission.3": "Help traders obtain funds to increase profits",
      "aboutus.mission.4":
        "Help followers earn passive income through the auto-trading system",
      "aboutus.mission.5":
        "Provide appropriate training, incentives and opportunities for high-performing and contributing professionals",
      "aboutus.pricingplans": "Pricing Plans",
      "aboutus.howthingswork": "How Things Work",
      "aboutus.fullpricing": "Full Pricing Comparison",
      "aboutus.subscribenow": "Subscribe Now",

      //PACKAGES
      "packages.title": "Select a Plan That Works For You",
      "packages.subtitle":
        "We have several powerful plans to increase your crypto's knowledge and trading skill.",
      "packages.choose": "Choose",
      "packages.selectplan": "Select Plan",
      "packages.purchase": "Purchase",

      //CONTACT US
      "contactus.title": "Connect With Us",
      "contactus.contactinformation": "Contact Information",
      "contactus.socialmedialinks": "Social Media Links",
      "contactus.dropmessage": "Drop Us a Message",

      //CONNECT
      "connect.title": "Log In / Sign Up",
      "connect.login": "Log In",
      "connect.signup": "Sign Up",
      "connect.subtitle":
        "Hi there! To fully enjoy the benefits of our website, we require all users to sign in with their Discord account.",
      "connect.discordsignup": "Don't have a Discord account?",
      "connect.creatediscord": "Create one here",

      //PAYMENT
      "payment.title": "Select a Payment Method",
      "payment.subtitle": "Please select your preferred payment method.",
      "payment.onetime": "One-time payment",
      "payment.auto": "Auto-renewal",
      "payment.selectpayment": "Select payment method below",
      "payment.terms":
        "By clicking on 'Checkout', i hereby declare that i agree to the ",
      "payment.creditdebit": "Credit/debit card",
      "payment.crypto": "Cryptocurrency (USDT)",
      "payment.checkout": "Checkout",
      "payment.permonth": "per month",
      "payment.month": "month(s)",
      "payment.off": "off",

      //PROFILE
      profileimage: "Profile Image",
      fullname: "Full Name",
      email: "Email",
      country: "Country",
      phonenumber: "Phone Number",
      birthdate: "Birth Date",
      gender: "Gender",
      verifyemail: "Verify Email",
      verifyphone: "Verify Phone",
      "profile.changeemailphone": "Change Phone Number/Verify Email",
      accountsettings: "Account Settings",
      connectwithgoogle: "Connect with Google",
      "profile.transferyoutube": "Transfer your YouTube Member status",

      //SUBSCRIPTION
      "subscription.tier": "Membership Tier",
      "subscription.status": "Membership Status",
      "subscription.period": "Subscription Period",
      "subscription.renewaldate": "Subscription Renewal Date",
      "subscription.cancel": "Cancel Subscription",
      "subscription.invoices": "Invoices",
      "subscription.receipts": "Receipts",
      "subscription.referrals": "Referrals",
      "subscription.referralcode": "My Referral Code",
      "subscription.referrallink": "My Referral Link",
      "subscription.credits": "Referral Credit",
      "subscription.referreduser": "User(s) Referred",
      "subscription.availablewithdraw": "Available to withdraw",
      "subscription.earnings": "Lifetime earnings",
      "subscription.withdraw": "Withdraw",
      "subscription.comingsoon": "Coming Soon",

      //CONTENT
      "content.title": "My Videos",
      "content.upload": "Upload",
      "content.golive": "Go Live",
      "content.postdetails": "Post Details",
      title: "Title",
      description: "Description",
      video: "Video",
      thumbnail: "Thumbnail",
      audiences: "Audiences",
      user: "Created by User",
      publishvideo: "Publish video",
      preview: "Preview",
      statistics: "Statistics",
      "statistics.view": "View Count",
      "statistics.download": "Download Count",
      "statistics.comment": "Comment Count",
      "statistics.like": "Like Count",
      "statistics.dislike": "Dislike Count",
      "content.newpost": "Create New Post",
      "content.newlive": "New Live Stream",
      broadcaster: "Broadcaster",
      date: "Date",
      create: "Create",
      "content.livestreaminfo": "Live Stream Info",
      "content.livechat": "Live Chat",
      "content.watching": "Watching",
      "content.livechat.nochat": "No Chat Found",
      "content.livechat.prompt": "Say something...",

      //ERROR PAGE
      "403.message": "Sorry, you are not authorized to access this page.",
      "404.message": "Page Not Found",
      "500.message": "Server error 500",

      //CHANGE VERIFY
      "changeverify.checkedit": "Check/Edit Info",
      "changeverify.verifyemail": "Verify Email",
      "changeverify.verifyphone": "Verify Phone",
      "changeverify.checkedit.description":
        "Fill in your email and phone number",
      "changeverify.verifyemail.description":
        "Enter verification code sent to your email",
      "changeverify.verifyphone.description":
        "Enter OTP sent to your phone number",
      "changeverify.getcodeprompt":
        "Click on 'Get Code' to receive a verification code.",
      "changeverify.codesentto": "A 6-digit verification code will be sent to",
      "changeverify.entercode": "Please enter the code received below.",
      "changeverify.emailverificationcode": "Email Verification Code",
      "changeverify.phonenumberverificationcode":
        "Phone Number Verification Code",
      "changeverify.getcode": "Get Code",
      "changeverify.changemaildiscord":
        "If you would like to change your email address, please do so through Discord",

      //VIDEO
      "video.nodescription": "No description yet.",
      "video.comment": "Comment",
      "video.comments": "Comments",
      "video.comment.action": "Comment",
      "video.addcomment": "Add a Comment",
      "video.youmayalsolike": "You May Also Like",
      "video.like": "Like",
      "video.notfound": "Video not found.",
      "video.livechat": "Live Chat",
      "channel.notfound": "Channel not found.",
    },
  },
  cn: {
    translation: {
      //GENERAL
      joinus: "欢迎加入",
      logout: "登出",
      loadingmore: "加载中。。。",
      loadmore: "加载更多",
      send: "发送",
      name: "姓名",
      message: "信息内容",
      referralcode: "推荐码",
      continuewithdiscord: "使用 Discord 登入",
      continuewithgoogle: "使用 Google 登入",
      cancel: "取消",
      edit: "更改",
      savechanges: "保存更改",
      replace: "更换",
      delete: "删除",
      done: "完成",
      upload: "上载",
      backhome: "返回首页",
      next: "下一步",
      back: "返回",
      seemore: "观看更多",
      videos: "影片",

      //BROWSE
      menu: "菜单",
      creatorstudio: "创作者工作室",
      livenow: "直播",
      newreleases: "最新影片",
      trending: "热门影片",
      all: "全部",
      comingsoon: "即将更新",

      //HEADER & SIDEBAR
      "header.profile": "我的资料",
      "header.subscription": "我的会员",
      "header.loginsignup": "登入 / 注册",
      home: "主页",
      browse: "影片",
      aboutus: "关于我们",
      membership: "注册会员",
      contactus: "联系我们",
      content: "内容管理",
      privacypolicy: "隐私政策",
      termsofuse: "使用协议",
      searchvideos: "搜索影片",

      //HOME PAGE
      "home.banner.title": "了解加密货币，跟上现代趋势",
      "home.banner.description":
        "TheTradveller 由各领域专业人士提供有关加密货币的知识教学与新闻分享，并在社群内分享临时信息与个人观点",

      //ABOUT US
      "aboutus.featuredstreamers": "精选主播",
      "aboutus.description":
        'The Tradveller是一个以使命与愿景为导向的加密货币教学平台与社群，致力于提供最全面及最高效有关加密货币的教学与分享，帮助成员靠加密货币反转人生。"提供教学，追求卓越，贴近客户"是我们的宗旨，驱使我们以最高品质在加密货币教学中脱引而出。',
      "aboutus.vision": "我们的愿景",
      "aboutus.vision.description":
        "成为东南亚中文圈最具代表性与影响力的加密货币教学平台和社群",
      "aboutus.mission": "我们的使命",
      "aboutus.mission.1":
        "随着加密货币的发展不断提供最全面及最高效有关加密货币的知识教学与新闻分享",
      "aboutus.mission.2": "帮助成员靠加密货币翻转人生",
      "aboutus.mission.3": "帮助交易员获取资金加大盈利",
      "aboutus.mission.4": "帮助跟单者通过跟单系统赚取被动收入",
      "aboutus.mission.5":
        "提供适当的培训、奖励与发展机会给予表现优越和积极贡献的专业人士",
      "aboutus.pricingplans": "价格配套",
      "aboutus.howthingswork": "用户指南",
      "aboutus.fullpricing": "完整配套对比",
      "aboutus.subscribenow": "购买会员",

      //PACKAGES
      "packages.title": "选择适合您的配套",
      "packages.subtitle": "我们提供各种配套来增加您的加密知识与交易技能。",
      "packages.choose": "选择",
      "packages.selectplan": "选择配套",
      "packages.purchase": "购买",

      //CONTACT US
      "contactus.title": "联系我们",
      "contactus.contactinformation": "联系信息",
      "contactus.socialmedialinks": "社交媒体链接",
      "contactus.dropmessage": "给我们留言",

      //CONNECT
      "connect.title": "登入 / 注册",
      "connect.login": "登入",
      "connect.signup": "注册",
      "connect.subtitle":
        "您好！为了充分享受我们网站所提供的服务，我们要求所有用户使用 Discord 帐户登录。",
      "connect.discordsignup": "没有 Discord 帐户？",
      "connect.creatediscord": "请在这儿注册",

      //PAYMENT
      "payment.title": "选择付款方式",
      "payment.subtitle": "请选择您的首选付款方式。",
      "payment.onetime": "一次性支付",
      "payment.auto": "自动续订",
      "payment.selectpayment": "请在下方选择付款方式",
      "payment.terms": "通过点击“结帐”，我在此声明我同意",
      "payment.creditdebit": "信用卡/借记卡",
      "payment.crypto": "加密货币(USDT)",
      "payment.checkout": "结帐",
      "payment.permonth": "每月",
      "payment.month": "个月",
      "payment.off": "折扣",

      //PROFILE
      profileimage: "头像",
      fullname: "姓名",
      email: "电子邮件",
      country: "国家",
      phonenumber: "电话号码",
      birthdate: "出生日期",
      gender: "性别",
      verifyemail: "验证邮件地址",
      verifyphone: "验证电话号码",
      "profile.changeemailphone": "更改电话号码/验证电子邮件",
      accountsettings: "账户设置",
      connectwithgoogle: "使用 Google 登入",
      "profile.transferyoutube": "链接您的 YouTube 会员身份",

      //SUBSCRIPTION
      "subscription.tier": "会员等级",
      "subscription.status": "会员状态",
      "subscription.period": "会员有效期",
      "subscription.renewaldate": "会员更新日期",
      "subscription.cancel": "取消自动续费",
      "subscription.invoices": "发票",
      "subscription.receipts": "收据",
      "subscription.referrals": "推荐好友",
      "subscription.referralcode": "推荐码",
      "subscription.referrallink": "推荐链接",
      "subscription.credits": "余额",
      "subscription.referreduser": "已推荐好友",
      "subscription.availablewithdraw": "可提取",
      "subscription.earnings": "总共赚取",
      "subscription.withdraw": "提取",
      "subscription.comingsoon": "即将推出",

      //CONTENT
      "content.title": "我的视频",
      "content.upload": "上传影片",
      "content.golive": "现场直播",
      "content.postdetails": "视频详情",
      title: "标题",
      description: "描述",
      video: "影片",
      thumbnail: "缩略图",
      audiences: "观众",
      user: "上传用户",
      publishvideo: "开放视频",
      preview: "预览视频",
      statistics: "视频统计",
      "statistics.view": "查看次数",
      "statistics.download": "下载次数",
      "statistics.comment": "留言次数",
      "statistics.like": "点赞次数",
      "statistics.dislike": "不喜欢次数",
      "content.newpost": "创建新影片",
      "content.newlive": "新直播",
      broadcaster: "广播员",
      date: "日期",
      create: "创造",
      "content.livestreaminfo": "直播信息",
      "content.livechat": "在线聊天",
      "content.watching": "人观看",
      "content.livechat.nochat": "暂无评论",
      "content.livechat.prompt": "说些什么。。。",

      //ERROR PAGE
      "403.message": "抱歉，您无权浏览此页面。",
      "404.message": "无此页面",
      "500.message": "系统报错 500",

      //CHANGE VERIFY
      "changeverify.checkedit": "检查/更改信息",
      "changeverify.verifyemail": "验证邮件地址",
      "changeverify.verifyphone": "验证电话号码",
      "changeverify.checkedit.description": "填写您的电子邮件和电话号码",
      "changeverify.verifyemail.description": "输入发送到您邮箱的验证码",
      "changeverify.verifyphone.description": "输入发送到您电话号码的 OTP",
      "changeverify.getcodeprompt": "请点击“获取代码”以接收验证码。",
      "changeverify.codesentto": "6位验证码将发送至",
      "changeverify.entercode": "请输入收到的代码。",
      "changeverify.emailverificationcode": "电子邮件验证码",
      "changeverify.phonenumberverificationcode": "电话号码验证码",
      "changeverify.getcode": "获取代码",
      "changeverify.changemaildiscord": "若想更改电子邮件，请到 Discord 更改",

      //VIDEO
      "video.nodescription": "暂无描述",
      "video.comment": "评论",
      "video.comments": "评论",
      "video.comment.action": "评论",
      "video.addcomment": "添加评论",
      "video.youmayalsolike": "猜你喜欢",
      "video.like": "点赞",
      "video.notfound": "找不到视频。",
      "video.livechat": "在线聊天",
      "channel.notfound": "找不到频道。",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "cn", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: {
      cn: ["en"],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
