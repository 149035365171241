import { AxiosError } from "axios";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";

import { PackageInfoT } from "~/types";
import { appAxios, guestAxios, url } from "~/utils/axios";

const getPackagesQueryFn = () => {
  return guestAxios
    .get<PackageInfoT[]>(url.get_packages, {
      params: { page_size: 0 },
    })
    .then((response) => response.data);
};

export const useGetPackagesQuery = (
  options?: UseQueryOptions<
    PackageInfoT[],
    Error | AxiosError<{ detail: string }>
  >
) => {
  return useQuery<PackageInfoT[], Error | AxiosError<{ detail: string }>>(
    "packages",
    getPackagesQueryFn,
    options
  );
};

type SubscribeFreePackageInfo = { id?: number };

const subscribeFreePackageMutationFn = (
  subscribeFreePackageInfo: SubscribeFreePackageInfo
) => {
  const { id } = subscribeFreePackageInfo;

  return appAxios
    .post(`${url.subscribe_free}/${id}/subscribe/`)
    .then((response) => response.data);
};

export const useSubscribeFreePackageMutation = (
  options?: UseMutationOptions<any, AxiosError, SubscribeFreePackageInfo>
) => {
  return useMutation<any, AxiosError, SubscribeFreePackageInfo>(
    subscribeFreePackageMutationFn,
    options
  );
};
