import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "react-query";

import { PackageInfoT } from "~/types";
import { guestAxios, url } from "~/utils/axios";

// const getPackagesQueryFn = () => {
//   return guestAxios
//     .get<PackageInfoT[]>(url.get_packages, {
//       params: { page_size: 0 },
//     })
//     .then((response) => response.data);
// };

// export const useGetPackagesQuery = (
//   options?: UseQueryOptions<
//     PackageInfoT[],
//     Error | AxiosError<{ detail: string }>
//   >
// ) => {
//   return useQuery<PackageInfoT[], Error | AxiosError<{ detail: string }>>(
//     "packages",
//     getPackagesQueryFn,
//     options
//   );
// };

const getOrganisationDetailsQueryFn = () => {
  return guestAxios.get<any>(url.org_details).then((response) => response.data);
};

export const useGetOrganisationDetailsQuery = (
  options?: UseQueryOptions<any, Error | AxiosError<{ detail: string }>>
) => {
  return useQuery<any, Error | AxiosError<{ detail: string }>>(
    "org_details",
    getOrganisationDetailsQueryFn,
    options
  );
};
