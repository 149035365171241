import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

// TODO:: Serialize more queryString
// Specification please refer to: https://datatracker.ietf.org/doc/html/rfc3986
export const useQueryString = () => {
  const { search } = useLocation();

  const searchParams = useMemo(() => {
    let searchStore = <any>{};

    const urlParams = new URLSearchParams(search);

    urlParams.forEach((key, value) => {
      if (searchStore[value]) {
        searchStore[value] = [...searchStore[value], key];
      } else {
        searchStore[value] = key;
      }
    });

    return searchStore;
  }, [search]);

  return searchParams;
};
