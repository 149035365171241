import { AxiosError } from "axios";
import { QueryFunction, useQuery, UseQueryOptions } from "react-query";
import { useLocation, useSearchParams } from "react-router-dom";

import { UserProfileT } from "~/types";
import { appAxios, guestAxios, url } from "~/utils/axios";

const userQueryFn = () => {
  return appAxios.get(url.profile).then((response) => {
    console.log("header profile:", response);
    return response.data;
  });
};

// Get user profile
export const useUserProfileQuery = (
  options?: UseQueryOptions<UserProfileT>
) => {
  const token = localStorage.getItem("access_token");

  return useQuery<UserProfileT>(
    ["profile"],
    userQueryFn,
    options
      ? {
          ...options,
          // retry: false,
          staleTime: 300000,
          cacheTime: 300000,
          enabled: !!token,
        }
      : undefined
  );
};

const getOrganisationDetailsQueryFn = () => {
  return guestAxios.get<any>(url.org_details).then((response) => response.data);
};

export const useGetOrganisationDetailsQuery = (
  options?: UseQueryOptions<any, Error | AxiosError<{ detail: string }>>
) => {
  return useQuery<any, Error | AxiosError<{ detail: string }>>(
    "org_details",
    getOrganisationDetailsQueryFn,
    options
  );
};
