import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import {
  IoArrowBackOutline,
  IoArrowForwardOutline,
  IoHomeOutline,
  IoKeyOutline,
  IoPerson,
} from "react-icons/io5";
import { Link, Outlet } from "react-router-dom";

import images from "~/assets/images";
import { PrimaryButton, TextButton } from "~/components/Button";
import { PaddedContainer } from "~/components/Container";
import { DescriptionText, SubTitle, Title } from "~/components/Text";

import ResetPasswordForm from "./ResetPasswordForm";
import styles from "./styles.module.less";

const ResetPasswordPage = () => {
  return (
    <div className="relative overflow-hidden min-h-full">
      <PaddedContainer className="space-y-4">
        <Row gutter={[36, 36]}>
          <Col xs={24} md={10} className="space-y-4 md:!p-12">
            <Title>Set New Password</Title>
            <DescriptionText>Fill in your preferred password.</DescriptionText>
          </Col>
          <Col xs={24} md={14}>
            <ResetPasswordForm />
          </Col>
        </Row>
      </PaddedContainer>

      <div className="hidden md:block absolute bottom-[-40px] left-[-200px] -z-10">
        <img src={images.blob3} width={600} />
      </div>
    </div>
  );
};

export default ResetPasswordPage;
