import { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Image,
  message,
  Modal,
  Row,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { AiFillEdit } from "react-icons/ai";
import {
  IoAlertCircleOutline,
  IoArrowBackOutline,
  IoArrowForwardOutline,
  IoHomeOutline,
  IoImageOutline,
  IoInformationCircle,
  IoInformationCircleOutline,
  IoKeyOutline,
  IoLogoDiscord,
  IoLogoGoogle,
  IoPencilOutline,
  IoPerson,
} from "react-icons/io5";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";

import images from "~/assets/images";
import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from "~/components/Button";
import {
  HorizontalInput,
  Input,
  SelectInput,
  TextAreaInput,
} from "~/components/Input";
import { DiscordOAuth, GoogleOAuth } from "~/components/OAuth";
import { DescriptionText, SubTitle, Title } from "~/components/Text";
import { useAuthContext } from "~/contexts/auth";
import { Path } from "~/enums";
import { UserProfileT } from "~/types";
import { UserInformationFormField } from "~/types/form";
import { normFile } from "~/utils/functions";

import { useCountryListQuery, useUpdateProfileMutation } from "./query";
import styles from "./styles.module.less";

const GENDER_OPTIONS = [
  { name: "Male", value: "M" },
  { name: "Female", value: "F" },
];

type ProfileFormProps = {
  initialValue: UserProfileT | undefined;
};

const ProfileForm = ({ initialValue }: ProfileFormProps) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [profileImg, setProfileImg] = useState<any>();

  useEffect(() => {
    form.setFieldsValue({ country: initialValue?.country.code });
    initialValue?.birth_date &&
      form.setFieldsValue({ dob: moment(initialValue?.birth_date) });
    form.setFieldsValue({ gender: initialValue?.gender.code });
  }, [initialValue]);

  const { data, isLoading, isError, refetch } = useCountryListQuery();

  const { mutate, isLoading: isLoadingUpdateProfile } =
    useUpdateProfileMutation({
      onSuccess: (data: any) => {
        if (!data.is_phone_verified || !data.is_email_verified) {
          const { phone, phone_prefix, email } = data;
          goToVerify({ phone, phone_prefix, email });
        }

        form.resetFields(["photo"]);

        message.success("Profile updated succefully.");
      },
      onError: (error: any) => {
        message.error("Failed to update profile. Please try again.");
      },
    });

  const goToVerify = (info: any) => {
    navigate(Path.changeverifydetails, {
      state: { verifyInfo: info },
    });
  };

  const onFinish = (values: any) => {
    if (values.dob) {
      values.birth_date = values.dob.format("YYYY-MM-DD");
      delete values.dob;
    }

    const { photo, ...rest } = values;

    const formData = new FormData();

    if (photo && photo[0]) {
      formData.append("photo", photo[0].originFileObj as Blob);
    }

    for (const fields of Object.entries(rest)) {
      const [key, value] = fields;
      if (value) formData.append(key, value as any);
    }

    if (!initialValue?.is_sign_up_completed) {
      mutate({ formData, method: "PUT" });
    } else {
      mutate({ formData, method: "PATCH" });
    }
  };

  const onCountryChange = (value: string, option: any) => {
    !isPhoneRegistered() &&
      form.setFieldsValue({ phone_prefix: option.object.phone_code });
  };

  const onChangePhoneNumber = () => {
    goToVerify({ phone_prefix: "", phone: "" });
  };

  const onChangeVerify = () => {
    goToVerify(form.getFieldsValue(["phone_prefix", "phone", "email"]));
  };

  const onChangePassword = () => {
    navigate(Path.changepassword);
  };

  const isPhoneRegistered = () => {
    return !!initialValue?.phone;
  };

  const isEmailRegistered = () => {
    return !!initialValue?.email;
  };

  const onVerifyPhone = () => {
    const { phone, phone_prefix } = initialValue || {};
    goToVerify({ phone, phone_prefix });
  };

  const onChangeThumbnail = (value: any) => {
    let profileImgUrl = URL.createObjectURL(value.file);
    setProfileImg(profileImgUrl);
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      name="userinformation_form"
      size="middle"
      requiredMark={false}
      initialValues={initialValue || undefined}
      className="w-2/3 space-y-8 !my-5"
      labelCol={{
        xs: { span: 24 },
        sm: { span: 12 },
      }}
      wrapperCol={{
        xs: { span: 24 },
        sm: { span: 12 },
      }}
      colon={false}
    >
      <Form.Item
        name="photo"
        label={
          <div>
            <DescriptionText>{t("profileimage")}</DescriptionText>
          </div>
        }
        labelAlign="left"
        getValueFromEvent={normFile}
      >
        <Upload
          beforeUpload={() => false}
          maxCount={1}
          accept="image/*"
          listType="picture"
          // onChange={onChangeThumbnail}
        >
          <div className="flex space-x-3 items-center">
            <Avatar
              size="large"
              icon={<IoPerson />}
              src={
                initialValue?.photo.file && (
                  <Image
                    draggable={false}
                    src={initialValue?.photo.file}
                    style={{
                      width: 44,
                      height: 44,
                      objectFit: "contain",
                      background: "white",
                    }}
                    preview={false}
                  />
                )
              }
            />
            <div>
              {t("edit")} <AiFillEdit />
            </div>
          </div>
        </Upload>
      </Form.Item>

      <HorizontalInput name="fullname" label={t("fullname")} required />

      <HorizontalInput
        name="email"
        label={
          <div>
            {t("email")}
            {!initialValue?.is_email_verified &&
              initialValue?.email &&
              initialValue?.is_sign_up_completed && (
                <TextButton onClick={onChangeVerify}>
                  <IoAlertCircleOutline /> {t("verifyemail")}
                </TextButton>
              )}
          </div>
        }
        rules={[{ type: "email", message: "Invalid email format" }]}
        disabled={isEmailRegistered()}
        suffix={
          <Tooltip title={t("changeverify.changemaildiscord")}>
            <IoInformationCircleOutline className="text-green-500 text-lg" />
          </Tooltip>
        }
        required
      />

      <SelectInput
        name="country"
        label={t("country")}
        options={data}
        valueKey="code"
        onChange={onCountryChange}
        optionFilterProp="children"
        required
        showSearch
      />

      <div>
        <HorizontalInput
          name="phone"
          label={
            <div>
              {t("phonenumber")}
              {!initialValue?.is_phone_verified &&
                initialValue?.phone &&
                initialValue?.is_sign_up_completed && (
                  <TextButton onClick={onChangeVerify}>
                    <IoAlertCircleOutline /> {t("verifyphone")}
                  </TextButton>
                )}
            </div>
          }
          addonBefore={
            <SelectInput
              name="phone_prefix"
              label="Phone Code"
              options={data}
              valueKey="phone_code"
              nameKey="phone_code"
              optionFilterProp="children"
              disabled={isPhoneRegistered()}
              required
              noStyle
              showSearch
            />
          }
          rules={[
            {
              pattern: /\d{8,15}$/g,
              message:
                "Invalid format, phone number must be between 11 to 18 characters, only numbers allowed",
            },
          ]}
          disabled={isPhoneRegistered()}
          required
        />
      </div>

      <HorizontalInput name="dob" label={t("birthdate")} type="date" />

      <SelectInput name="gender" label={t("gender")} options={GENDER_OPTIONS} />

      <div className="space-y-3 xl:space-y-0 xl:space-x-3 flex flex-col xl:flex-row xl:justify-end">
        {initialValue?.is_sign_up_completed &&
          (isEmailRegistered() || isPhoneRegistered()) && (
            <div>
              <SecondaryButton onClick={onChangeVerify}>
                {t("profile.changeemailphone")}
              </SecondaryButton>
            </div>
          )}
        {/* <div>
          <SecondaryButton onClick={onChangePassword}>
            Change Password
          </SecondaryButton>
        </div> */}
        <div>
          <PrimaryButton htmlType="submit" loading={isLoadingUpdateProfile}>
            {t("savechanges")}
          </PrimaryButton>
        </div>
      </div>
    </Form>
  );
};

export default ProfileForm;
