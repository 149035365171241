import { useCallback, useMemo, useState } from "react";
import { Button, Col, Modal, Row, Switch } from "antd";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import {
  IoArrowBackOutline,
  IoArrowForwardOutline,
  IoCheckmark,
  IoCheckmarkDone,
  IoHomeOutline,
  IoKeyOutline,
} from "react-icons/io5";
import {
  Link,
  useLocation,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";

import images from "~/assets/images";
import { PrimaryButton, TertiaryButton } from "~/components/Button";
import { PaddedContainer } from "~/components/Container";
import {
  DescriptionText,
  SubTitle,
  Title,
  VideoSectionTitle,
} from "~/components/Text";
import { VideoInfo } from "~/components/VideoInfo";
import { useAuthContext } from "~/contexts/auth";
import { Path } from "~/enums";
import { PackageInfoT } from "~/types";

import { useGetPackagesQuery } from "./query";
import { useSubscribeFreePackageMutation } from "./query";
import styles from "./styles.module.less";

// const PackageList = [
//   {
//     id: 1,
//     package_name: "Intro",
//     description:
//       "Pro account gives you freedom with uploading HD videos and can also meet all your business needs apasih kamu",
//     feature_list: ["Upload Video up to 720p Resolution"],
//     price: 123,
//   },
//   {
//     id: 2,
//     package_name: "Basic",
//     description:
//       "Pro account gives you freedom with uploading HD videos and can also meet all your business needs apasih kamu",
//     feature_list: [
//       "Upload Video with HD Resolution",
//       "Attachment & Post Scheduling",
//     ],
//     price: 123,
//   },
//   {
//     id: 3,
//     package_name: "Pro",
//     description:
//       "Pro account gives you freedom with uploading HD videos and can also meet all your business needs apasih kamu",
//     feature_list: [
//       "Upload Video with HD Resolution",
//       "Attachment & Post Scheduling",
//       "Set your rates",
//       "Exclusive Deals",
//     ],
//     price: 123,
//   },
// ];

const SubscriptionPackages = () => {
  const { t, i18n } = useTranslation();
  const { isAuthenticated } = useAuthContext();
  const navigate = useNavigate();
  const [selectedPackage, setSelectedPackage] = useState<PackageInfoT>();
  const { data, isLoading, isError, refetch } = useGetPackagesQuery();

  const { isLoading: isSubscribing, mutate: subscribeFree } =
    useSubscribeFreePackageMutation({
      onSuccess: (data) => {
        let subscribeMessage =
          "You have successfully subscribed to the package." +
          (data.valid_until
            ? " The package will " +
              (data.auto_renew ? "auto-renew" : "expire") +
              " on " +
              data.valid_until
            : "");
        Modal.success({
          centered: true,
          title: "Package Subscription Successful",
          content: subscribeMessage,
          onOk() {
            navigate(`/${Path.subscription}`);
          },
        });
      },
      onError: (data) => {
        Modal.error({
          centered: true,
          title: "Package Subscription Failed",
          content: "Please try again.",
          onOk() {},
        });
      },
    });

  const onClickPackage = (membership: PackageInfoT) => {
    setSelectedPackage(membership);
  };

  const onClickSelectPlan = (membership: PackageInfoT) => {
    if (!isAuthenticated) {
      Modal.info({
        centered: true,
        title: "Login Required",
        content: "Please login before subscribing to a package.",
        onOk() {
          navigate(`/${Path.connect}`);
        },
      });
      return;
    }

    if (parseFloat(membership.subscribe_price[0].amount) === 0) {
      subscribeFree({ id: membership.id });
      return;
    }

    navigate(`${membership.id}`, { state: { selectedPackage: membership } });
  };
  return (
    <div className="flex flex-col items-center space-y-8">
      <div className="text-center w-2/3">
        <Title>{t("packages.title")}</Title>
        <DescriptionText className="mt-3">
          {t("packages.subtitle")}
        </DescriptionText>
      </div>

      <Row gutter={[24, 24]} justify={"center"} className="w-full">
        {data?.map((membership) => (
          <Col xs={24} sm={12} md={8} xl={6}>
            <div
              className={
                "flex flex-col h-full rounded-xl p-6 space-y-3 " +
                (selectedPackage?.id == membership.id
                  ? "bg-neutral-900 border-green-500 border -translate-y-3 duration-200"
                  : "bg-neutral-800")
              }
              onClick={() => {
                onClickPackage(membership);
              }}
            >
              <div className="text-base text-green-200 font-semibold">
                {membership.title}
              </div>
              <div className="text-xs text-gray-400">
                {membership.description}
              </div>
              <div className="space-y-1 flex-1">
                {membership.features?.map((item) => (
                  <div className="flex space-x-2">
                    <IoCheckmark className="mt-1" />{" "}
                    <div className="text-sm flex-1">{item.feature}</div>
                  </div>
                ))}
              </div>
              <div className="space-y-1 pt-2">
                <div>
                  {membership.subscribe_price.map((price) => (
                    <div>
                      <span className="font-semibold text-lg">
                        {price.currency == "USDTTRC20"
                          ? "USDT" + price.amount + " (TRC20)"
                          : price.currency.toUpperCase() + price.amount}
                      </span>
                    </div>
                  ))}
                  <span className="text-xs text-gray-400">
                    per{" "}
                    {membership.expiry_duration > 1
                      ? membership.expiry_duration + " months"
                      : "month"}
                  </span>
                </div>
              </div>
              <div className="flex">
                {selectedPackage?.id == membership.id ? (
                  <PrimaryButton
                    onClick={() => {
                      onClickSelectPlan(membership);
                    }}
                    loading={isSubscribing}
                  >
                    {t("packages.selectplan")}
                  </PrimaryButton>
                ) : (
                  <TertiaryButton>{t("packages.choose")}</TertiaryButton>
                )}
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default SubscriptionPackages;
