import { Spin } from "antd";

const OAuthRedirect = () => {
  return (
    <div className="w-full h-full flex justify-center items-center space-x-3">
      <Spin />
      <span>Please wait, redirecting...</span>
    </div>
  );
};

export default OAuthRedirect;
