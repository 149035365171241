import { AxiosError } from "axios";
import { useInfiniteQuery, useQuery, UseQueryOptions } from "react-query";

import { VideoInfoT } from "~/types";
import { appAxios, guestAxios, url } from "~/utils/axios";

type GetPostResponse = {
  results: VideoInfoT[];
  next: string;
};

const getPostQueryFn = (context: any) => {
  const [_, search] = context.queryKey;
  const { pageParam: nextPageUrl } = context;

  return appAxios
    .get<GetPostResponse>(nextPageUrl ?? url.get_posts_admin, {
      params: nextPageUrl ? {} : { search },
    })
    .then((response) => response.data);
};

export const useGetPostInfiniteQuery = (
  publish_status?: string,
  search?: string,
  options?: any
) => {
  return useInfiniteQuery<
    GetPostResponse,
    Error | AxiosError<{ detail: string }>
  >(["content", publish_status, search], getPostQueryFn, {
    ...options,
    getNextPageParam: (lastPage, pages) => {
      return lastPage.next;
    },
  });
};
