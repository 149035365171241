import { useEffect, useLayoutEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Image,
  message,
  Modal,
  Row,
  Upload,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  IoArrowBackOutline,
  IoArrowForwardOutline,
  IoCloudCircle,
  IoHomeOutline,
  IoImageOutline,
  IoKeyOutline,
  IoLogoDiscord,
  IoLogoGoogle,
  IoTrashBin,
} from "react-icons/io5";
import ReactPlayer from "react-player";
import { useQueryClient } from "react-query";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useResolvedPath,
} from "react-router-dom";

import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from "~/components/Button";
import { PaddedContainer } from "~/components/Container";
import { Editor } from "~/components/Editor";
import { Input, SelectInput, TextAreaInput } from "~/components/Input";
import { DiscordOAuth, GoogleOAuth } from "~/components/OAuth";
import { DescriptionText, SubTitle, Title } from "~/components/Text";
import { VideoPlayer } from "~/components/VideoPlayer";
import { useAuthContext } from "~/contexts/auth";
import { Path } from "~/enums";
import { useGetPostStreamQuery } from "~/pages/video/query";
import { UserInformationFormField } from "~/types/form";
import { guestAxios, url } from "~/utils/axios";
import { normFile } from "~/utils/functions";

import {
  useDeletePostMutation,
  useGetAudiencesQuery,
  useGetPostDetailsQuery,
  useUpdatePostMutation,
} from "./query";
import styles from "./styles.module.less";

const GENDER_OPTIONS = [
  { name: "Male", value: "M" },
  { name: "Female", value: "F" },
];

const EditPost = () => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { setAuthInfo } = useAuthContext();
  const queryClient = useQueryClient();

  const [thumbnail, setThumbnail] = useState<any>();
  const [video, setVideo] = useState<any>();

  const { id } = useParams<{ id: string }>();

  const {
    data: audiences,
    isLoading: isLoadingAudiences,
    isError: isErrorAudiences,
  } = useGetAudiencesQuery();

  const {
    data,
    isLoading: isLoadingDetails,
    isError,
    refetch,
  } = useGetPostDetailsQuery(id!, {
    onSuccess: (data: any) => {
      let audienceList = data.audiences;
      data.audiences = audienceList.map((audience: any) => audience.id);
      form.setFieldsValue(data);
      setThumbnail(data?.thumbnail?.file);
    },
    onError: (error: any) => {
      if (error.response.status == 404) {
        Modal.error({
          centered: true,
          title: "Post Not Found",
          content: "Please try again.",
          onOk() {
            navigate(`/${Path.content}`);
          },
        });
      } else {
        message.error("Failed to retrieve post details. Please try again");
      }
    },
  });

  const {
    data: postStream,
    isLoading: isLoadingPostStream,
    isError: isErrorPostStream,
    refetch: refetchPostStream,
  } = useGetPostStreamQuery(id!);

  const { isLoading, mutate } = useUpdatePostMutation({
    onSuccess: () => {
      form.resetFields();
      message.success("Post updated. Changes saved successfully.");
    },
    onError: () => {
      message.error("Failed to save changes. Please try again.");
    },
    onSettled: () => {
      queryClient.invalidateQueries(["post_details", id]);
    },
  });

  const { isLoading: isLoadingDelete, mutate: deletePost } =
    useDeletePostMutation({
      onSuccess: () => {
        Modal.success({
          centered: true,
          title: "Delete success",
          content: "Post deleted successfully.",
          onOk() {
            navigate(`/${Path.content}`);
          },
        });
      },
      onError: () => {
        message.error("Failed to delete post. Please try again.");
      },
    });

  const onOAuthSuccess = (data: any) => {
    localStorage.setItem("access_token", data.access_token);
    setAuthInfo({ isAuthenticated: true, isVerifyingToken: false });
    navigate("/");
  };

  // const { data, isLoading, isError, refetch } = useCountryListQuery();

  // const { mutate, isLoading: isLoadingSignUp } = useSignUpMutation({
  //   onSuccess: () => {
  //     navigate(`/signup/verification`);
  //   },
  // });

  const onFinish = (values: any) => {
    const { new_thumbnail, audiences, ...rest } = values;

    const formData = new FormData();

    if (new_thumbnail && new_thumbnail[0]) {
      formData.append("thumbnails", new_thumbnail[0].originFileObj as Blob);
    }

    if (form.isFieldTouched("audiences") && audiences) {
      audiences.map((audience: any, index: any) => {
        formData.append("audiences", audience);
      });
    }

    for (const fields of Object.entries(rest)) {
      const [key, value] = fields;
      if (value) formData.append(key, value as any);
    }

    mutate({ id, formData });

    // navigate(`/signup/verification`, { state: { signUpInfo: values } });
  };

  // const onCountryChange = (value: string, option: any) => {
  //   form.setFieldsValue({ phone_prefix: option.object.phone_code });
  // };

  const onChangeThumbnail = (value: any) => {
    let thumbnailUrl = URL.createObjectURL(value.file);
    setThumbnail(thumbnailUrl);
  };

  const onDelete = () => {
    Modal.confirm({
      title: "Confirm delete?",
      icon: <IoTrashBin />,
      content: "This post will be permanently deleted.",
      onOk() {
        deletePost({ id });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <PaddedContainer>
      <SubTitle>{t("content.postdetails")}</SubTitle>
      <Form
        onFinish={onFinish}
        form={form}
        name="userinformation_form"
        layout="vertical"
        size="middle"
        requiredMark={false}
        className="space-y-8 !mt-5"
        initialValues={data}
      >
        <Row gutter={36}>
          <Col
            xs={{ order: 2, span: 24 }}
            md={{ order: 1, span: 12 }}
            className="w-full"
          >
            {/* <input type="file" name="fileName"></input> */}
            <Input name="title" label={t("title")} required />

            <Form.Item
              name="description"
              label={<DescriptionText>{t("description")}</DescriptionText>}
            >
              <Editor />
            </Form.Item>

            <div>
              <DescriptionText className="mb-2">
                {t("thumbnail")}
              </DescriptionText>
              <Image src={data?.thumbnail?.file} className="mb-2" width={160} />
            </div>

            <Form.Item
              className="!w-[300px]"
              name="new_thumbnail"
              getValueFromEvent={normFile}
            >
              <Upload
                beforeUpload={() => false}
                maxCount={1}
                accept="image/*"
                listType="picture"
                onChange={onChangeThumbnail}
              >
                <Button icon={<IoImageOutline />}>{t("replace")}</Button>
              </Upload>
            </Form.Item>

            <SelectInput
              name="audiences"
              label={t("audiences")}
              options={audiences}
              nameKey="title"
              valueKey="id"
              mode="multiple"
              required
            />

            <Form.Item
              name="is_visible"
              valuePropName="checked"
              initialValue={true}
            >
              <Checkbox>
                <span className="text-sm">{t("publishvideo")}</span>
              </Checkbox>
            </Form.Item>
          </Col>
          <Col xs={{ order: 1, span: 24 }} md={{ order: 2, span: 12 }}>
            <DescriptionText className="mb-3">{t("preview")}</DescriptionText>
            <div className="w-full mb-4">
              <VideoPlayer
                video_url={postStream?.access_url}
                thumbnail_url={thumbnail ?? true}
                user_email=""
              />
            </div>
            <div className="mt-7">
              <DescriptionText>{t("statistics")}</DescriptionText>
              <Descriptions size="small" column={1} className="my-3">
                <Descriptions.Item label={t("statistics.view")}>
                  {data?.statistic?.view_count ?? ""}
                </Descriptions.Item>
                <Descriptions.Item label={t("statistics.download")}>
                  {data?.statistic?.download_count ?? ""}
                </Descriptions.Item>
                <Descriptions.Item label={t("statistics.comment")}>
                  {data?.statistic?.comment_count ?? ""}
                </Descriptions.Item>
                <Descriptions.Item label={t("statistics.like")}>
                  {data?.statistic?.like_count ?? ""}
                </Descriptions.Item>
                <Descriptions.Item label={t("statistics.dislike")}>
                  {data?.statistic?.dislike_count ?? ""}
                </Descriptions.Item>
              </Descriptions>
            </div>
          </Col>
        </Row>
        <div className="space-y-3 md:space-y-0 md:space-x-3 flex flex-col md:flex-row md:justify-between">
          <div>
            <SecondaryButton
              onClick={onDelete}
              loading={isLoadingDelete}
              danger
            >
              {t("delete")}
            </SecondaryButton>
          </div>
          <div className="space-y-3 md:space-y-0 md:space-x-3 flex flex-col md:flex-row md:justify-end">
            <div>
              {" "}
              <SecondaryButton
                onClick={() => {
                  navigate(-1);
                }}
              >
                {t("done")}
              </SecondaryButton>
            </div>
            <div>
              <PrimaryButton htmlType="submit" loading={isLoading}>
                {t("savechanges")}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </Form>
    </PaddedContainer>
  );
};

export default EditPost;
