import React, { useEffect, useState } from "react";
import { Button, Col, Radio, Row } from "antd";
import { useTranslation } from "react-i18next";
import {
  IoArrowBackOutline,
  IoArrowForwardOutline,
  IoChevronForward,
  IoHomeOutline,
  IoKeyOutline,
} from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";

import images from "~/assets/images";
import { RadioButton, TextButton } from "~/components/Button";
import { SecondaryButton } from "~/components/Button";
import { PaddedContainer } from "~/components/Container";
import { SubTitle, Title, VideoSectionTitle } from "~/components/Text";
import { VideoInfo } from "~/components/VideoInfo";
import { VideoRow } from "~/components/VideoRow";
import { Path } from "~/enums";
import { VideoInfoT } from "~/types";
import { useQueryString } from "~/utils/hooks";

import {
  useGetPostInfiniteQuery,
  useLiveSchedulesInfiniteQuery,
} from "./query";
import styles from "./styles.module.less";

const BrowsePage = () => {
  const { t, i18n } = useTranslation();
  const queryString = useQueryString();
  const navigate = useNavigate();
  const { category } = useParams<{ category: string }>();

  const { keyword } = queryString;

  return (
    <PaddedContainer>
      {/*----- FILTER TAB -----*/}
      <Radio.Group
        value={category}
        onChange={(e) => {
          if (keyword) {
            navigate(`/${Path.browse}/${e.target.value}?keyword=${keyword}`);
          } else {
            navigate(`/${Path.browse}/${e.target.value}`);
          }
        }}
      >
        <RadioButton value="all">{t("all")}</RadioButton>
        <RadioButton value="live">{t("livenow")}</RadioButton>
        <RadioButton value="trending">{t("trending")}</RadioButton>
        <RadioButton value="new-release">{t("newreleases")}</RadioButton>
        <RadioButton value="coming-soon">{t("comingsoon")}</RadioButton>
      </Radio.Group>

      {keyword && (
        <div className="mt-4">
          <SubTitle>Searching for "{keyword}"</SubTitle>
        </div>
      )}

      {category == "all" ? (
        <AllCategoriesSection search={keyword} />
      ) : category == "live" ? (
        <LiveCategorySection search={keyword} />
      ) : (
        <SingleCategorySection search={keyword} category={category ?? ""} />
      )}
    </PaddedContainer>
  );
};

type BrowseSectionProps = {
  search: string;
};

const AllCategoriesSection = ({ search }: BrowseSectionProps) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {
    data: trending,
    isLoading: isLoadingTrending,
    isError: isErrorTrending,
  } = useGetPostInfiniteQuery("trending", search);
  const {
    data: newRelease,
    isLoading: isLoadingNewRelease,
    isError: isErrorNewRelease,
  } = useGetPostInfiniteQuery("new-release", search);
  const {
    data: comingSoon,
    isLoading: isLoadingComingSoon,
    isError: isErrorComingSoon,
  } = useGetPostInfiniteQuery("coming-soon", search);
  const {
    data: liveSchedule,
    isLoading: isLoadingLiveSchedule,
    isError: isErrorLiveSchedule,
  } = useLiveSchedulesInfiniteQuery(search);

  const { pages: trendingPage } = (trending as any) || {
    pages: [],
  };
  const { pages: newReleasePage } = (newRelease as any) || {
    pages: [],
  };
  const { pages: comingSoonPage } = (comingSoon as any) || {
    pages: [],
  };
  const { pages: liveSchedulePage } = (liveSchedule as any) || {
    pages: [],
  };

  return (
    <div>
      {/*----- LIVE NOW -----*/}
      <div className="mt-8">
        <div className="flex justify-between">
          <VideoSectionTitle>
            <span className="text-red-500 mr-2">•</span>
            {t("livenow")}
          </VideoSectionTitle>
          <TextButton
            onClick={() => {
              navigate(`/${Path.browse}/live`);
            }}
          >
            {t("seemore")} <IoChevronForward />
          </TextButton>
        </div>
        <VideoRow
          size="small"
          data={liveSchedulePage && liveSchedulePage[0]?.results.slice(0, 8)}
          isLoading={isLoadingLiveSchedule}
        />
      </div>
      {/*----- TRENDING -----*/}
      <div className="mt-8">
        <div className="flex justify-between">
          <VideoSectionTitle>{t("trending")}</VideoSectionTitle>
          <TextButton
            onClick={() => {
              navigate(`/${Path.browse}/trending`);
            }}
          >
            {t("seemore")} <IoChevronForward />
          </TextButton>
        </div>
        <VideoRow
          size="small"
          data={trendingPage && trendingPage[0]?.results.slice(0, 8)}
          isLoading={isLoadingTrending}
        />
      </div>
      {/*----- NEW RELEASES -----*/}
      <div className="mt-8">
        <div className="flex justify-between">
          <VideoSectionTitle>{t("newreleases")}</VideoSectionTitle>
          <TextButton
            onClick={() => {
              navigate(`/${Path.browse}/new-release`);
            }}
          >
            {t("seemore")} <IoChevronForward />
          </TextButton>
        </div>
        <VideoRow
          size="small"
          data={newReleasePage && newReleasePage[0]?.results.slice(0, 8)}
          isLoading={isLoadingNewRelease}
        />
      </div>
      {/*----- COMING SOON -----*/}
      <div className="mt-8">
        <div className="flex justify-between">
          <VideoSectionTitle>{t("comingsoon")}</VideoSectionTitle>
          <TextButton
            onClick={() => {
              navigate(`/${Path.browse}/coming-soon`);
            }}
          >
            {t("seemore")} <IoChevronForward />
          </TextButton>
        </div>
        <VideoRow
          size="small"
          data={comingSoonPage && comingSoonPage[0]?.results.slice(0, 8)}
          isLoading={isLoadingComingSoon}
        />
      </div>
    </div>
  );
};

type SingleCategorySectionProps = {
  category: string;
  search: string;
};

const SingleCategorySection = ({
  category,
  search,
}: SingleCategorySectionProps) => {
  const { t, i18n } = useTranslation();
  const {
    isLoading,
    isError,
    error,
    data,
    fetchNextPage,
    isFetching,
    hasNextPage,
    isFetchingNextPage,
  } = useGetPostInfiniteQuery(category, search);

  const { pages, pageParams } = (data as any) || {
    pages: [],
    pageParams: null,
  };

  const [videos, setVideos] = useState([]);

  useEffect(() => {
    let allPages = pages.map((page: any) => {
      return page.results;
    });

    var flattenedPages = [].concat.apply([], allPages);

    setVideos(flattenedPages);
  }, [pages]);

  return (
    <div className="mt-8">
      <VideoRow size="small" data={videos} isLoading={isLoading} />

      <div className="my-5 flex justify-center">
        <div>
          {hasNextPage ? (
            <SecondaryButton
              onClick={fetchNextPage}
              disabled={!hasNextPage}
              loading={isFetchingNextPage}
            >
              {isFetchingNextPage ? t("loadingmore") : t("loadmore")}
            </SecondaryButton>
          ) : null}
        </div>
      </div>
    </div>
  );
};

type LiveCategorySectionProps = {
  search?: string;
};

const LiveCategorySection = ({ search }: LiveCategorySectionProps) => {
  const { t, i18n } = useTranslation();
  const {
    isLoading,
    isError,
    error,
    data,
    fetchNextPage,
    isFetching,
    hasNextPage,
    isFetchingNextPage,
  } = useLiveSchedulesInfiniteQuery(search);

  const { pages, pageParams } = (data as any) || {
    pages: [],
    pageParams: null,
  };

  const [videos, setVideos] = useState([]);

  useEffect(() => {
    let allPages = pages.map((page: any) => {
      return page.results;
    });

    var flattenedPages = [].concat.apply([], allPages);

    setVideos(flattenedPages);
  }, [pages]);

  return (
    <div className="mt-8">
      <VideoRow size="small" data={videos} isLoading={isLoading} />

      <div className="my-5 flex justify-center">
        <div>
          {hasNextPage ? (
            <SecondaryButton
              onClick={fetchNextPage}
              disabled={!hasNextPage}
              loading={isFetchingNextPage}
            >
              {isFetchingNextPage ? t("loadingmore") : t("loadmore")}
            </SecondaryButton>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default BrowsePage;
