import { AxiosError } from "axios";
import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";
import { sprintf } from "sprintf-js";

import { UserProfileT, VideoInfoT } from "~/types";
import { appAxios, url } from "~/utils/axios";

const getPostDetailsQueryFn = (id: string) => {
  return appAxios
    .get(`${url.post_details}/${id}/details/`)
    .then((response) => response.data);
};

const useGetPostDetailsQuery = (
  id: string,
  options?: UseQueryOptions<
    VideoInfoT[],
    Error | AxiosError<{ detail: string }>
  >
) => {
  return useQuery<any, Error | AxiosError<{ detail: string }>>(
    ["useGetPostDetailsQuery", id],
    () => getPostDetailsQueryFn(id),
    options
  );
};

const getAudiencesQueryFn = () => {
  return appAxios.get<any>(url.get_audiences).then((response) => response.data);
};

const useGetAudiencesQuery = (
  options?: UseQueryOptions<any, Error | AxiosError<{ detail: string }>>
) => {
  return useQuery<any, Error | AxiosError<{ detail: string }>>(
    "audiences",
    getAudiencesQueryFn,
    options
  );
};

const getBroadcasterQueryFn = () => {
  return appAxios
    .get<any>(url.get_broadcaster_list, {
      params: {
        page_size: 0,
      },
    })
    .then((response) => response.data);
};
const useGetBroadcasterQuery = (
  options?: UseQueryOptions<any, Error | AxiosError<{ detail: string }>>
) => {
  return useQuery<any, Error | AxiosError<{ detail: string }>>(
    "broadcasters",
    getBroadcasterQueryFn,
    options
  );
};

const getChatMessageQueryFn = (id: string) => {
  if (id) {
    const endpoint = sprintf(url.get_chatroom_message, id);
    return appAxios
      .get<any>(endpoint, {
        params: {
          page_size: 0,
        },
      })
      .then((response) => response.data);
  }
};

const useGetChatMessageQuery = (
  id: string,
  options?: UseQueryOptions<any, Error | AxiosError<{ detail: string }>>
) => {
  return useQuery<any, Error | AxiosError<{ detail: string }>>(
    ["chat_messages", id],
    () => getChatMessageQueryFn(id),
    options
  );
};

type UpdatePostInfo = { id?: string; formData: FormData };

const updatePostMutationFn = (updatePostInfo: UpdatePostInfo) => {
  const { id, formData } = updatePostInfo;

  return appAxios
    .patch(`${url.update_post}/${id}/update/`, formData)
    .then((response) => response.data);
};

const useUpdatePostMutation = (
  options?: UseMutationOptions<any, AxiosError, UpdatePostInfo>
) => {
  return useMutation<any, AxiosError, UpdatePostInfo>(
    updatePostMutationFn,
    options
  );
};

type DeletePostInfo = { id?: string };

const deletePostMutationFn = (deletePostInfo: DeletePostInfo) => {
  const { id } = deletePostInfo;

  return appAxios
    .delete(`${url.delete_post}/${id}/delete/`)
    .then((response) => response.data);
};

const useDeletePostMutation = (
  options?: UseMutationOptions<any, AxiosError, DeletePostInfo>
) => {
  return useMutation<any, AxiosError, DeletePostInfo>(
    deletePostMutationFn,
    options
  );
};

const getStreamLinkQueryFn = (id: string) => {
  let endpoint = sprintf(url.generate_stream_link, id);

  return appAxios.get<any>(endpoint).then((response) => {
    return response.data;
  });
};

const useGetStreamLinkQuery = (
  id: string,
  options?: UseQueryOptions<any, Error | AxiosError<{ detail: string }>>
) => {
  return useQuery<any, Error | AxiosError<{ detail: string }>>(
    ["useGetStreamLinkQuery", id],
    () => getStreamLinkQueryFn(id),
    options
  );
};

export {
  useGetPostDetailsQuery,
  useGetAudiencesQuery,
  useGetBroadcasterQuery,
  useGetChatMessageQuery,
  useUpdatePostMutation,
  useDeletePostMutation,
  useGetStreamLinkQuery,
};
