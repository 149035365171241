import { ReactNode } from "react";

import styles from "./styles.module.less";

type ContainerProps = {
  children?: ReactNode;
  className?: string;
};

const PaddedContainer = ({ children, className }: ContainerProps) => {
  return (
    <div className={"px-4 md:px-6 lg:px-10 pt-4 pb-12 " + className}>
      {children}
    </div>
  );
};

export { PaddedContainer };
