import { FC, ReactElement, ReactNode, useEffect, useLayoutEffect } from "react";
import { Modal, Result, Spin } from "antd";
import { url } from "inspector";
import { useMutation } from "react-query";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { useAuthContext } from "~/contexts/auth";
import { useRole } from "~/contexts/role";
import { Path } from "~/enums";
import Unauthorized from "~/pages/error/403";

import { PrimaryButton } from "../Button";

import { useUserProfileQuery } from "./query";

// import { useVerifyToken } from "./query";

const promptUpdateProfile = (navigate: any, currentPath: string) => {
  if (`/${Path.profile}` != currentPath) {
    Modal.warning({
      centered: true,
      title: "Profile Update Required",
      content: "Additional details required. Please update your profile.",
      onOk() {
        navigate(`${Path.profile}`);
      },
    });
  }

  return;
};

const promptVerifyPhoneEmail = (
  navigate: any,
  currentPath: string,
  phoneInfo: any
) => {
  if (!currentPath.includes(Path.profile)) {
    Modal.warning({
      centered: true,
      title: "Phone/email Verification Required",
      content: "Please verify your phone number and/or email.",
      onOk() {
        navigate(`/${Path.profile}/${Path.changeverifydetails}`, {
          state: { verifyInfo: phoneInfo },
        });
      },
    });
  }

  return;
};

type PublicRouteProps = {
  restricted?: boolean;
  children: ReactNode;
};

const PublicRoute = ({ restricted = false, children }: PublicRouteProps) => {
  const [, setSearchParams] = useSearchParams();
  const { isAuthenticated, isVerifyingToken, setAuthInfo } = useAuthContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setRoleInfo } = useRole();

  const _ = useUserProfileQuery({
    onSuccess: (data) => {
      setAuthInfo({ isAuthenticated: true, isVerifyingToken: false });
      setRoleInfo({
        isCreator: data.role != "MEMBER",
        isBroadcaster:
          data.role != "MEMBER" ||
          (data.role == "MEMBER" && data.is_broadcaster),
      });

      const { is_sign_up_completed, is_phone_verified, is_email_verified } =
        data;

      if (!is_sign_up_completed) {
        promptUpdateProfile(navigate, pathname);
      } else if (!is_phone_verified || !is_email_verified) {
        const { phone, phone_prefix, email } = data;
        promptVerifyPhoneEmail(navigate, pathname, {
          phone,
          phone_prefix,
          email,
        });
      }

      // setSearchParams({});
    },
  });

  if (isVerifyingToken) {
    return (
      <div className="h-full flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  if (isAuthenticated && restricted) {
    return <Navigate to={`/${Path.browse}/all`} />;
  }

  return children as ReactElement;
};

type PrivateRouteProps = {
  children: ReactNode;
};

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const [, setSearchParams] = useSearchParams();
  const { isAuthenticated, isVerifyingToken, setAuthInfo, logout } =
    useAuthContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setRoleInfo } = useRole();

  const _ = useUserProfileQuery({
    onSuccess: (data) => {
      setAuthInfo({ isAuthenticated: true, isVerifyingToken: false });
      setRoleInfo({
        isCreator: data.role != "MEMBER",
        isBroadcaster:
          data.role != "MEMBER" ||
          (data.role == "MEMBER" && data.is_broadcaster),
      });

      // const { is_sign_up_completed, is_phone_verified, is_email_verified } =
      //   data;

      // if (!is_sign_up_completed) {
      //   promptUpdateProfile(navigate, pathname);
      // } else if (!is_phone_verified || !is_email_verified) {
      //   const { phone, phone_prefix, email } = data;
      //   promptVerifyPhoneEmail(navigate, pathname, {
      //     phone,
      //     phone_prefix,
      //     email,
      //   });
      // }

      // setSearchParams({});
    },
  });

  if (isVerifyingToken) {
    return (
      <div className="h-full flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  if (isAuthenticated) {
    return children as ReactElement;
  }

  return <Navigate to={`/${Path.connect}`} />;
};

const CreatorOnlyRoute: FC = ({ children }) => {
  const { isCreator } = useRole();
  const { isAuthenticated, isVerifyingToken, setAuthInfo, logout } =
    useAuthContext();

  if (isCreator) return children as ReactElement;

  if (isAuthenticated) {
    return <Unauthorized />;
  }

  return <Navigate to={`/${Path.connect}`} />;
};

const BroadcasterOnlyRoute: FC = ({ children }) => {
  const { isBroadcaster } = useRole();
  const { isAuthenticated, isVerifyingToken, setAuthInfo, logout } =
    useAuthContext();

  if (isBroadcaster) return children as ReactElement;

  if (isAuthenticated) {
    return <Unauthorized />;
  }

  return <Navigate to={`/${Path.connect}`} />;
};

export { PublicRoute, PrivateRoute, CreatorOnlyRoute, BroadcasterOnlyRoute };
