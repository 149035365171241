import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { useMutation, UseMutationOptions } from "react-query";

import { CountryInfoT } from "~/types";
import { UserInformationFormField } from "~/types/form";
import { guestAxios, url } from "~/utils/axios";

type EnquiryEmailInfo = { formData: FormData };

const getOrganisationDetailsQueryFn = () => {
  return guestAxios.get<any>(url.org_details).then((response) => response.data);
};

export const useGetOrganisationDetailsQuery = (
  options?: UseQueryOptions<any, Error | AxiosError<{ detail: string }>>
) => {
  return useQuery<any, Error | AxiosError<{ detail: string }>>(
    "org_details",
    getOrganisationDetailsQueryFn,
    options
  );
};
