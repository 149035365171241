import { AxiosError } from "axios";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { sprintf } from "sprintf-js";

import { PackageInfoT, UserProfileT } from "~/types";
import { appAxios, guestAxios, url } from "~/utils/axios";

const getSubscriptionDetailsQueryFn = () => {
  return appAxios.get<any>(url.get_details).then((response) => response.data);
};

export const useGetSubscriptionDetailsQuery = (
  options?: UseQueryOptions<any, Error | AxiosError<{ detail: string }>>
) => {
  return useQuery<any, Error | AxiosError<{ detail: string }>>(
    "subscription_detail",
    getSubscriptionDetailsQueryFn,
    options
  );
};

const userQueryFn = () => {
  return appAxios.get(url.profile).then((response) => {
    console.log("subscription profile:", response);
    return response.data;
  });
};

// Get user profile
export const useUserProfileQuery = (options?: UseQueryOptions<any>) => {
  return useQuery<any>(
    ["profile"],
    userQueryFn,
    options
      ? {
          ...options,
          // retry: false,
          staleTime: 300000,
          cacheTime: 300000,
        }
      : undefined
  );
};

const getReferralsQueryFn = () => {
  return appAxios.get<any>(url.get_referrals).then((response) => response.data);
};

export const useGetReferralsQuery = (
  options?: UseQueryOptions<any, Error | AxiosError<{ detail: string }>>
) => {
  return useQuery<any, Error | AxiosError<{ detail: string }>>(
    "referrals",
    getReferralsQueryFn,
    options
  );
};

const cancelSubscriptionMutationFn = () => {
  return appAxios
    .post(url.cancel_subscription)
    .then((response) => response.data);
};

export const useCancelSubscriptionMutation = (
  options?: UseMutationOptions<any, AxiosError>
) => {
  const queryClient = useQueryClient();

  return useMutation<any, AxiosError>(cancelSubscriptionMutationFn, {
    ...options,
    onSettled: (data) => {
      queryClient.invalidateQueries(["subscription_detail"]);
    },
  });
};
