import { AxiosError } from "axios";
import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";
import { sprintf } from "sprintf-js";

import { UserProfileT, VideoInfoT } from "~/types";
import { appAxios, url } from "~/utils/axios";

const getPostDetailsQueryFn = (id: string) => {
  return appAxios
    .get(`${url.post_details}/${id}/details/`)
    .then((response) => response.data);
};

const useGetPostDetailsQuery = (
  id: string,
  options?: UseQueryOptions<
    VideoInfoT[],
    Error | AxiosError<{ detail: string }>
  >
) => {
  return useQuery<any, Error | AxiosError<{ detail: string }>>(
    ["useGetPostDetailsQuery", id],
    () => getPostDetailsQueryFn(id),
    options
  );
};

const getChatMessageQueryFn = (id: string) => {
  if (id) {
    const endpoint = sprintf(url.get_chatroom_message, id);
    return appAxios
      .get<any>(endpoint, {
        params: {
          page_size: 0,
        },
      })
      .then((response) => response.data);
  }
};

const useGetChatMessageQuery = (
  id: string,
  options?: UseQueryOptions<any, Error | AxiosError<{ detail: string }>>
) => {
  return useQuery<any, Error | AxiosError<{ detail: string }>>(
    ["chat_messages", id],
    () => getChatMessageQueryFn(id),
    options
  );
};

const getPostStreamQueryFn = (id: string) => {
  return appAxios
    .get(`${url.live_stream}/${id}/stream/`)
    .then((response) => response.data);
};

const useGetPostStreamQuery = (
  id: string,
  options?: UseQueryOptions<
    VideoInfoT[],
    Error | AxiosError<{ detail: string }>
  >
) => {
  return useQuery<any, Error | AxiosError<{ detail: string }>>(
    ["post_stream", id],
    () => getPostStreamQueryFn(id),
    options
  );
};

type GetPostResponse = {
  results: VideoInfoT[];
  next: string;
};

const getRelatedPostQueryFn = (context: any) => {
  const [_key, _params] = context.queryKey;
  const { pageParam: nextPageUrl } = context;

  return appAxios
    .get<GetPostResponse>(
      nextPageUrl ?? `${url.related_post}/${_params}/related/`
    )
    .then((response) => response.data);
};

const useGetPostInfiniteQuery = (id: string, options?: any) => {
  return useInfiniteQuery<GetPostResponse, Error>(
    ["useGetPostInfiniteQuery", id],
    getRelatedPostQueryFn,
    {
      ...options,
      getNextPageParam: (lastPage, pages) => {
        return lastPage.next;
      },
    }
  );
};

const getPostCommentsQueryFn = (context: any) => {
  const [_key, _params] = context.queryKey;
  const { pageParam: nextPageUrl } = context;
  let endpoint = sprintf(url.get_posts_comments, _params);

  return appAxios
    .get(nextPageUrl ?? endpoint, {
      params: {
        ordering: "-created_at",
      },
    })
    .then((response) => response.data);
};

const useGetPostCommentsInfiniteQuery = (id: string, options?: any) => {
  return useInfiniteQuery<GetPostResponse, Error>(
    ["useGetPostCommentsInfiniteQuery", id],
    getPostCommentsQueryFn,
    {
      ...options,
      getNextPageParam: (lastPage, pages) => {
        return lastPage.next;
      },
    }
  );
};

type CreatePostCommentInfo = { id: string; formData: FormData };

const createPostCommentMutationFn = (
  createPostCommentInfo: CreatePostCommentInfo
) => {
  const { id, formData } = createPostCommentInfo;

  let endpoint = sprintf(url.create_posts_comments, id);

  return appAxios.post(endpoint, formData).then((response) => response.data);
};

const useCreatePostCommentMutation = (
  options?: UseMutationOptions<any, AxiosError, CreatePostCommentInfo>
) => {
  return useMutation<any, AxiosError, CreatePostCommentInfo>(
    createPostCommentMutationFn,
    options
  );
};

type ReactPostInfo = { id: string };

const reactPostMutationFn = (reactPostInfo: ReactPostInfo) => {
  const { id } = reactPostInfo;

  let endpoint = sprintf(url.react_post, id);

  return appAxios.post(endpoint).then((response) => response.data);
};

const useReactPostMutation = (
  options?: UseMutationOptions<any, AxiosError, ReactPostInfo>
) => {
  return useMutation<any, AxiosError, ReactPostInfo>(
    reactPostMutationFn,
    options
  );
};

const userQueryFn = () => {
  const token = localStorage.getItem("access_token");
  if (token) {
    return appAxios.get(url.profile).then((response) => {
      console.log("video profile:", response);
      return response.data;
    });
  }
};

// Get user profile
const useUserProfileQuery = (options?: UseQueryOptions<any>) => {
  const token = localStorage.getItem("access_token");
  return useQuery<any>(
    ["profile"],
    userQueryFn,
    options
      ? {
          ...options,
          // retry: false,
          staleTime: 300000,
          cacheTime: 300000,
          enabled: !!token,
        }
      : undefined
  );
};

export {
  useGetPostDetailsQuery,
  useGetPostStreamQuery,
  useGetPostInfiniteQuery,
  useGetPostCommentsInfiniteQuery,
  useCreatePostCommentMutation,
  useReactPostMutation,
  useUserProfileQuery,
  useGetChatMessageQuery,
};
