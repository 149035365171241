import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

interface SidebarContextProps extends SidebarInfo {
  setSidebarInfo: Dispatch<SetStateAction<SidebarInfo>>;
}

type SidebarInfo = {
  collapse: boolean;
};

const SidebarContext = createContext<SidebarContextProps>(
  {} as SidebarContextProps
);

type SidebarContextProviderProps = {
  children?: ReactNode;
};

const SidebarContextProvider = ({ children }: SidebarContextProviderProps) => {
  const [sidebarInfo, setSidebarInfo] = useState<SidebarInfo>({
    collapse: false,
  });

  return (
    <SidebarContext.Provider value={{ ...sidebarInfo, setSidebarInfo }}>
      {children}
    </SidebarContext.Provider>
  );
};

const useSidebarContext = () => useContext(SidebarContext);

export { SidebarContextProvider, useSidebarContext };
