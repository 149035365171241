import { Link, Outlet } from "react-router-dom";

const TermSkeleton = () => {
  return (
    <div className="mt-2 mb-10">
      <div className="flex justify-center">
        <Link
          to="/login"
          className="flex items-center text-black hover:underline"
        >
          <img
            className="w-20 h-20 sm:w-24 sm:h-24"
            alt="tradveller logo"
            src={process.env.PUBLIC_URL + "/TradvellerLogo512.png"}
          />
          <span className="text-xl sm:text-3xl font-bold">TheTradveller</span>
        </Link>
      </div>

      <div className="sm:p-10 p-3">
        <Outlet />
      </div>
    </div>
  );
};

export default TermSkeleton;
