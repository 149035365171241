import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { useMutation, UseMutationOptions } from "react-query";

import { CountryInfoT, VideoInfoT } from "~/types";
import { UserInformationFormField } from "~/types/form";
import { appAxios, guestAxios, url } from "~/utils/axios";

type UpdatePostInfo = { id?: string; formData: FormData };

const updatePostMutationFn = (updatePostInfo: UpdatePostInfo) => {
  const { id, formData } = updatePostInfo;

  return appAxios
    .patch(`${url.update_post}/${id}/update/`, formData)
    .then((response) => response.data);
};

export const useUpdatePostMutation = (
  options?: UseMutationOptions<any, AxiosError, UpdatePostInfo>
) => {
  return useMutation<any, AxiosError, UpdatePostInfo>(
    updatePostMutationFn,
    options
  );
};

const getPostQueryFn = (id: string) => {
  return appAxios
    .get(`${url.post_details}/${id}/details/`)
    .then((response) => response.data);
};

export const useGetPostDetailsQuery = (
  id: string,
  options?: UseQueryOptions<
    VideoInfoT[],
    Error | AxiosError<{ detail: string }>
  >
) => {
  return useQuery<any, Error | AxiosError<{ detail: string }>>(
    ["post_details", id],
    () => getPostQueryFn(id),
    options
  );
};

type DeletePostInfo = { id?: string };

const deletePostMutationFn = (deletePostInfo: DeletePostInfo) => {
  const { id } = deletePostInfo;

  return appAxios
    .delete(`${url.delete_post}/${id}/delete/`)
    .then((response) => response.data);
};

export const useDeletePostMutation = (
  options?: UseMutationOptions<any, AxiosError, DeletePostInfo>
) => {
  return useMutation<any, AxiosError, DeletePostInfo>(
    deletePostMutationFn,
    options
  );
};

const getAudiencesQueryFn = () => {
  return guestAxios
    .get<any>(url.get_audiences)
    .then((response) => response.data);
};

export const useGetAudiencesQuery = (
  options?: UseQueryOptions<any, Error | AxiosError<{ detail: string }>>
) => {
  return useQuery<any, Error | AxiosError<{ detail: string }>>(
    "audiences",
    getAudiencesQueryFn,
    options
  );
};

const getPostStreamQueryFn = (id: string) => {
  return appAxios
    .get(`${url.live_stream}/${id}/stream/`)
    .then((response) => response.data);
};

const useGetPostStreamQuery = (
  id: string,
  options?: UseQueryOptions<
    VideoInfoT[],
    Error | AxiosError<{ detail: string }>
  >
) => {
  return useQuery<any, Error | AxiosError<{ detail: string }>>(
    ["post_stream", id],
    () => getPostStreamQueryFn(id),
    options
  );
};
